import React, {  useState } from 'react';

import {  T } from "../Globals.js"
import { Stack,TextField } from '@mui/material';
import ItemTemplate from "../components/ItemTemplate";
import LinkField from "../components/LinkField";

export function AccountantExpertpaymentsItem(props) {
  let [data, setData] = useState({ });

  const saveHandler = async () => {  }
  const deleteHandler = async () => { }
  const cancelHandler = async () => {}

  return (
    <>
      <ItemTemplate {...props} title={<T>expert payments item</T>} table="accountantexpertpayments" data={data} setData={setData} onSave={saveHandler} onCancel={cancelHandler} onDelete={deleteHandler}>
        <AccountantExpertpaymentsItemView  />
      </ItemTemplate>
    </>
  )
}



export function AccountantExpertpaymentsItemView(prop) {
  const handleChange = (event) => {
    if (event.target.name === "amount_rub") event.target.value = - Math.abs(event.target.value)
    prop.setData({ ...prop.data, [event.target.name]: event.target.value })
  }
  /*
  const handleCheck = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.checked })
  }
  */
  return (
    <Stack sx={{gap:2}}>
      
      <LinkField data={prop.data} table="users/experts" label={<T>experts item</T>} name="expertid" onChange={handleChange} disabledCreate/>

      <TextField
        fullWidth
        label={<T>amount rub</T>}
        value={Math.abs(prop.data?.amount_rub) || ""}
        name="amount_rub"
        onChange={handleChange}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
      />

      <TextField
        fullWidth
        label={<T>expertpayments akt</T>}
        value={prop.data?.akt || ""}
        name="akt"
        onChange={handleChange}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
      />


    </Stack>
  )
}