import { Box, Button, Stack, Typography } from '@mui/material';
import React,{useState, useRef} from 'react';
import {T} from "../Globals.js"


export default function HumanSelector({data, setData, buttons=true, className, title=true, minWidth=150, sx}) {

  const dimensions = {maxWidth: buttons?"200px":"150px", minWidth: minWidth}
  const [humanFront, setHumanFront] = useState(data?.human?.front ?? true);
  const [part, setPart] = useState(data?.human?.part ?? null);
  const [markerPosition, setMarkerPosition] = useState(data?.human?.x && data?.human?.y ? {x: data.human.x, y: data?.human?.y} : null);

  const svgRef = useRef(null);

  const handleSvgClick = (event) => {
    const partBody = event.target.getAttribute("data-part");

    if (partBody) {
      //координаты X и Y клика в процентах от ширины и высоты SVG
      const svgRect = svgRef.current.getBoundingClientRect();
      const xPercent = ((event.clientX - svgRect.left) / svgRect.width) * 100;
      const yPercent = ((event.clientY - svgRect.top) / svgRect.height) * 100;
      const addHumanData = {
        x: xPercent, 
        y: yPercent, 
        front: humanFront, 
        part: partBody 
      };

      setPart(partBody);
      setMarkerPosition({ x: xPercent, y: yPercent }); 
      setData({ ...data, human: {...addHumanData} });
    } 
  }

  const changeFront = (value) => {
    setMarkerPosition(null);
    setPart(null);
    setHumanFront(value);
  }

  // if (markerPosition) {
  //   console.log(markerPosition.x, markerPosition.y);
  // }
  

  return (
    <Box className={className} sx={{margin: "auto", ...sx}}>
      
      <Box className={className} sx={{margin: "auto"}}>
      <Box >
        {buttons && (
          <Stack sx={{flexDirection: "row", justifyContent: "center", gap: 2, mt: 1}}>
            <Button variant={humanFront ? "contained" : "outlined"}  onClick={() => changeFront(true)}>{<T>humanselector front</T>}</Button>
            <Button variant={humanFront ? "outlined" : "contained"}  onClick={() => changeFront(false)}>{<T>humanselector back</T>}</Button>
          </Stack>
        )}

        <div style={{...dimensions, height: "auto", position: "relative", margin: "0 auto"}}>
          {/* {markerPosition && ( <div style={{ position:'absolute', top:`calc(${markerPosition.y}% - 5px)`, left:`calc(${markerPosition.x}% - 5px)`, width:'10px', height:'10px', backgroundColor:'red', borderRadius:'50%'}}/> )} */}
          {markerPosition && ( <div style={{ position:'absolute', top:`calc(${markerPosition.y}% - 5px)`, left:`calc(${markerPosition.x}% - 5px)`, width:'10px', height:'10px', backgroundColor:'red', borderRadius:'50%'}}/> )}
          {humanFront ? (
            <svg ref={svgRef} onClick={buttons ? handleSvgClick : null} xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 317 725">
              <defs>
                <style>
                  {`
                  .human_cls-1 {fill: #f4d8c1;}
                  .human_cls-10,.human_cls-11,.human_cls-12,.human_cls-13,.human_cls-2,.human_cls-3,.human_cls-4,.human_cls-5,.human_cls-6,.human_cls-7,.human_cls-8,
                  .human_cls-9 {fill: none;}
                  .human_cls-10,.human_cls-11,.human_cls-12,.human_cls-13,.human_cls-3,.human_cls-4,.human_cls-5,.human_cls-6,.human_cls-7,.human_cls-8,
                  .human_cls-9 {stroke: #1d1d1b;stroke-linecap: round;stroke-linejoin: round;stroke-width: 0.5px;}
                  .human_cls-4 {stroke-dasharray: 5.71 5.71;}
                  .human_cls-5 {stroke-dasharray: 5.85 5.85;}
                  .human_cls-6 {stroke-dasharray: 5;}
                  .human_cls-7 {stroke-dasharray: 4.99 4.99;}
                  .human_cls-8 {stroke-dasharray: 4.76 4.76;}
                  .human_cls-9 {stroke-dasharray: 5.49 5.49;}
                  .human_cls-10 {stroke-dasharray: 5.19 5.19;}
                  .human_cls-11 {stroke-dasharray: 4.82 4.82;}
                  .human_cls-12 {stroke-dasharray: 5.09 5.09;}
                  .human_cls-13 {stroke-dasharray: 4 4;}
                  `}
                </style>
              </defs>
              <title>Human</title>
              <path className="human_cls-1" data-part='hip right' d="M129.83,401.11V357.66h28.65v-17H98.33c-.54,3.91-2.51,14.77-3.06,21.39-2.27,27.35,7.36,81.33,8.91,87.33s10.45,22.65,8,47.63c-1.19,12-.29,18.16-.29,18.16l-.16,1,.38-.11L144.7,524h.06a10.52,10.52,0,0,1,.44-1.34c1.38-3.27,3.56-11.84,3.56-14.38s.24-10.29.15-13.19,1.35-18.2,2.51-21.69,2.13-18,1.65-20.91-.87-14.53.19-19.37S156,417,156,414.44s.09-6.68.77-8.81a45.12,45.12,0,0,1,1.73-4.43v-.09Z" />
              <path className="human_cls-1" data-part='hip left' d="M221.72,362.06c-.55-6.62-2.52-17.48-3.06-21.39H158.48v17h28.68v43.45H158.48v.06h0a47.71,47.71,0,0,1,1.74,4.46c.68,2.13.78,6.29.78,8.81a162.21,162.21,0,0,0,2.71,18.68c1.06,4.84.68,16.46.19,19.37s.49,17.42,1.65,20.91,2.61,18.78,2.51,21.69.15,10.65.15,13.19,2.18,11.11,3.56,14.38a9.14,9.14,0,0,1,.44,1.34h.05l32.6-7.93.38,0c-.09-.58-.14-.93-.14-.93S206,509,204.83,497c-2.47-25,6.44-41.63,8-47.63S224,389.41,221.72,362.06Z" />
              <rect className="human_cls-1" data-part='groin area' x="129.83" y="357.66" width="57.33" height="43.45" />
              <path className="human_cls-1" data-part="neck" d="M174.57,100.69c-3,2.62-9.83,8.33-11.19,9.2s-5,1.16-5,1.16-3.6-.29-5-1.16-8.18-6.58-11.19-9.2a19.84,19.84,0,0,1-5.55-8.23,8.24,8.24,0,0,1-.35-1.16,9.35,9.35,0,0,0,.28,1.45c.58,1.94,2.72,8.72,3.7,12.2s1.16,14.72,1,15.88h34.05c-.19-1.16,0-12.39,1-15.88s3.12-10.26,3.7-12.2a11.36,11.36,0,0,0,.35-2.11,12.85,12.85,0,0,1-.67,2.88A16.08,16.08,0,0,1,174.57,100.69Z" />
              <path className="human_cls-1" data-part='face' d="M187.31,63.52h-.07a48.15,48.15,0,0,0,1-15.68,27.44,27.44,0,0,0-3.4-9.84H131.88a27.44,27.44,0,0,0-3.4,9.84,48.49,48.49,0,0,0,1,15.66l.08,0,6.62,21.88s0,.31-.07.81c0,1.18.09,3.42.3,5.1a8.24,8.24,0,0,0,.35,1.16,19.84,19.84,0,0,0,5.55,8.23c3,2.62,9.82,8.33,11.19,9.2s5,1.16,5,1.16,3.6-.29,5-1.16,8.17-6.58,11.19-9.2a16.08,16.08,0,0,0,5.15-7.17,12.85,12.85,0,0,0,.67-2.88c.23-2.32.23-5.25.23-5.25a1.23,1.23,0,0,0,.08.15v-.15Z" />
              <path className="human_cls-1" data-part='shoulder left' d="M264.52,264.52,236,278.16c-1.3-5.64-2.69-10.17-3.73-13.27-4.11-12.36-8.37-19.56-9.56-30.43-.11-1-.19-2-.44-3.91a103.85,103.85,0,0,0-3.19-15.34,5.24,5.24,0,0,0,.55,0,7.8,7.8,0,0,0,1.86-.37c3.62-1.23,6.26-5.12,6.26-9.74,0-5.36-3.56-9.74-8.06-10.13l.66-20.73.81-25.44v-.06c0-1.82.11-3.65.17-5.47l.33.1a25.35,25.35,0,0,1,12,7.5c3.66,4.22,10.59,14.07,12.31,19.9s3.5,13.6,3.25,17.74-.4,10.59-.4,10.59a59.45,59.45,0,0,0,2,6.38c.83,1.85,3.88,12,4,17.84s1.2,15.46,1.2,15.46.93,3.88,1.89,7.71S262.35,261.28,264.52,264.52Z" />
              <path className="human_cls-1" data-part='armpits left' d="M221.53,214.83c3.62-1.24,6.26-5.13,6.26-9.75,0-5.61-3.9-10.16-8.71-10.16a6.55,6.55,0,0,0-.89.05c-4.39.52-7.82,4.85-7.82,10.11,0,5,3.16,9.23,7.3,10a7.67,7.67,0,0,0,2,.09A7.85,7.85,0,0,0,221.53,214.83Z" />
              <path className="human_cls-1" data-part='breast' d="M217.67,215.1c-4.14-.79-7.3-5-7.3-10,0-5.26,3.43-9.59,7.82-10.11a6.55,6.55,0,0,1,.89-.05c.22,0,.43,0,.65,0l.66-20.73H96.26L96.92,195l.64,0a6.7,6.7,0,0,1,.9.05c4.39.52,7.82,4.85,7.82,10.11,0,5-3.16,9.23-7.3,10a8.09,8.09,0,0,1-1.39.12q2.19,9.39,4.39,18.8,2.14,5.17,4.31,10.35l2.48,6.78S111.4,268.65,111,274c-.13,1.6-.33,3.31-.6,5.07h95.85c-.27-1.76-.48-3.47-.6-5.07-.4-5.31,2.23-22.81,2.23-22.81l2.48-6.78L214.67,234l4.38-18.8A8.53,8.53,0,0,1,217.67,215.1Z" />
              <path className="human_cls-1" data-part='neckline' d="M221.72,143.25v.1l-.33-.1c-.06,1.82-.12,3.65-.17,5.47v.06l-.81,25.44H96.26q-.41-12.75-.82-25.49c0-1.83-.11-3.65-.17-5.48l-.34.1v-.1c3.68-1.55,12.4-4.26,12.4-4.26s5.81-3.1,7-3.68,16.65-6.78,21.49-9.78,5.23-3.58,5.42-4.74h34.24c.2,1.16.58,1.74,5.42,4.74s20.34,9.2,21.5,9.78,7,3.68,7,3.68S218,141.7,221.72,143.25Z" />
              <path className="human_cls-1" d="M95.11,214.83c-3.61-1.24-6.26-5.13-6.26-9.75,0-5.36,3.56-9.74,8.07-10.13l-1.65-51.7A25.5,25.5,0,0,0,83,150.85c-3.67,4.22-10.59,14.07-12.31,19.9s-3.5,13.61-3.25,17.74.4,10.59.4,10.59a60.92,60.92,0,0,1-2,6.39c-.82,1.84-3.88,12-4,17.83s-1.2,15.46-1.2,15.46-.93,3.89-1.88,7.72-4.42,14.8-6.59,18l28.49,13.65c1.3-5.64,2.69-10.17,3.72-13.28,4.11-12.36,8.37-19.56,9.56-30.42.12-1,.19-2,.44-3.91a104.84,104.84,0,0,1,3.2-15.35A8.07,8.07,0,0,1,95.11,214.83Z" />
              <path className="human_cls-1" data-part='armpits right' d="M95.11,214.83c-3.61-1.24-6.26-5.13-6.26-9.75,0-5.61,3.9-10.16,8.71-10.16a6.7,6.7,0,0,1,.9.05c4.39.52,7.82,4.85,7.82,10.11,0,5-3.16,9.23-7.3,10a8.09,8.09,0,0,1-1.39.12A8.41,8.41,0,0,1,95.11,214.83Z" />
              <path className="human_cls-1" data-part='shoulder right' d="M97.53,215.21a105,105,0,0,0-3.2,15.34c-.25,1.9-.32,2.89-.44,3.91-1.19,10.87-5.45,18.07-9.56,30.43-1,3.1-2.42,7.63-3.72,13.27L52.12,264.52c2.17-3.24,5.63-14.22,6.59-18s1.88-7.71,1.88-7.71,1-9.63,1.2-15.46,3.22-16,4-17.84a60.23,60.23,0,0,0,2-6.38s-.15-6.46-.4-10.59,1.52-11.91,3.25-17.74,8.64-15.68,12.31-19.9a25.31,25.31,0,0,1,12-7.5l.34-.1c.06,1.83.12,3.65.17,5.48q.42,12.75.82,25.49L96.92,195c-4.51.39-8.07,4.77-8.07,10.13,0,4.62,2.65,8.51,6.26,9.74A8,8,0,0,0,97.53,215.21Z" />
              <rect className="human_cls-2" x="158.48" y="357.66" width="28.68" height="43.45" />
              <rect className="human_cls-2" data-part='groin area' x="129.83" y="357.66" width="28.65" height="43.45" />
              <path className="human_cls-1" data-part="hair part head" d="M184.83,38a30.62,30.62,0,0,0-15.58-13.78,34.87,34.87,0,0,0-10.76-2.07,34.75,34.75,0,0,0-10.74,2.07A30.61,30.61,0,0,0,132.16,38Z" />
              <path className="human_cls-1" data-part="foot right" d="M142.81,655c0-1.94,1.16-4.36,1.16-6.68s-1.26-4.84-1.55-6.68l-22.27,3.58s-.58,3.1-.67,4.75,1.16,5.51,1.16,5.51a12.75,12.75,0,0,1-.1,5.14c-.68,1.93-5.42,10.35-7.36,11.9a97.62,97.62,0,0,0-8.42,8.23,13.37,13.37,0,0,0-2.84,5.44,2.45,2.45,0,0,0,.13,2.1,1.51,1.51,0,0,0,1.29.66c1.13-.14,1.72-2.2,1.82-2.15a18.68,18.68,0,0,1-.91,2.8,5.51,5.51,0,0,0-.46,1.51c-.05.94.25,2.22,1.13,2.6a1.55,1.55,0,0,0,.88.07c2.08-.39,3.34-4.26,3.33-4.27s-.73,2.5-1.54,4.63a2.23,2.23,0,0,0,.08,2,2.09,2.09,0,0,0,1.25.69,5.15,5.15,0,0,0,2.36.17c2.46-.54,3.63-5.59,3.74-5.55a18.27,18.27,0,0,0-.74,5.68,2.78,2.78,0,0,0,.63,1.89,2.5,2.5,0,0,0,2.07.74c2.54-.37,4.38-5.71,4.24-7.09,0-.14-.1-.76.24-1a.69.69,0,0,1,.44-.13c1.91,0,2.86-.06,3,.34a.41.41,0,0,1-.07.34c-4.5,7.33-1.74,10.82,5.23,9.1,1.51-.38,2.23-2.13,2.81-4s1.55-5.23,3.58-6.68a8.64,8.64,0,0,0,3.29-6.1c0-1.74-.39-5.23.48-6.39s3.49-3.49,3.88-5.42a41.74,41.74,0,0,0,.19-8.52C144.07,661.81,142.81,657,142.81,655Z" />
              <path className="human_cls-1" data-part="foot left" d="M174.18,655c0-1.94-1.16-4.36-1.16-6.68s1.25-4.84,1.54-6.68l22.27,3.58s.58,3.1.68,4.75-1.16,5.51-1.16,5.51a12.75,12.75,0,0,0,.1,5.14c.67,1.93,5.42,10.35,7.35,11.9a97.42,97.42,0,0,1,8.43,8.23,13.51,13.51,0,0,1,2.84,5.44,2.45,2.45,0,0,1-.13,2.1,1.54,1.54,0,0,1-1.29.66c-1.14-.14-1.72-2.2-1.83-2.15a18.68,18.68,0,0,0,.91,2.8,5.12,5.12,0,0,1,.46,1.51c.06.94-.25,2.22-1.13,2.6a1.52,1.52,0,0,1-.87.07c-2.09-.39-3.35-4.26-3.33-4.27s.73,2.5,1.54,4.63a2.3,2.3,0,0,1-.08,2,2.11,2.11,0,0,1-1.26.69,5.11,5.11,0,0,1-2.35.17c-2.47-.54-3.64-5.59-3.75-5.55a18.45,18.45,0,0,1,.75,5.68,2.89,2.89,0,0,1-.63,1.89,2.5,2.5,0,0,1-2.07.74c-2.55-.37-4.38-5.71-4.24-7.09,0-.14.1-.76-.24-1a.72.72,0,0,0-.44-.13c-1.91,0-2.87-.06-3,.34a.42.42,0,0,0,.08.34c4.5,7.33,1.74,10.82-5.23,9.1-1.52-.38-2.23-2.13-2.81-4s-1.55-5.23-3.58-6.68a8.6,8.6,0,0,1-3.29-6.1c0-1.74.38-5.23-.49-6.39s-3.48-3.49-3.87-5.42a41,41,0,0,1-.2-8.52C172.92,661.81,174.18,657,174.18,655Z" />
              <path className="human_cls-1" data-part='shin right' d="M119,628.4c.58,4.56,1.16,16.85,1.16,16.85l22.27-3.58c-.29-1.84.29-27.69.29-27.69s1.78-12.31,2.28-16.9c.87-8.13,8-38.34.21-63.17a20.43,20.43,0,0,1-.44-9.91l-32.66-7.93h-.36c-.19,1.12-.54,3.16-1,5.21-.72,3.05-1.3,7.84-1.3,7.84s-1.75,8.71-2.47,15.25-1.45,19.6.58,28.32S118.41,623.85,119,628.4Z" />
              <path className="human_cls-1" data-part='shin left' d="M174.28,614s.58,25.85.29,27.69l22.27,3.58S197.42,633,198,628.4s9.44-47,11.47-55.71,1.31-21.79.58-28.32-2.46-15.25-2.46-15.25-.59-4.79-1.31-7.84c-.49-2-.84-4.09-1-5.21l-33,7.93a20.31,20.31,0,0,1-.44,9.91c-7.77,24.83-.66,55,.22,63.17C172.5,601.67,174.28,614,174.28,614Z" />
              <path className="human_cls-1" data-part='stomach' d="M218.66,340.67c0-.1,0-.21,0-.3-1.45-11.23-7.94-33.11-7.94-33.11v-5.61c0-2.45-3-13.24-4.44-22.66H110.75c-1.41,9.42-4.44,20.21-4.44,22.66v5.61s-6.49,21.88-7.94,33.11c0,.09,0,.2,0,.3Z" />
              <line className="human_cls-3" x1="172.28" y1="524" x2="174.71" y2="523.41" />
              <line className="human_cls-4" x1="180.26" y1="522.06" x2="199.68" y2="517.33" />
              <line className="human_cls-3" x1="202.46" y1="516.66" x2="204.88" y2="516.07" />
              <line className="human_cls-3" x1="144.7" y1="524" x2="142.27" y2="523.41" />
              <line className="human_cls-4" x1="136.73" y1="522.06" x2="117.3" y2="517.33" />
              <line className="human_cls-3" x1="114.53" y1="516.66" x2="112.1" y2="516.07" />
              <line className="human_cls-3" x1="142.42" y1="641.67" x2="139.95" y2="642.07" />
              <line className="human_cls-5" x1="134.18" y1="643" x2="125.51" y2="644.39" />
              <line className="human_cls-3" x1="122.62" y1="644.85" x2="120.15" y2="645.25" />
              <line className="human_cls-3" x1="174.73" y1="641.67" x2="177.19" y2="642.07" />
              <line className="human_cls-5" x1="182.97" y1="643" x2="191.64" y2="644.39" />
              <line className="human_cls-3" x1="194.53" y1="644.85" x2="196.99" y2="645.25" />
              <line className="human_cls-6" x1="132.16" y1="37.99" x2="184.83" y2="37.99" />
              <line className="human_cls-6" x1="141.55" y1="120.79" x2="175.44" y2="120.79" />
              <line className="human_cls-3" x1="98.6" y1="340.67" x2="101.1" y2="340.67" />
              <line className="human_cls-7" x1="106.09" y1="340.67" x2="213.4" y2="340.67" />
              <line className="human_cls-3" x1="215.89" y1="340.67" x2="218.39" y2="340.67" />
              <line className="human_cls-6" x1="110.76" y1="278.95" x2="206.23" y2="278.95" />
              <polyline className="human_cls-3" points="187.16 360.16 187.16 357.66 184.66 357.66" />
              <line className="human_cls-8" x1="179.91" y1="357.66" x2="134.71" y2="357.66" />
              <polyline className="human_cls-3" points="132.33 357.66 129.83 357.66 129.83 360.16" />
              <line className="human_cls-9" x1="129.83" y1="365.66" x2="129.83" y2="395.87" />
              <polyline className="human_cls-3" points="129.83 398.61 129.83 401.11 132.33 401.11" />
              <line className="human_cls-8" x1="137.09" y1="401.11" x2="182.28" y2="401.11" />
              <polyline className="human_cls-3" points="184.66 401.11 187.16 401.11 187.16 398.61" />
              <line className="human_cls-9" x1="187.16" y1="393.12" x2="187.16" y2="362.91" />
              <path className="human_cls-1" data-part='palm left' d="M268.15,354.94l18.68-5.43a19.3,19.3,0,0,0,7.57,7.67c4.44,2.65,7.05,3.64,9.85,7.23s4.19,8,5.24,8.88c2.37,1.89,8.4,8.41,3.93,9-2.16.28-5.1-1.54-7.42-4.25-1.32-1.53-4.15-5-5.23-4.23s-1.29,2.51-.53,5,9.14,24.46,9.14,24.46,1,3.38.26,4.35-1.62,1.09-2.88.37-4.29-6-6-10.07-2.46-6.56-2.46-6.56-.8,3.47,5.45,18.48c.4,1,1.62,4.14,1.24,5.11-.12.29-.45,1-1,1.18-2.16.58-6.28-7-8.49-12-2.91-6.55-4.21-12.33-4.41-12.28a11.88,11.88,0,0,0,.56,3.07c.59,2.32,2.67,9.51,5,13.53.25.44,1,1.76.53,2.72a1.34,1.34,0,0,1-.56.59c-1.49.63-6.48-5.64-9.94-13.29a70.64,70.64,0,0,1-3.32-9.29,31.48,31.48,0,0,0,2.91,10c1.08,2.35,2.63,5.06,1.95,5.63a.8.8,0,0,1-.72,0c-4.27-1.25-7.86-8.64-7.86-8.64-1.18-2.45-8-15.75-10.08-27.4-.55-3-.77-5.46-1-7.43S269.19,357.88,268.15,354.94Z" />
              <path className="human_cls-1" data-part='forearm left' d="M264.52,264.52c2.18,3.24,7.24,15.33,10.2,29.3s7.52,41.64,7.52,41.64,3.26,11.73,4.59,14.05l-18.68,5.43a15.24,15.24,0,0,0-1.15-2.45c-18.84-33.32-26.62-55.24-31-74.32Z" />
              <path className="human_cls-3" d="M219.73,195c0-.83.05-1.66.08-2.49" />
              <path className="human_cls-10" d="M220,187.27q.63-19.47,1.25-38.93" />
              <path className="human_cls-3" d="M221.3,145.75c0-.83.06-1.67.08-2.5" />
              <line className="human_cls-6" x1="264.49" y1="264.52" x2="236" y2="278.18" />
              <line className="human_cls-3" x1="286.79" y1="349.52" x2="284.39" y2="350.21" />
              <line className="human_cls-11" x1="279.77" y1="351.56" x2="272.83" y2="353.58" />
              <line className="human_cls-3" x1="270.52" y1="354.25" x2="268.12" y2="354.95" />
              <path className="human_cls-1" data-part='palm right' d="M48.5,354.94l-18.68-5.43a19.3,19.3,0,0,1-7.57,7.67c-4.44,2.65-7,3.64-9.86,7.23s-4.19,8-5.23,8.88c-2.37,1.89-8.4,8.41-3.93,9,2.16.28,5.1-1.54,7.42-4.25,1.32-1.53,4.15-5,5.23-4.23s1.29,2.51.52,5-9.13,24.46-9.13,24.46-1,3.38-.27,4.35,1.62,1.09,2.89.37,4.28-6,6-10.07,2.45-6.56,2.45-6.56.8,3.47-5.44,18.48c-.41,1-1.63,4.14-1.24,5.11.11.29.44,1,1,1.18,2.16.58,6.28-7,8.49-12,2.91-6.55,4.21-12.33,4.41-12.28A12.41,12.41,0,0,1,25,395c-.59,2.32-2.67,9.51-5,13.53-.26.44-1,1.76-.53,2.72a1.38,1.38,0,0,0,.55.59c1.49.63,6.48-5.64,10-13.29a72.47,72.47,0,0,0,3.32-9.29,31.48,31.48,0,0,1-2.91,10c-1.09,2.35-2.63,5.06-2,5.63a.82.82,0,0,0,.73,0c4.27-1.25,7.85-8.64,7.85-8.64,1.19-2.45,8-15.75,10.09-27.4.54-3,.76-5.46,1-7.43S47.45,357.88,48.5,354.94Z" />
              <path className="human_cls-1" data-part='forearm right' d="M52.12,264.52c-2.17,3.24-7.23,15.33-10.19,29.3s-7.53,41.64-7.53,41.64-3.26,11.73-4.58,14.05l18.68,5.43a16,16,0,0,1,1.14-2.45c18.85-33.32,26.62-55.24,31-74.32Z" />
              <line className="human_cls-3" x1="96.26" y1="174.22" x2="98.76" y2="174.22" />
              <line className="human_cls-12" x1="103.85" y1="174.22" x2="213.34" y2="174.22" />
              <line className="human_cls-3" x1="215.89" y1="174.22" x2="218.39" y2="174.22" />
              <path className="human_cls-3" d="M96.92,195c0-.83,0-1.66-.08-2.49" />
              <path className="human_cls-10" d="M96.67,187.27q-.61-19.47-1.24-38.93" />
              <path className="human_cls-3" d="M95.34,145.75c0-.83,0-1.67-.08-2.5" />
              <line className="human_cls-6" x1="52.15" y1="264.52" x2="80.64" y2="278.18" />
              <line className="human_cls-3" x1="29.85" y1="349.52" x2="32.25" y2="350.21" />
              <line className="human_cls-11" x1="36.88" y1="351.56" x2="43.82" y2="353.58" />
              <line className="human_cls-3" x1="46.13" y1="354.25" x2="48.53" y2="354.95" />
              <path className="human_cls-6" d="M95.11,214.83c-3.61-1.24-6.26-5.13-6.26-9.75,0-5.61,3.9-10.16,8.71-10.16a6.7,6.7,0,0,1,.9.05c4.39.52,7.82,4.85,7.82,10.11,0,5-3.16,9.23-7.3,10" />
              <path className="human_cls-6" d="M221.53,214.83c3.62-1.24,6.26-5.13,6.26-9.75,0-5.61-3.9-10.16-8.71-10.16a6.55,6.55,0,0,0-.89.05c-4.39.52-7.82,4.85-7.82,10.11,0,5,3.16,9.23,7.3,10Z" />
              <path className="human_cls-1" d="M187.31,63.52,180.7,85.39v.15a2.44,2.44,0,0,0,2.23,1.23c1.76,0,4.7-8,5.87-11.25s1.94-7.55,1.36-9.68A3.37,3.37,0,0,0,187.31,63.52Z" />
              <path className="human_cls-1" d="M129.52,63.51l-.08,0v0a3.43,3.43,0,0,0-2.92,2.33c-.58,2.13.2,6.39,1.36,9.68s4.11,11.25,5.86,11.25a2.46,2.46,0,0,0,2.32-1.38v.81c0-.5.07-.81.07-.81Z" />
              <path className="human_cls-6" d="M187.31,63.51,180.7,85.39s.09,5.42-1,8.13a16.08,16.08,0,0,1-5.15,7.17c-3,2.62-9.83,8.33-11.19,9.2s-5,1.16-5,1.16-3.6-.29-5-1.16-8.18-6.58-11.19-9.2a19.84,19.84,0,0,1-5.55-8.23c-1.07-2.71-.58-7.07-.58-7.07l-6.62-21.88" />
              <line className="human_cls-3" x1="158.5" y1="357.66" x2="158.5" y2="355.16" />
              <line className="human_cls-13" x1="158.5" y1="351.16" x2="158.5" y2="345.17" />
              <line className="human_cls-3" x1="158.5" y1="343.17" x2="158.5" y2="340.67" />
            </svg>
            
            ) : (

            <svg ref={svgRef} onClick={buttons ? handleSvgClick : null} xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 317 725">
              <defs>
                <style>
                  {`
                  .human_cls-1 {fill: #f4d8c1;}
                  .human_cls-2, .cls-3, .cls-4, .cls-5,
                  .human_cls-6 {fill: none; stroke: #1d1d1b; stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.5px;}
                  .human_cls-2 {stroke-dasharray: 5;}
                  .human_cls-4 {stroke-dasharray: 5.71 5.71;}
                  .human_cls-5 {stroke-dasharray: 5.14 5.14;}
                  .human_cls-6 {stroke-dasharray: 4.82 4.82;}
                  `}
                </style>
              </defs>
              <title>Human</title>
              <path className="human_cls-1" data-part='neck back' d="M140.5,105c1,3.49,1.17,14.72,1,15.88h34.06c-.2-1.16,0-12.39,1-15.88.76-2.71,2.21-7.39,3.1-10.26H137.4C138.29,97.56,139.74,102.24,140.5,105Z" />
              <path className="human_cls-1" data-part='back head' d="M187.39,63.51a48.31,48.31,0,0,0,1-15.68A27.71,27.71,0,0,0,185,38h0A30.61,30.61,0,0,0,169.4,24.21a34.79,34.79,0,0,0-10.75-2.07,34.83,34.83,0,0,0-10.75,2.07A30.65,30.65,0,0,0,132.31,38H132a27.71,27.71,0,0,0-3.41,9.84,48.32,48.32,0,0,0,1,15.66l.08,0,6.61,21.88s0,.31-.06.81a50.83,50.83,0,0,0,.3,5.1,9.48,9.48,0,0,0,.27,1.45c.13.43.34,1.12.6,1.94h42.2c.26-.82.47-1.51.6-1.94a13.33,13.33,0,0,0,.35-2.11c.23-2.32.23-5.25.23-5.25l.07.15v-.15l6.62-21.87Z" />
              <path className="human_cls-1" data-part='shin lower right' d="M196.4,669.34c-2.45-.41,0-13.83,0-13.83s1.26-3.87,1.16-5.51-.67-4.75-.67-4.75S197.43,633,198,628.4s9.44-47,11.47-55.71,1.31-21.79.58-28.32-2.47-15.25-2.47-15.25-.58-4.79-1.3-7.84c-.47-2-.82-3.92-1-5.07l-33,8a20.43,20.43,0,0,1-.49,9.71c-7.76,24.83-.65,55,.22,63.17.49,4.59,2.28,16.9,2.28,16.9s.58,25.85.29,27.69S173,646,173,648.35s1.16,4.74,1.16,6.68-1.64,15.78-1.26,17.72-3.79,29.76-3.09,31c3.78,6.89,19.65,4.33,21.56,4.11,7.13-.82,27.12-15.77,26.22-19.11C217.62,685.22,214.62,672.34,196.4,669.34Z" />
              <path className="human_cls-1" data-part='shin lower left' d="M145,597.08c.87-8.13,8-38.34.21-63.17a20.37,20.37,0,0,1-.42-10l-33-7.86c-.18,1.12-.54,3.16-1,5.21-.72,3.05-1.3,7.84-1.3,7.84s-1.75,8.71-2.47,15.25-1.45,19.6.58,28.32S118.41,623.85,119,628.4s1.16,16.85,1.16,16.85-.58,3.1-.67,4.75,1.16,5.51,1.16,5.51,2.41,13.42,0,13.83c-18.22,3-21.22,15.88-21.22,19.44-.9,3.34,19.09,18.29,26.22,19.11,1.91.22,17.78,2.78,21.56-4.11.7-1.27-3.48-29.1-3.09-31S142.81,657,142.81,655s1.16-4.36,1.16-6.68-1.26-4.84-1.55-6.68.29-27.69.29-27.69S144.5,601.67,145,597.08Z" />
              <path className="human_cls-1" data-part='back lower' d="M210.69,307.26v-5.61c0-2.46-3-13.28-4.45-22.7H110.76c-1.41,9.42-4.45,20.24-4.45,22.7v5.61s-6.49,21.88-7.94,33.11c0,.09,0,.2,0,.3H218.67c0-.1,0-.21,0-.3C217.18,329.14,210.69,307.26,210.69,307.26Z" />
              <path className="human_cls-1" data-part='back upper' d="M209.33,139s-5.81-3.1-7-3.68-16.65-6.77-21.5-9.78-5.22-3.58-5.42-4.74H141.2c-.19,1.16-.58,1.74-5.42,4.74s-20.33,9.2-21.49,9.78-7,3.68-7,3.68-8.72,2.71-12.4,4.26l.53,5.53.81,25.44q.64,20.44,1.3,40.88L102,234q2.14,5.17,4.31,10.35l2.48,6.78S111.4,268.65,111,274c-.13,1.6-.33,3.31-.6,5.07h95.85c-.27-1.76-.48-3.47-.6-5.07-.4-5.31,2.23-22.81,2.23-22.81l2.48-6.78L214.67,234l4.41-18.92q.66-20.44,1.31-40.88l.81-25.44.52-5.53C218,141.7,209.33,139,209.33,139Z" />
              <path className="human_cls-1" d="M144.9,532.79c.1.4.2.78.3,1.12C145.1,533.57,145,533.19,144.9,532.79Z" />
              <path className="human_cls-1" d="M144.47,530.18c0,.28.07.55.11.82C144.54,530.73,144.5,530.46,144.47,530.18Z" />
              <path className="human_cls-1" d="M144.37,528.77c0,.27,0,.54,0,.81C144.39,529.31,144.38,529,144.37,528.77Z" />
              <path className="human_cls-1" d="M144.66,531.53c0,.3.1.57.16.85C144.76,532.1,144.7,531.83,144.66,531.53Z" />
              <path className="human_cls-1" d="M144.35,528.13c0-.27,0-.54,0-.8C144.35,527.6,144.34,527.86,144.35,528.13Z" />
              <path className="human_cls-1" d="M144.45,525.87c0,.27-.06.54-.07.81C144.39,526.41,144.42,526.14,144.45,525.87Z" />
              <path className="human_cls-1" d="M144.7,524.33c-.07.31-.14.62-.18.94C144.56,525,144.63,524.64,144.7,524.33Z" />
              <path className="human_cls-1" d="M111.67,516.49v0Z" />
              <path className="human_cls-1" d="M111.36,518.22l0,.18Z" />
              <path className="human_cls-1" d="M111.47,517.62l0,.14Z" />
              <path className="human_cls-1" data-part='hip back left' d="M155.36,420.48H99.22c2.08,14.62,4.28,26.3,5,28.91,1.55,6,10.45,22.65,8,47.63-1.19,12-.29,18.16-.29,18.16s0,.33-.14.89l33,7.86a11.12,11.12,0,0,1,.42-1.27c1.38-3.27,3.56-11.84,3.56-14.38s.25-10.29.15-13.19,1.35-18.2,2.52-21.69,2.13-18,1.64-20.91-.87-14.53.2-19.37C153.92,430.16,154.78,424.79,155.36,420.48Z" />
              <path className="human_cls-1" d="M111.21,519v0Z" />
              <path className="human_cls-1" d="M111.07,519.68c0,.07,0,.13,0,.2S111.06,519.75,111.07,519.68Z" />
              <path className="human_cls-1" d="M110.92,520.39c0,.06,0,.13,0,.19S110.9,520.45,110.92,520.39Z" />
              <path className="human_cls-1" d="M205.66,518.31l0,.17Z" />
              <path className="human_cls-1" d="M205.54,517.69c0,.07,0,.13,0,.19S205.55,517.76,205.54,517.69Z" />
              <path className="human_cls-1" data-part='hip back right' d="M168.24,508.28c0,2.54,2.18,11.11,3.55,14.38a9,9,0,0,1,.49,1.54l33-8h0c-.11-.64-.17-1-.17-1s.9-6.16-.29-18.16c-2.46-25,6.44-41.63,8-47.63.67-2.61,2.88-14.29,5-28.91H161.64c.58,4.31,1.44,9.68,2.09,12.64,1.07,4.84.68,16.46.2,19.37s.48,17.42,1.64,20.91,2.62,18.78,2.52,21.69S168.24,505.74,168.24,508.28Z" />
              <path className="human_cls-1" d="M206.08,520.4a1.6,1.6,0,0,1,0,.21A1.6,1.6,0,0,0,206.08,520.4Z" />
              <path className="human_cls-1" d="M205.94,519.72l0,.21C206,519.86,206,519.79,205.94,519.72Z" />
              <path className="human_cls-1" d="M205.81,519.07l0,.18Z" />
              <path className="human_cls-1" d="M172.43,531c0-.21.06-.42.08-.63C172.49,530.53,172.46,530.74,172.43,531Z" />
              <path className="human_cls-1" d="M171.79,533.91c.11-.34.21-.71.3-1.1C172,533.2,171.9,533.57,171.79,533.91Z" />
              <path className="human_cls-1" d="M172.59,529.53l0-.52Z" />
              <path className="human_cls-1" d="M172.33,531.6c0,.25-.09.5-.14.75C172.24,532.1,172.29,531.86,172.33,531.6Z" />
              <path className="human_cls-1" d="M205.48,517.32l0-.17Z" />
              <path className="human_cls-1" d="M172.65,528.09v0Z" />
              <path className="human_cls-1" data-part='buttocks' d="M218.67,340.67H98.33c-.53,3.91-2.51,14.77-3.06,21.39-1.28,15.46,1.23,39.42,4,58.42h56.14c.37-2.75.62-5.06.62-6,0-2.52.09-6.68.77-8.81a47.71,47.71,0,0,1,1.74-4.46h0a47.71,47.71,0,0,1,1.74,4.46c.68,2.13.77,6.29.77,8.81,0,1,.25,3.29.62,6h56.14c2.72-19,5.23-43,3.95-58.42C221.18,355.44,219.2,344.58,218.67,340.67Z" />
              <path className="human_cls-2" d="M106.7,401.73c3.66,6.08,13.79,10.45,25.74,10.45s22.07-4.37,25.73-10.45h.66c3.66,6.08,13.79,10.45,25.73,10.45s22.08-4.37,25.74-10.45" />
              <line className="human_cls-2" x1="158.5" y1="401.73" x2="158.5" y2="378.75" />
              <line className="human_cls-3" x1="172.66" y1="524" x2="175.09" y2="523.41" />
              <line className="human_cls-4" x1="180.64" y1="522.06" x2="200.06" y2="517.33" />
              <line className="human_cls-3" x1="202.83" y1="516.66" x2="205.26" y2="516.07" />
              <line className="human_cls-3" x1="145.08" y1="524" x2="142.65" y2="523.41" />
              <line className="human_cls-4" x1="137.1" y1="522.06" x2="117.68" y2="517.33" />
              <line className="human_cls-3" x1="114.91" y1="516.66" x2="112.48" y2="516.07" />
              <line className="human_cls-2" x1="137.31" y1="94.69" x2="179.65" y2="94.69" />
              <line className="human_cls-2" x1="141.55" y1="120.79" x2="175.45" y2="120.79" />
              <line className="human_cls-2" x1="99.22" y1="420.48" x2="155.62" y2="420.48" />
              <line className="human_cls-2" x1="161.76" y1="420.48" x2="218.16" y2="420.48" />
              <line className="human_cls-2" x1="98.6" y1="340.67" x2="218.4" y2="340.67" />
              <line className="human_cls-2" x1="110.76" y1="278.95" x2="206.24" y2="278.95" />
              <path className="human_cls-1" data-part='shoulder inner left' d="M94.33,230.56a105.5,105.5,0,0,1,3.23-15.46q-1.14-35.92-2.29-71.85A25.5,25.5,0,0,0,83,150.85c-3.67,4.22-10.59,14.07-12.31,19.9s-3.5,13.61-3.25,17.74.4,10.59.4,10.59a60.92,60.92,0,0,1-2,6.39c-.82,1.84-3.88,12-4,17.83s-1.2,15.46-1.2,15.46-.93,3.89-1.88,7.72-4.42,14.8-6.59,18l28.49,13.65c1.3-5.64,2.69-10.17,3.72-13.28,4.11-12.36,8.37-19.56,9.56-30.42C94,233.44,94.08,232.46,94.33,230.56Z" />
              <path className="human_cls-1" data-part='shoulder inner right' d="M222.31,230.56a102.91,102.91,0,0,0-3.23-15.46q1.15-35.92,2.3-71.85a25.54,25.54,0,0,1,12.29,7.6c3.66,4.22,10.59,14.07,12.31,19.9s3.5,13.61,3.25,17.74-.4,10.59-.4,10.59a60.12,60.12,0,0,0,2,6.39c.83,1.84,3.88,12,4,17.83s1.2,15.46,1.2,15.46.93,3.89,1.89,7.72,4.41,14.8,6.58,18L236,278.17c-1.3-5.64-2.69-10.17-3.73-13.28-4.11-12.36-8.37-19.56-9.56-30.42C222.64,233.44,222.56,232.46,222.31,230.56Z" />
              <path className="human_cls-1" data-part='wrist right' d="M268.15,354.94l18.68-5.43a19.3,19.3,0,0,0,7.57,7.67c4.44,2.65,7.05,3.64,9.85,7.23s4.19,8,5.24,8.88c2.37,1.89,8.4,8.41,3.93,9-2.16.28-5.1-1.54-7.42-4.25-1.32-1.53-4.15-5-5.23-4.23s-1.29,2.51-.53,5,9.14,24.46,9.14,24.46,1,3.38.26,4.35-1.62,1.09-2.88.37-4.29-6-6-10.07-2.46-6.56-2.46-6.56-.8,3.47,5.45,18.48c.4,1,1.62,4.14,1.24,5.11-.12.29-.45,1-1,1.18-2.16.58-6.28-7-8.49-12-2.91-6.55-4.21-12.33-4.41-12.28a11.88,11.88,0,0,0,.56,3.07c.59,2.32,2.67,9.51,5,13.53.25.44,1,1.76.53,2.72a1.34,1.34,0,0,1-.56.59c-1.49.63-6.48-5.64-9.94-13.29a70.64,70.64,0,0,1-3.32-9.29,31.48,31.48,0,0,0,2.91,10c1.08,2.35,2.63,5.06,1.95,5.63a.8.8,0,0,1-.72,0c-4.27-1.25-7.86-8.64-7.86-8.64-1.18-2.45-8-15.75-10.08-27.4-.55-3-.77-5.46-1-7.43S269.19,357.88,268.15,354.94Z" />
              <path className="human_cls-1" data-part='forearm inner right' d="M264.52,264.52c2.18,3.24,7.24,15.33,10.2,29.3s7.52,41.64,7.52,41.64,3.26,11.73,4.59,14.05l-18.68,5.43a15.24,15.24,0,0,0-1.15-2.45c-18.84-33.32-26.62-55.24-31-74.32Z" />
              <path className="human_cls-3" d="M219.08,215.1c0-.84.06-1.67.08-2.5" />
              <path className="human_cls-5" d="M219.33,207.46l1.89-59.14" />
              <path className="human_cls-3" d="M221.3,145.75c0-.83.06-1.67.08-2.5" />
              <line className="human_cls-2" x1="264.49" y1="264.52" x2="236" y2="278.18" />
              <line className="human_cls-3" x1="286.79" y1="349.52" x2="284.39" y2="350.21" />
              <line className="human_cls-6" x1="279.77" y1="351.56" x2="272.83" y2="353.58" />
              <line className="human_cls-3" x1="270.52" y1="354.25" x2="268.12" y2="354.95" />
              <path className="human_cls-1" data-part='wrist left' d="M48.5,354.94l-18.68-5.43a19.3,19.3,0,0,1-7.57,7.67c-4.44,2.65-7,3.64-9.86,7.23s-4.19,8-5.23,8.88c-2.37,1.89-8.4,8.41-3.93,9,2.16.28,5.1-1.54,7.42-4.25,1.32-1.53,4.15-5,5.23-4.23s1.29,2.51.52,5-9.13,24.46-9.13,24.46-1,3.38-.27,4.35,1.62,1.09,2.89.37,4.28-6,6-10.07,2.45-6.56,2.45-6.56.8,3.47-5.44,18.48c-.41,1-1.63,4.14-1.24,5.11.11.29.44,1,1,1.18,2.16.58,6.28-7,8.49-12,2.91-6.55,4.21-12.33,4.41-12.28A12.41,12.41,0,0,1,25,395c-.59,2.32-2.67,9.51-5,13.53-.26.44-1,1.76-.53,2.72a1.38,1.38,0,0,0,.55.59c1.49.63,6.48-5.64,10-13.29a72.47,72.47,0,0,0,3.32-9.29,31.48,31.48,0,0,1-2.91,10c-1.09,2.35-2.63,5.06-2,5.63a.82.82,0,0,0,.73,0c4.27-1.25,7.85-8.64,7.85-8.64,1.19-2.45,8-15.75,10.09-27.4.54-3,.76-5.46,1-7.43S47.45,357.88,48.5,354.94Z" />
              <path className="human_cls-1" data-part='forearm inner left' d="M52.12,264.52c-2.17,3.24-7.23,15.33-10.19,29.3s-7.53,41.64-7.53,41.64-3.26,11.73-4.58,14.05l18.68,5.43a16,16,0,0,1,1.14-2.45c18.85-33.32,26.62-55.24,31-74.32Z" />
              <path className="human_cls-3" d="M97.56,215.1c0-.84,0-1.67-.08-2.5" />
              <path className="human_cls-5" d="M97.32,207.46l-1.89-59.14" />
              <path className="human_cls-3" d="M95.34,145.75c0-.83,0-1.67-.08-2.5" />
              <line className="human_cls-2" x1="52.15" y1="264.52" x2="80.64" y2="278.18" />
              <line className="human_cls-3" x1="29.85" y1="349.52" x2="32.25" y2="350.21" />
              <line className="human_cls-6" x1="36.88" y1="351.56" x2="43.82" y2="353.58" />
              <line className="human_cls-3" x1="46.13" y1="354.25" x2="48.53" y2="354.95" />
              <path className="human_cls-1" d="M187.47,63.52l-6.62,21.87v.15a2.47,2.47,0,0,0,2.24,1.23c1.75,0,4.69-8,5.86-11.25s2-7.55,1.36-9.68A3.36,3.36,0,0,0,187.47,63.52Z" />
              <path className="human_cls-1" d="M129.68,63.51l-.08,0v0a3.42,3.42,0,0,0-2.92,2.33c-.59,2.13.19,6.39,1.36,9.68s4.11,11.25,5.86,11.25a2.45,2.45,0,0,0,2.31-1.38s0,.32,0,.81c0-.5.06-.81.06-.81Z" />
              
              <path data-part='foot inner left' xmlns="http://www.w3.org/2000/svg" className="human_cls-1" d="M71.57,662.94a56.5,56.5,0,0,1,2.72,8.38c.06.32.61,4.3.64,5.87a28.4,28.4,0,0,1-.28,4.71c0,.29-.09.55-.14.81h0a19.55,19.55,0,0,1-.45,2.28,2.85,2.85,0,0,1-.13.5c-.31,1.18-.66,2.27-.91,3.14a5.62,5.62,0,0,0-.3,1.51,13,13,0,0,0,.11,1.65,2.7,2.7,0,0,1,0,.28,20.84,20.84,0,0,1,.32,4.79,15.41,15.41,0,0,1-2,5.12,29.1,29.1,0,0,1-2.79,4.15,4.21,4.21,0,0,1-2.52,1.25,5.85,5.85,0,0,1-2.24-.07,5.31,5.31,0,0,1-1.77-.75,3.32,3.32,0,0,1-1.16-1.25,10.54,10.54,0,0,1-1-4.29,37.36,37.36,0,0,1,1.25-7.54,1,1,0,0,1,0-.17s0-.09,0-.13c-.24.1-.5.21-.77.3l-.26.94a21.19,21.19,0,0,0-.45,3.36c0,.52-.1,1.05-.18,1.54a9.11,9.11,0,0,1-.6,2.13,3.69,3.69,0,0,1-1.67,2,3.63,3.63,0,0,1-2,.38,3.28,3.28,0,0,1-1.23-.35,2.78,2.78,0,0,1-.51-.33l-.14-.13c-.21-.22-.18-1.44.16-3a3,3,0,0,1-2.48,2.36c-1.52.07-2.5-1.72-2.8-2.26a3.43,3.43,0,0,1-.27-.68,3.13,3.13,0,0,1,0-1.59,20,20,0,0,0,.36-3.91c-.06.6-.35,3.41-1.71,3.78a2.32,2.32,0,0,1-.33.05,4.22,4.22,0,0,1-2.9-.91s-1-.93-1-4.59c0-.24,0-.46,0-.46a3.8,3.8,0,0,1,.07-.42c.07-.3.19-.93.37-1.8A2.22,2.22,0,0,1,41,691.33a1.82,1.82,0,0,1-1.47-.41c-.76-.69-.34-1.71-.42-4.32v-.37a7.5,7.5,0,0,1,.12-1.43c.17-1.13-1.7-6.43-2.22-8.2a23.42,23.42,0,0,1-.76-7.16,48.43,48.43,0,0,1,.53-6.73c.06-.47.13-1,.2-1.44.41-2.72.95-5.55,1.5-8.14.73-3.42,1.48-6.42,2-8.17,1-3.26,2.08-9.36,3.49-16,.25-1.16.51-2.35.78-3.54.4-1.8.83-3.61,1.28-5.39A18.13,18.13,0,0,1,49,613.48a12,12,0,0,1,1.91-2,10.32,10.32,0,0,1,2.2-1.43l.84-.36c.28-.11.56-.2.85-.29a11.39,11.39,0,0,1,1.47-.32,12,12,0,0,1,7.4,1.22,11.28,11.28,0,0,1,5,5q.24.5.45,1a13,13,0,0,1,.46,1.62,14.77,14.77,0,0,1,.27,2.59c.06,3.59-1,7.38-1.74,10.44-.16.65-.29,1.35-.4,2.07-.18,1.14-.31,2.34-.41,3.54-.26,3.07-.3,6.12-.44,7.94a29.91,29.91,0,0,0,1,8.64C68.19,654.2,69.88,658.44,71.57,662.94Z"/>
              <path data-part='foot inner right' xmlns="http://www.w3.org/2000/svg" className="human_cls-1" d="M245.43,662.94a56.5,56.5,0,0,0-2.72,8.38c-.06.32-.61,4.3-.64,5.87a28.4,28.4,0,0,0,.28,4.71c0,.29.09.55.14.81h0a19.55,19.55,0,0,0,.45,2.28,2.85,2.85,0,0,0,.13.5c.31,1.18.66,2.27.91,3.14a5.62,5.62,0,0,1,.3,1.51,13,13,0,0,1-.11,1.65,2.7,2.7,0,0,0,0,.28,20.84,20.84,0,0,0-.32,4.79,15.41,15.41,0,0,0,2,5.12,29.1,29.1,0,0,0,2.79,4.15,4.21,4.21,0,0,0,2.52,1.25,5.85,5.85,0,0,0,2.24-.07,5.31,5.31,0,0,0,1.77-.75,3.32,3.32,0,0,0,1.16-1.25,10.54,10.54,0,0,0,1-4.29,37.36,37.36,0,0,0-1.25-7.54,1,1,0,0,0,0-.17s0-.09,0-.13c.24.1.5.21.77.3l.26.94a21.19,21.19,0,0,1,.45,3.36c.05.52.1,1.05.18,1.54a9.11,9.11,0,0,0,.6,2.13,3.69,3.69,0,0,0,1.67,2,3.63,3.63,0,0,0,2,.38,3.28,3.28,0,0,0,1.23-.35,2.78,2.78,0,0,0,.51-.33l.14-.13c.21-.22.18-1.44-.16-3a3,3,0,0,0,2.48,2.36c1.52.07,2.5-1.72,2.8-2.26a3.43,3.43,0,0,0,.27-.68,3.13,3.13,0,0,0,0-1.59,20,20,0,0,1-.36-3.91c.06.6.35,3.41,1.71,3.78a2.32,2.32,0,0,0,.33.05,4.22,4.22,0,0,0,2.9-.91s1-.93,1-4.59c0-.24,0-.46,0-.46a3.8,3.8,0,0,0-.07-.42c-.07-.3-.19-.93-.37-1.8a2.22,2.22,0,0,0,1.61,1.79,1.82,1.82,0,0,0,1.47-.41c.76-.69.34-1.71.42-4.32v-.37a7.5,7.5,0,0,0-.12-1.43c-.17-1.13,1.7-6.43,2.22-8.2a23.42,23.42,0,0,0,.76-7.16,48.43,48.43,0,0,0-.53-6.73c-.06-.47-.13-1-.2-1.44-.41-2.72-1-5.55-1.5-8.14-.73-3.42-1.48-6.42-2-8.17-1-3.26-2.08-9.36-3.49-16-.25-1.16-.51-2.35-.78-3.54-.4-1.8-.83-3.61-1.28-5.39a18.13,18.13,0,0,0-2.95-6.56,12,12,0,0,0-1.91-2,10.32,10.32,0,0,0-2.2-1.43l-.84-.36c-.28-.11-.56-.2-.85-.29a11.39,11.39,0,0,0-1.47-.32,12,12,0,0,0-7.4,1.22,11.28,11.28,0,0,0-5,5q-.24.5-.45,1a13,13,0,0,0-.46,1.62,14.77,14.77,0,0,0-.27,2.59c-.06,3.59,1,7.38,1.74,10.44.16.65.29,1.35.4,2.07.18,1.14.31,2.34.41,3.54.26,3.07.3,6.12.44,7.94a29.91,29.91,0,0,1-1,8.64C248.81,654.2,247.12,658.44,245.43,662.94Z"/>
            </svg>
          )}
        </div>
      </Box>
    </Box>
    {title && (
        <>
          <Stack>
            <Typography variant="body1" sx={{textAlign:"center"}}><T>{"humanselector "+part}</T></Typography>
          </Stack>
        </>
      )}
    </Box>

  )

}