import React, { useEffect } from 'react';
//import { useLoaderData } from "react-router-dom";
import MyDataGrid from "../components/MyDataGrid"


import { T, t } from "../Globals.js"
import Heading from '../components/Heading';
import { Box } from '@mui/material';


const columns = [
  { field: '_clinic', headerName: t("clinic"), flex: 1, valueGetter: (params) => params.value?.name},
  { field: 'name', headerName: t("name"), flex: 1 },
  { field: 'email', headerName: t("email"), flex: 1 },
  { field: '_roles', headerName: t("Роли"), width: 250,
      renderCell: (params) => {
        const roles = 
          Object.keys(params.row)
            .filter((key) => /role/.test(key) && (params.row[key]) && (key !== "roles"))
            .map(el => el.replace("role_", ""));
        return <div style={{display:"flex", flexWrap: "wrap"}}>{roles.map(role => 
          <span key={role} style={{
              marginRight: 5, 
              color: role==='expert' ? 'orange'
                : role==='superadmin' ? 'red' 
                : role==='administrator' ? 'blue'
                : role==='accountant' ? 'green'
                : 'black'}}>{role}</span>)}</div>;
      },
  },
  { field: 'note', headerName: t("note"), width: 250,
      renderCell: (params) => {
        if (params.row?.blockedmessage) {
          return "Заблокирован: "+params.row?.blockedmessage
        }

        return null;
      },
  }
];


export function ProfilesList() {

  useEffect(() => {
    window.glob("title", <T>profiles</T>)
  }, [])


  return (
    <Box sx={{px: 2}}>
      <Heading heading={<T>administrator doctors</T>} style={{margin: "20px auto 0 auto"}} />
      <MyDataGrid
        columns={columns}
        table="profiles"
        linktable = "profiles"
        sortModel={{field:"name",sort:"asc"}}
        searchCols={[{ col: 'name' }, { col: 'email' }, { col: '_clinic', searchFunc: "name" }]}
      />
    </Box>
  );
}