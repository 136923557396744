import React,{} from 'react';

import {Box, Stack, Typography} from '@mui/material';

//import Budget from '../cards/Budget';
import Balance from '../components/cards/Balance';
// import GExpensesCategory from '../graphics/GExpensesCategory';
// import GExpense from '../components/graphics/GExpense';
//import RecentOrders from '../RecentOrders';
// import GOrders from '../graphics/GOrders';
// import ExpertsList from '../components/cards/ExpertsList';
// import MainSlider from '../sliders/MainSlider';
import NewsSlider from '../components/sliders/NewsSlider';

// import PromoMain from '../components/cards/PromoMain';
// import {T} from "../Globals.js"
import PromoDescription from '../components/PromoDescription';


function MyComponent() {

  return (
    <Stack sx={{gap:4, alignItems:"center", mt:4}}>
      {/* <Welcome /> */}

      <Stack sx={{flexDirection: {lg: "row", xs: "column"}, width: "100%", maxWidth:window.maxWidth, gap: 2, justifyContent: "space-between", px:0, mb: 4}}>
        <Typography variant='myH2' fontSize={{xs:22, md:28}} sx={{maxWidth: 500, alignSelf: "center", px: 2, py:4}}>Сервис оценки дерматоскопических изображений для пользователей HANDYSCOPE/DERMLITE</Typography>
        <Box sx={{maxWidth:470, margin: "0 auto"}}>
          <img style={{margin: "auto", marginTop: 6, display: "block", width:"100%" }} alt='dermatoscope' src='/img/FF-image-603x376-4.jpg'></img>
        </Box>
        {/* <Box sx={{px: 3, backgroundColor: "#f2f2f5", width: "inherit", maxWidth: {xs: "100%", md:400}, height: {xs: 260, lg:300}, position: "relative", "img": {maxWidth: {xs: 240, lg: 280}}}}>
          <img style={{margin: "auto", marginTop: 6, display: "block"}} alt='dermatoscope' src='/img/dermatoscope.png'></img>
        </Box> */}
      </Stack>
    {/*
      <PromoMain/>
  */}
      <Stack sx={{flexDirection: {md: "row", xs: "column"}, maxWidth:window.maxWidth, width: "100%", gap: 2, px: 0, justifyContent: "flex-start", mb: 4}}>
        <Balance />
        <Box sx={{width: "100%"}}>
          <NewsSlider />
        </Box>
      </Stack>
      <Stack sx={{maxWidth:window.maxWidth, flexDirection: "column", width: "100%", gap: 4}}>
        <PromoDescription/> 
      </Stack>     
{/*      
      <ExpertsList/>
*/}
      


      {/* <Grid container sx={{ maxWidth:window.maxWidth}} columns={10} spacing={"1.5rem"}>
        <Grid item xs={10} md={4}>
          <Balance />
        </Grid>
        <Grid item xs={10} md={6}>
          <NewsSlider />
        </Grid>
      </Grid> */}



      


      
      {/* <GExpense sx={{width: "100%", maxWidth:window.maxWidth}}/> */}


      {/* Бэкап */}

      {/* SECTION-7 */}
      {/* <Grid container sx={{mt: 7, justifyContent: "center", gap: 5}} >
        <Grid item xs={12} md={4}>
          <GOrders
            chartSeries={[20, 10, 70]}
            count={[30, 10, 60]}
            labels={["В обработке", "Просрочено", "Готово"]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <GExpensesCategory
            chartSeries={[30, 70]}
            labels={["AI", "specialist"]}
            sx={{ height: "100%" }}
          />
        </Grid>
      </Grid> */}

      {/* SECTION-1 */}
      {/* <Box sx={{display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: 2}} className={scss.section1}>
        <Stack sx={{gap: 3, minWidth: "20%", flexWrap: "wrap"}}> 
          <Budget
            difference={12}
            positive
            sx={{ height: "fit-content", width: '100%' }}
            money="10.000"
            balls="30"
          />
          <Balance
            difference={12}
            sx={{ height: "fit-content", width: '100%' }}
            ai="15"
            specialist="10"
          />
        </Stack> */}
        {/* <GExpense styles={{width: "70%"}}/> */}
        {/* <Box sx={{ width: "45%" }}>
          <NewsSlider />
        </Box>
      </Box> */}

      {/* SECTION-2 */}
      {/* <Box sx={{pt: 7}}>
        <MainSlider />
      </Box> */}

      {/* SECTION-3 */}
      {/* <Box sx={{ mt: 7, display: "flex", justifyContent: "center" }}>
        <Button variant="contained" color="primary" sx={{p: 2, fontSize: "1.5rem"}}>
          Создать новый запрос
        </Button>
      </Box> */}

      {/* SECTION-4 */}
      {/* <Box sx={{display: "flex", gap: 12, mt: 12}}>
        <GExpense />
        <GExpensesCategory
          chartSeries={[30, 70]}
          labels={["AI", "specialist"]}
          sx={{ height: '100%', width: '30%'}}
        />
      </Box> */}

      {/* SECTION-5 */}
      {/* <Box sx={{display: "flex", gap: 12, mt: 7}}>
        <RecentOrders />
        <GOrders
          chartSeries={[20, 10, 70]}
          count={[30, 10, 60]}
          labels={["В обработке", "Просрочено", "Готово"]}
          sx={{ height: '100%', width: '30%'}}
        />
      </Box> */}

      {/* SECTION-6 */}
      {/* <Box sx={{mt: 8}}>
        <ExpertsList/>
      </Box> */}
    </Stack>
  )
}

export default MyComponent;