import React, {  useState } from 'react';
import {  T,  getImageUrl } from "../Globals.js"
import { Stack, TextField, Box, Button, InputLabel, Input,Paper,Typography } from '@mui/material';
import ItemTemplate from "../components/ItemTemplate";
import MyImage from '../components/Image';
import Pdf from "../components/Pdf";
import Img from "../components/Img";
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import { RequestsItemView } from "./RequestItem.js";
import ReadOnlyField from "../components/ReadOnlyField";
import DiagnosisSelector from '../components/DiagnosisSelector.js';
import StatusChip from '../components/StatusChip.js';
import {ReactComponent as IconMelanomehead} from "../icons/melanomehead.svg"
import dayjs from 'dayjs';

export function AisItem(props) {
  let [data, setData] = useState({ });
  const sendHandler = async () => { data.status='answered' }
  const rejectHandler = async () => { data.status='reject' }
  //const deleteHandler = async () => { }
  const cancelHandler = async () => {}
  if (data.status==="answered") {
    return (
      <ItemTemplate {...props} title={<T>ais item</T>} data={data} setData={setData} table="ais" buttons={["close"]}  onCancel={cancelHandler} >
        {window.glob("user").role_superadmin ?
          <AisItemEdit />
          :
          <AisItemViewWrapper />
        }
      </ItemTemplate>
    )

  }
  return (
    <ItemTemplate {...props} title={<T>ais item</T>} table="ais" buttons={["send","reject"]} data={data} setData={setData} onSend={sendHandler} onReject={rejectHandler} onCancel={cancelHandler} >
      <AisItemEdit />
    </ItemTemplate>
  )
}


function AisItemViewWrapper(prop) {
  return (
    <>
      {prop.data?._request && RequestsItemView({data: prop.data._request})}
      <AisItemView {...prop}/>
    </>
  )
}

export function AisItemView({data}) {
  const r = data

  console.log(r);

  return (
    <Box className="responseAi" sx={{mt: "1.5rem"}}>
      <Typography variant='myDefault22'><T>report ai</T></Typography>
      <Paper className='paperAi' sx={{my:2, mb:4, border: "1px solid #686895", display: "flex", flexDirection: {xs: "column", md: "row"}}} key={data._id}>
        <Box className="infoAi" sx={{gap: 2, display: "flex", alignItems: {xs:"center", md:"inherit", sm:"inherit"},flexDirection: "column",  borderRight: {xs: "unset", md: "1px solid #686895"}, p:2, maxWidth: {md: 265}}}>
          <Box sx={{ width: '100%', height: "120px", overflow: 'hidden', "svg": {width: "100%", height: "inherit"}}}>
            <IconMelanomehead/>
          </Box>
          <Typography variant='myDefault18' color="black" fontWeight={500}><T>report ai descr</T></Typography>
        </Box>

        <Stack className="responseInfoAi" sx={{minWidth: {md:"330px"}, flex: "auto"}}>
          <Box className="container" sx={{borderBottom: {xs: "unset", md:"1px solid #686895"}}}>
            <Stack className="title" sx={{p:{md:2}, px:2, margin:{xs: "auto", md:"inherit"}, maxWidth: "fit-content",alignItems: "center", flexDirection: {xs: "column", sm: "row"}, justifyContent: "center", flexWrap: "wrap"}}>
              <Typography variant='myDefault18' color="black" fontWeight={500}><T>card ai</T></Typography>
              <StatusChip type="ai" {...data} sx={{mx: {sm:2.5}, my: {xs:1, md:0}}}/>
              <div style={{marginTop: "4px"}}>{dayjs(data.date).format("DD.MM.YYYY HH:mm")}</div>
            </Stack>
          </Box>

          <Box p={2} sx={{display: "flex", alignItems: "center", flexDirection: "column"}}>
            <Img src={getImageUrl(r,"pdfpng")} style={{width:"100%", height:"auto"}} />
            {r.comment && <ReadOnlyField label={<T>comment</T>} value={r.comment} />}
            <Button sx={{mt:2}} variant="contained" color="primary" component="a" href={getImageUrl(r,"pdf")} download startIcon={<FileDownloadRoundedIcon />} >
              <T>download</T>
            </Button>
          </Box>
        </Stack>
      </Paper>
    </Box>

  )
}


export function AisItemEdit(prop) {
  const handleChange = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.value })
  }
  /*
  const handleCheck = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.checked })
  }
  */
  const [imageSrc, setImageSrc] = useState(getImageUrl(prop.data,"pdf"));

  const handleLoadPdf = event => {
    if (event.target.files?.length > 0) { 
      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = function(ev) {
        const base64data = ev.target.result;  
        setImageSrc(base64data);
        //onChange(base64data)
        prop.setData({...prop.data, pdf:base64data})
      }
      event.target.value = null;
    }
  }

  return (
    <Stack sx={{ gap: 2 }}>
      

      <Box>
        <MyImage src={getImageUrl(prop.data?._request, "image")} />
        <Button variant="contained" color="primary" component="span" startIcon={<FileDownloadRoundedIcon />}>
          <T>download</T>
        </Button>
      </Box>

      <T>upload ai report</T>
     
      <Pdf src={imageSrc} style={{width:200, height:200}} />
      <InputLabel htmlFor="fileInput">
        <Input id="fileInput" type="file" accept="image/*" style={{display: "none"}} onChange={handleLoadPdf} />
        <Button variant="contained" color="primary" component="span" startIcon={<FileUploadRoundedIcon />}>
          <T>upload</T>
        </Button>
      </InputLabel>

      <DiagnosisSelector data={prop.data} setData={prop.setData} />

      <TextField
        fullWidth
        label={<T>comment</T>}
        value={prop.data?.comment || ""}
        name="comment"
        onChange={handleChange}
      />


    </Stack>
  )
}

