import React, {  useState } from 'react';
import {  axios, T, Alert } from "../Globals.js"
import { Stack, TextField, MenuItem, Button} from '@mui/material';
import ItemTemplate from "../components/ItemTemplate";
import LinkField from "../components/LinkField";
import { useNavigate } from 'react-router-dom';

export function AccountantItem(props) {
  let [data, setData] = useState({ });
  const saveHandler = async () => { }
  const deleteHandler = async () => { }
  const cancelHandler = async () => {}

  return (
    <>
      <ItemTemplate {...props} title={<T>accountant item</T>} defaults={{status:"completed", paymentmethod:"gift"}} table="accountant" data={data} setData={setData} onSave={saveHandler} onCancel={cancelHandler} onDelete={deleteHandler}>
        <AccauntantItemView  />
      </ItemTemplate>
    </>
  )
}



export function AccauntantItemView(prop) {
  const navigate = useNavigate();
  const handleChange = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.value })
  }
  /*
  const handleCheck = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.checked })
  }
  */
  const confirmHandler = async function () {
    const res = await axios.post("/api/accountant/confirm/"+prop.data._id)
    if (res.data.result === "ok") navigate("/accountant")
    else {Alert(res.data.message); }
  }

  
  return (
    <Stack sx={{ gap: 2 }}>
      <LinkField label={<T>clinic</T>} table="clinics" data={prop.data} name="clinicid" onChange={handleChange} field="_clinic"/>
      
      <TextField
        fullWidth
        label={<T>billNo</T>}
        value={prop.data?.billNo || ""}
        name="billNo"
        aria-readonly
      />

      <TextField
        fullWidth
        select
        label={<T>payment status</T>}
        value={prop.data?.status || ""}
        name="status"
        onChange={handleChange}
      >
        <MenuItem value="pending"><T>payment pending</T></MenuItem>
        <MenuItem value="completed"><T>payment completed</T></MenuItem>
        <MenuItem value="failed"><T>payment failed</T></MenuItem>
        <MenuItem value="refunded"><T>payment refunded</T></MenuItem>
      </TextField>


      <TextField
        fullWidth
        label={<T>comment</T>}
        value={prop.data?.comment || ""}
        name="comment"
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label={<T>amount rub</T>}
        value={prop.data?.amount_rub || 0}
        name="amount_rub"
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label={<T>amount so</T>}
        value={prop.data?.amount_so || 0}
        name="amount_so"
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label={<T>amount ai</T>}
        value={prop.data?.amount_ai || 0}
        name="amount_ai"
        onChange={handleChange}
      />

      <TextField
        sx={{mt: 3}}
        select
        label={<T>Срок на FF</T>}
        value={prop.data?.unlimitedFF || ""}
        onChange={handleChange}
        name="unlimitedFF"
      >
        <MenuItem value="quartal"><T>Квартал</T></MenuItem>
        <MenuItem value="year"><T>Год</T></MenuItem>
        <MenuItem value="gift"><T>Подарок</T></MenuItem>
        <MenuItem value="trial"><T>Триал</T></MenuItem>
        <MenuItem value="trial 30"><T>Триал 30 дней</T></MenuItem>
      </TextField>

      <TextField
        sx={{mt: 3}}
        select
        label={<T>payment method</T>}
        value={prop.data?.paymentmethod || ""}

        onChange={handleChange}
        name="paymentmethod"
      >
        <MenuItem value="bank"><T>payment method bank</T></MenuItem>
        <MenuItem value="card"><T>payment method card</T></MenuItem>
        <MenuItem value="gift"><T>payment method gift</T></MenuItem>
        <MenuItem value="trial"><T>payment method trial</T></MenuItem>
      </TextField>

      <Button variant="contained" onClick={confirmHandler} disabled={prop.data?.status==='pending'?false:true}><T>transaction confirm</T></Button>

    </Stack>
  )
}