import { axios } from "../Globals"
import Cookies from 'js-cookie'
import io from "socket.io-client"

let socket = null;

function socketInit() {
  socket = io.connect(axios.defaults.baseURL, {
    extraHeaders: {
      "Authorization": Cookies.get('token')
    }
  }); // Устанавливаем соединение с сервером

  socket.on('balance', newData => {
    console.log(newData);
    window.glob("balance", newData)
  });
  socket.on('expertbalance', newData => {
    console.log(newData);
    window.glob("expertbalance", newData)
  });
  socket.on('aisbalance', newData => {
    console.log(newData);
    window.glob("aisbalance", newData)
  });
  socket.on('newRequestsCount', newData => {
    console.log(newData);
    window.glob("newRequestsCount", newData.count)
  });
  socket.on('newAisCount', newData => {
    console.log(newData);
    window.glob("newAisCount", newData.count)
  });
  socket.on('requestsUnreadCount', newData => {
    console.log(newData);
    window.glob("requestsUnreadCount", newData.count)
  });
  socket.on('bondage', newData => {
    console.log("!!!BONDAGE!!!");
    console.log(newData);
    // window.glob("balance", newData)
  });
  //setTimeout(()=>{
 //   socket.emit('hello',"world");
  //},3000)
  // Отправка запроса на сервер
  //socket.emit('get_data');
}

export { socketInit, socket }