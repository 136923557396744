import React from 'react';
import { Stack, Button} from '@mui/material';
import {T} from "../Globals.js"

function Sex(props) {

  let gender = {
    male: {
      variant: "outlined",
      svgColor: "#7D7D7F",
    },
    female: {
      variant: "outlined",
      svgColor: "#7D7D7F",
    }
  };

  if (props.data?.sex) {
    gender[props.data.sex] = {variant: "contained", svgColor: "white"};
  }

  return (
    <Stack sx={{gap: 3, flexDirection: "row", my: 2}}>
      <Button name="sex" value="male" variant={gender.male.variant} onClick={props.handleChange} startIcon={
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none"><g clipPath="url(#clip0_408_4459)"><path d="M9.5 21.5C14.4706 21.5 18.5 17.4706 18.5 12.5C18.5 7.52944 14.4706 3.5 9.5 3.5C4.52944 3.5 0.5 7.52944 0.5 12.5C0.5 17.4706 4.52944 21.5 9.5 21.5Z" stroke={gender.male.svgColor} strokeMiterlimit="10"/><path d="M21.2004 0.799805L15.9004 6.0998" stroke={gender.male.svgColor} strokeMiterlimit="10"/><path d="M16.8008 0.5C18.3008 0.5 19.7008 0.5 21.2008 0.5C21.2008 2.1 21.2008 3.7 21.2008 5.3" stroke={gender.male.svgColor} strokeMiterlimit="10"/></g><defs><clipPath id="clip0_408_4459"><rect width="21.7" height="22" fill="white"/></clipPath></defs></svg>
      }><T>male</T></Button>
      <Button name="sex" value="female" variant={gender.female.variant} onClick={props.handleChange} startIcon={
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none"><g clipPath="url(#clip0_408_4555)"><path d="M9 21.2998C13.6944 21.2998 17.5 17.4942 17.5 12.7998C17.5 8.10538 13.6944 4.2998 9 4.2998C4.30558 4.2998 0.5 8.10538 0.5 12.7998C0.5 17.4942 4.30558 21.2998 9 21.2998Z" stroke={gender.female.svgColor} strokeMiterlimit="10"/>  <path d="M15.0996 6.6999L21.3996 0.399902" stroke={gender.female.svgColor} strokeMiterlimit="10"/>  <path d="M21.3996 6.6999L15.0996 0.399902" stroke={gender.female.svgColor} strokeMiterlimit="10"/></g><defs>  <clipPath id="clip0_408_4555"><rect width="21.8" height="21.9" fill="white"/></clipPath></defs></svg>
      }><T>female</T></Button>
    </Stack>
  );
}

export default Sex;