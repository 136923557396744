import React, { useState,useContext } from 'react';

import { TextField, Button, Typography, Box, Stack } from '@mui/material';

//import Template from "../TemplateGuest.js";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'

import {Context,axios,T, Alert} from "../Globals.js"
import {socketInit} from "../controllers/socketController"
import PasswordField from "../components/PasswordField"

const Login = () => {
  const navigate = useNavigate();
  const {globalState, setGlobalState} = useContext(Context);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const resp = await axios.post('/api/login', formData);

      if (resp.data.result==="ok") {
        Cookies.set("token",resp.data.data.token,{ expires: 100, path: '/' })
        axios.defaults.headers.common["Authorization"] = resp.data.data.token;
        setGlobalState({...globalState, user:resp.data.data})
        navigate('/');
        socketInit()
        //console.log(socket)
      } else {
        if (resp.data?.blockeduser) {
          Alert("","","error", resp.data)
          return;
        }
        Alert(resp.data.message)
      }

    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
      <Box sx={{ maxWidth: {xs: "100%", sm:375}, mx: 'auto' }}>
        <Typography variant="myDefault22" sx={{mx: "auto", mt: 4, mb: 2, display: "block", textAlign: "center", lineHeight: "initial"}}><T>login</T></Typography>
        <form onSubmit={handleSubmit}>
          <Stack gap={2}>
            <TextField
              required 
              fullWidth
              type="email"
              name="email"
              label="Email"
              onChange={handleChange}
              value={formData.email}
            />
            <PasswordField
              required
              fullWidth
              name="password"
              onChange={handleChange}
              value={formData.password}
            />
          </Stack>
          
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{display: "block", m: "0 auto", mt: 3}}
          >
            <T>login</T>
          </Button>
        </form>
        <Button variant="text" onClick={()=>navigate("/forgottpassword")} sx={{display: "block", m: "0 auto", mt: 1.5}}><T>forgott password</T></Button>        
        <Button variant="text" onClick={()=>navigate("/register")} sx={{display: "block", m: "0 auto", mt: 1.5}}><T>register</T></Button>        
      </Box>
  );
};

export default Login;
