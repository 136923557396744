import React, { useEffect , useState} from 'react';
//import { useLoaderData } from "react-router-dom";
import MyDataGrid from "../components/MyDataGrid"
import {  Box, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';


import { T, t, axios} from "../Globals.js"
import Heading from '../components/Heading';
import dayjs from 'dayjs';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  { field: 'date', headerName: t("date"), width: 130, renderCell: (params) => {
    if (params.formattedValue) return dayjs(params.formattedValue).format("DD.MM.YYYY HH:mm");
  } },
  { field: 'amount_rub', headerName: t("amount rub"), width: 130, renderCell: (params) => {
    if (params.formattedValue) {
      const value = params.formattedValue;
      let color = "green";

      if (Math.sign(value) === -1 && Math.sign(value) !== 0) color = "red";
      return <span style={{color: color}}>{value}</span>
    };
  } },
  { field: '_expert', headerName: t("expert item"), width: 130, valueGetter:(p)=>p.row._expert?.name||'' },
  { field: 'akt', headerName: t("expertpayments akt"), width: 130 },
  //{ field: 'expertid', headerName: t("expert"), width: 130 },
];  


export function AccountantExpertpaymentsList() {
  const[stat, setStat] = useState([]);

  useEffect(() => {
    window.glob("title", <T>expert payments</T>)
    axios.get("/api/accountantexpertpayments/stat").then((r)=>{
      //console.log(r)
      if (r.data.status === "ok") {
        setStat(r.data.data)
      }
    })

  }, [])

  const create = () => {
    window.navigate('/accountantexpertpayments/create');
  }


  return (
    <Box sx={{px: 2}}>
      <Fab sx={{position:"fixed", right:20, bottom:20}} onClick={create} variant="extended" color="primary"><AddIcon /><T>create</T></Fab>
      <Heading heading={<T>expert payments</T>}></Heading>
      <MyDataGrid
        columns={columns}
        table="accountantexpertpayments"
      />

      <Heading heading={<T>expert statistic</T>}></Heading>
      <DataGrid 
        columns={[
          {field:"name", headerName:t('name'), width:300,valueGetter:(p)=>p.row._expert?.name||'' },
          {field:"month", headerName:t('month'), width:130,valueGetter:(p)=>(p.row._id.year+"-"+(p.row._id.month))||'' },
          {field:"totalRub", headerName:t('total'), width:300},
        ]}
        rows={stat.map((a,i)=>{a.id=i; return a})}
        sx={{maxWidth:window.maxWidth, mx:"auto"}}
        rowHeight={25}
      />
      
      
    
    </Box>
  );
}