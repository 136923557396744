import React, { useState, useEffect } from 'react';
import { T , axios, getImageUrl} from "../Globals.js"
import  Img from "../components/Img"
import { Box, Paper, Stack, Typography } from '@mui/material';
import { useParams } from "react-router-dom";
import Heading from '../components/Heading.js';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {ReactComponent as AcademicIcon} from "../icons/experts/academic.svg"
import {ReactComponent as ExperienceIcon} from "../icons/experts/experience.svg"
import {ReactComponent as SpecializationIcon} from "../icons/experts/specialization.svg"
import ItemTemplate from "../components/ItemTemplate";

export function ExpertsItem(props) {
  const params = useParams();
  let [data, setData] = useState({});

  let user = window.glob("user")
  let id = props.data?._id || props.id || params.id || user.clinicid
  
  const saveHandler = async () => {}
  const cancelHandler = async () => {}

  useEffect(()=>{
    window.glob("title", <T>expert</T>)
    axios.get("/api/users/experts").then((resp)=>{
      if (resp.data.result==="ok") {
        for(let i in resp.data.data) {
          if (resp.data.data[i]._id === params.id) {
            setData(resp.data.data[i])
          }
        }
      }
    })
  },[params.id]);

  return (
    <ItemTemplate {...props} id={id} table="experts" data={data} setData={setData} onSave={saveHandler} onCancel={cancelHandler} buttons={["close"]}>
      <ExpertView  />
    </ItemTemplate>
  )
}


export function ExpertView({data, ...prop}) {
  return (
    <Box sx={{width:"100%", display: "flex", flexDirection: "column", gap: 2}}>
      <Heading heading={data.name}></Heading>
      <Stack sx={{display: "flex", flexDirection: "row", gap: 4}}>
        <Img src={getImageUrl(data)} style={{width:"200px", height:"200px", borderRadius: "1rem"}} />
        <List sx={{display: "flex", flexDirection: "column", gap: 1, p:0}}>
          {data.academic &&
            <ListItem sx={{gap: 2, maxWidth: 390, alignItems: "inherit" }}>
              <AcademicIcon style={{minWidth: 40, }}/>
              <ListItemText sx={{display: "flex", flexDirection: "column-reverse", m:0}}
                secondary={<Typography variant='body2' color="primary"><T>academic...</T></Typography>} 
                primary={<Typography variant='body1' color="black">{data.academic}</Typography>} 
              />
            </ListItem>
          } 
          {data.regalii &&
            <ListItem sx={{gap: 2, maxWidth: 390, alignItems: "inherit" }}>
              <SpecializationIcon style={{minWidth: 40, }}/>
              <ListItemText sx={{display: "flex", flexDirection: "column-reverse", m:0}}
                secondary={<Typography variant='body2' color="primary"><T>specialization...</T></Typography>} 
                primary={<Typography variant='body1' color="black">{data.regalii}</Typography>} 
              />
            </ListItem>
          }
          {data.experience &&
            <ListItem sx={{gap: 2, maxWidth: 390, alignItems: "inherit" }}>
              <ExperienceIcon style={{minWidth: 40, }}/>
              <ListItemText sx={{display: "flex", flexDirection: "column-reverse", m:0}} 
                secondary={<Typography variant='body2' color="primary"><T>experience...</T></Typography>}
                primary={<Typography variant='body1' color="black">{data.experience}</Typography>} 
              />
            </ListItem>
          }
        </List>
      </Stack>
      
      <Paper sx={{my: 1, backgroundColor: window.theme.palette.white.v2, p:2, display: "flex", flexDirection: "column"}}>
        <Heading heading={<T>aboutme</T>} style={{marginTop: 0}} line={false}></Heading>
        <Typography sx={{whiteSpace: "pre-line"}}>{data.longaboutme}</Typography>
      </Paper>

      <Paper sx={{my: 1, backgroundColor: window.theme.palette.white.v2, p:2, display: "flex", flexDirection: "column"}}>
        <Heading heading={<T>direction activity</T>} style={{marginTop: 0}} line={false}></Heading>
        <Typography sx={{whiteSpace: "pre-line"}}>{data.aboutme}</Typography>
      </Paper>
    </Box>
  )
}


