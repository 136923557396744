import React, {  } from 'react';
import { Typography, Box } from "@mui/material";
import {T} from "../Globals.js";

export default function DiagnosisSelector({data, sx={}}) {
  let spanPosition = (data?.confidence?.toString().split(".")[1])?.substring(0,2); 

  const renderSpan = () => {
    if (data?.outclass_name) {
      if (data?.outclass_name === "benign") {
        spanPosition = 100 - spanPosition
      }

      return <span className={`absolute -top-4 -translate-x-1/2`} style={{left:`${spanPosition}%`, content: '', border: "10px solid transparent", borderTop: "10px solid black"}}></span>;
    }
    return null;
  };

  const categories = [
    { name: "benign", gradient: "from-yellow-400 to-yellow-600", width: "w-[20%]", label: "< 20%", },
    { name: "observation", gradient: "from-gray-400 to-gray-600", width: "w-[30%]", label: "< 50%", },
    { name: "malignant", gradient: "from-red-400 to-red-600", width: "w-[50%]", label: ">= 50%", }
  ];

  return (
    <Box sx={{ my: 3, maxWidth: "450px", ...sx }}>
      <Typography component="div" sx={{ fontSize: "1.1rem", textAlign: "center", mb: 3 }}>
        <T>malignancy risk assessment</T>
      </Typography>

      {data?.outclass_name &&
        <>
          <div className='flex  mx-auto gap-[1px] relative'>
            {renderSpan()}
            {categories.map((category) => (
              <div key={category.name}className={`text-center ${category.width}  ${data?.outclass_name === category.name ? "underline" : ""}`}>
                <div className={`w-full mb-1 h-6 bg-gradient-to-r ${category.gradient}`}></div>
                <T>{category.label}</T>
              </div>
            ))}
          </div>

          <Typography component="div" sx={{ fontSize: "1.1rem", textAlign: "center", mt:2 }}>
            <T>probability malignant</T>: <strong>{spanPosition}%</strong>
          </Typography>
        </>
      }

      
    </Box>
  );
}