import React, { useState } from 'react';
import {T } from "../Globals.js";

import { Box, InputLabel, Button, Input } from "@mui/material";
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import Img from "../components/Img";



function ImageUpload({ label, src, name, onChange}) {

  const [imageSrc, setImageSrc] = useState(src);
  const handleLoadImage = event => {
    if (event.target.files?.length > 0) { 
      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = function(ev) {
        const base64data = ev.target.result;  
        setImageSrc(base64data);
        onChange(base64data)
      }
      event.target.value = null;
    }
  }

  return (
    <Box sx={{m: 2, display: "flex", flexDirection:"column", gap: 1, alignItems: "center"}}>
      {label}
      <InputLabel htmlFor="fileInput">
        <Input id="fileInput" type="file" accept="image/*" style={{display: "none"}} onChange={handleLoadImage} />
        <Button variant="contained" color="primary" component="span" startIcon={<FileUploadRoundedIcon />}><T>upload image</T></Button>
      </InputLabel>
      <Img src={imageSrc} style={{width:"200px", height:"200px"}} />
    </Box>

  );
}
export default ImageUpload;
