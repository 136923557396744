import React, {  useState } from 'react';
import { T, axios, getImageUrl } from "../Globals.js"
import { Stack, TextField, Box, Paper, Typography, Button} from '@mui/material';
import ItemTemplate from "../components/ItemTemplate";
import ReadOnlyField from "../components/ReadOnlyField";
import StatusChip from "../components/StatusChip";
import {RequestsItemViewWrapper } from "./RequestItem.js";
import { Alert,t } from '../Globals.js';
import Img from '../components/Img.js';
import dayjs from 'dayjs';
import CountdownTimer from '../components/CountDown.js';
import Help from '../components/Help.js';
import SuperAdminPanel from '../components/SuperAdminPanel.js';
import DiagnosisSelector from '../components/DiagnosisSelector.js';

const textFields = [
  {
    name: "grade",
    label: <T>answer grade</T>,
    multiline: true,
  },
  {
    name: "differential",
    label: <T>answer differential diagnosis</T>,
    multiline: true,
  },
  {
    name: "recommendations",
    label: <T>answer recommendations</T>,
    multiline: true,
  },
  // {
  //   name: "answer",
  //   label: <T>answer answer</T>,
  //   multiline: true,
  // },
];


export function ResponsesItem(props) {

  let [data, setData] = useState({});

  const sendHandler = async () => { 
    return Alert(<T>answer confirm</T>, <ResponsesItemView data={data} />,"confirm");
  }
  const rejectHandler = async () => { 
    return new Promise((resolve,reject) => {
      Alert(<T>response reject</T>, <></>, "prompt").then((resp) => {
        data.status="reject";
        if (resp?.comment) {
          delete data.grade;
          delete data.differential;
          delete data.recommendations;
          data.answer = resp.comment;
        }
        resolve();
      }).catch((error) => {
        reject();
      });
    }) 
  }
  const deleteHandler = async () => {}
  const cancelHandler = async () => {}
  
  
  if (data?.status==="answered" || data?.status==="reject") {
    return (
      <ItemTemplate {...props} title={<T>response item</T>} table="responses" data={data} onSend={sendHandler} setData={setData} buttons={["close"]} >
        {window.glob("user").role_superadmin ?
        <ResponsesItemEdit  />:
        <ResponsesItemViewWrapper onSend={sendHandler}/>
        }
        
      </ItemTemplate>
    )
  } 
  return (
    <ItemTemplate {...props} title={<T>response item</T>} table="responses" data={data} setData={setData} buttons={["send", "reject"]} onSend={sendHandler} onReject={rejectHandler} onCancel={cancelHandler} onDelete={deleteHandler}>
      <ResponsesItemEdit  />
    </ItemTemplate>
  )
}

export function ResponsesItemEdit(prop) {
//  const handleChange = (event) => {
//    prop.setData({ ...prop.data, [event.target.name]: event.target.value })
//  }

  const handleUncontrolledChange = (event) => {
    prop.data[event.target.name] = event.target.value;
  }
  // prop.data["data"] = {_pacient: prop.data._request._pacient};

  
  return (
    <Stack sx={{ gap: 2 }}>
      {prop.data?._request && <RequestsItemViewWrapper data={prop.data._request}/>}
      
      {/* <DiagnosisSelector data={prop.data} setData={prop.setData} /> */}
      <Help><T>responses item help</T></Help>
      {!window.glob("user").role_superadmin && 
        <CountdownTimer minutes={30} dateTaken={prop.data.dateTaken} zeroHandler={()=>{
          Alert(t("time reached zero")).then(()=>{window.navigate("/responses")})
        }}/>
      }
      <SuperAdminPanel {...prop} handleChange={handleUncontrolledChange}/>
      {
        textFields.map((field, key) => (
          <TextField multiline key={key} name={field.name} label={field.label} defaultValue={prop.data[field.name] || ""} onChange={handleUncontrolledChange} />
        ))
      }
      
    </Stack>
  )
}

function ResponsesItemViewWrapper(prop) {

  return (
    <>
    {prop.data?._request && RequestsItemViewWrapper({data: prop.data._request})}
      <ResponsesItemView {...prop}/>
    </>
  )
}

export function ResponsesItemView({data, setData}) {

  function handleReprocessing(event) {
    Alert("Переобработка", "Введите пречину отмены", "prompt").then(d => {
      axios.post(`/api/responses/reprocessing/${data._id}?newanswer=${event.target.value}`, {
        comment: d.comment,
      }).then(resp => {
        if (resp.data.result === "error") {
          Alert(<T>{resp.data.message}</T>);
          return
        };
        setData({...data, ...resp.data.data});
      });
    }).catch((error) => {
      console.log(error);
    });;
  }

  return (
    <Box className="responseExpert" sx={{mt: "1.5rem", pageBreakBefore:"always"}}>
      <Typography variant='myDefault22'><T>report expert</T></Typography>
      <Paper className='paperExpert' sx={{my:2, mb:4, border: "1px solid #686895", display: "flex", flexDirection: {xs: "column", md: "row"}}} key={data._id}>
        <Box className="infoExpert" sx={{gap: {xs: 0, sm: 2, md: 0}, display: "flex", alignItems: {xs:"center", md:"inherit", sm:"inherit"},flexDirection: {xs: "column", md: "column", sm: "row"},  borderRight: {xs: "unset", md: "1px solid #686895"}, p:2, maxWidth: {md: 265}}}>
          {/* <Image src="" alt="expert" sx={{width: 135, height: 135, borderRadius: 2}}/> */}
          <center>
            <Img src={getImageUrl(data._expert)} style={{width:"150px", height:"150px", objectFit:"cover"}} alt="expert" />
            {data?.diagnosis && <DiagnosisSelector data={data}/>}
          </center>

          <Stack className="infoExpertText" mt={2}>
            <Typography variant='caption'><T>expert</T>:</Typography>
            <Typography variant='myDefault18' color="black" fontWeight={500}>{data?._expert?.name && data._expert.name.split(" ").join('\n')}</Typography>
            <Typography variant='caption' mt={1}>{data?._expert?.regalii}</Typography>
          </Stack>
        </Box>

        <Stack className="responseInfo" sx={{minWidth: {md:"330px"}, flex: "auto"}}>
          <Box className="container" sx={{display: "flex", justifyContent: "space-between", borderBottom: {xs: "unset", md:"1px solid #686895"}}}>
            <Stack className="title" sx={{py:{md:2}, px:0, margin:{xs: "auto", md:"inherit"}, maxWidth: "fit-content",alignItems: "center",flexDirection: {xs: "column", sm: "row"},  justifyContent: "center", flexWrap: "wrap"}}>
              {/*<Typography variant='myDefault18' color="black" fontWeight={500}><T>answer grade</T></Typography>*/}
              <StatusChip className="chip" type={data.ai ? "ai": "so"} {...data} sx={{mx: {sm:2.5}, my: {xs:1, md:0}}}/>
              <div style={{marginTop: "4px"}}>{dayjs(data.date).format("DD.MM.YYYY HH:mm")}</div>
            </Stack>
            {window.glob("user")?.role_superadmin && 
              <Stack sx={{display: "flex", flexDirection: "row"}}>
                <Button color='primary' sx={{mr:1}} value={true} onClick={handleReprocessing} disabled={data?.reprocessing || data?.refund}>Переобработка</Button>
                <Button color='secondary' value={false} onClick={handleReprocessing} disabled={data?.reprocessing || data?.refund}>Возврат средств</Button>
              </Stack>
            }
          </Box>

          <Stack sx={{p: 2, gap: 0}}>
            {data.grade && <ReadOnlyField sx={{"h6":{fontSize: 18}}} divider={false} index={"1. "} label={<T>answer grade</T>} value={data.grade} />}
            {data.differential && <ReadOnlyField sx={{"h6":{fontSize: 18}}} divider={false} index={"2. "} label={<T>answer differential diagnosis</T>} value={data.differential} />}
            {data.recommendations && <ReadOnlyField sx={{"h6":{fontSize: 18}}} divider={false} index={"3. "} label={<T>answer recommendations</T>} value={data.recommendations} />}
            {data.answer && <ReadOnlyField sx={{"h6":{fontSize: 18}}} divider={false} index={data?.status === "reject" ? "" : "4. "} label={<T>answer answer</T>} value={data.answer} />}
            {data.reprocessing && <ReadOnlyField sx={{"h6":{fontSize: 18, color: "red"}}} divider={false} label="Причина переобработки" value={data.reprocessing} />}
            {data.refund && <ReadOnlyField sx={{"h6":{fontSize: 18, color: "red"}}} divider={false} label="Причина отмены" value={data.refund} />}
          </Stack>
        </Stack>
      </Paper>
    </Box>
  )
}