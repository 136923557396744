import React, {  } from 'react';
import {Box } from "@mui/material";
//import scss from "./StatusChip.module.scss";

//import {T} from "../Globals.js";
const yello = "#ffdd00"
const gray = "#777"
const red = "#dd0000"
const blue = "#bbeeff"

export default function StatusChip({type=null, diagnosis='', status="", text=''}) {
  let backgroundColor = "#ffffff";

  if (type==="ai" && diagnosis) {
    backgroundColor = diagnosis === "safely" ? yello : diagnosis === "oncologist" ?  red : gray;
  }
  if (type==="so" && status === "answered") {
    backgroundColor = blue
  }
  if (type==="so" && status === "reject") {
    backgroundColor = blue
  }
  return <Box 
    sx={{ width: 18, height: 18, backgroundColor: backgroundColor, borderRadius: "100%", border:"1px solid gray"}}
  />
}