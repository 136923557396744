import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {T} from "../Globals.js"

const PasswordField = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const MINSIMBOLS = 6;

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      label={props?.label || <T>password</T>}
      sx={{...props?.sx}}

      error={(props?.value).length < MINSIMBOLS && (props?.value).length !== 0 ? true : false}
      helperText={(props?.value).length < MINSIMBOLS && (props?.value).length !== 0 ? <T>error password simbols</T> : null }

      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleTogglePassword}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
