import React, { useEffect } from 'react';
//import { useLoaderData } from "react-router-dom";
import MyDataGrid from "../components/MyDataGrid"
import { Box, Button, Stack, Typography } from '@mui/material';

import { T, t, axios, Alert, getImageUrl} from "../Globals.js"
import { useNavigate } from 'react-router-dom';
import Img from "../components/Img"
import Heading from '../components/Heading';
import StatusChip from '../components/StatusChip';
import dayjs from 'dayjs';

const columns = [
  {
    field: '',
    headerName: t("image"),
    sortable: false,
    width: 80,
    renderCell: (params) => {
      const imageUrl = getImageUrl(params.row._request, 'image', 'small');
      return <div style={{display: "flex", justifyContent: "center", width: "100%"}}><Img src={imageUrl} alt="Image" /></div>;
    }      
  },

  { field: 'date', headerName: t("date"), width: 130, type: 'dateTime', renderCell: (params) => {
    return params.row.date ? <div style={{textWrap: "wrap"}}>{dayjs(params.row.date).format("DD.MM.YYYY")} {dayjs(params.row.date).format("HH:mm")}</div> : null;
  }, valueGetter: (params) => {
    return params.value ? new Date(params.value) : null;
  },},
 // { field: 'requestid', headerName: t("request"), width: 130 },
  //{ field: 'expertid', headerName: t("expert"), width: 130 },
  // { field: '_expert', headerName: t("expert"), width: 130, valueGetter:(p)=>p.row._expert?.name||'' },
  { field: 'status', headerName: t("status answer"), width: 130, renderCell: (params) => {
    return <Stack sx={{display: 'flex', flexDirection: 'column', gap: 0.3}}>
      <StatusChip type="so" key={params._id} status={params.value} text={params.value} />
    </Stack>
  } },
  
];  


export function ResponsesList() {
  const navigate = useNavigate();


  useEffect(() => {
    window.glob("title", <T>responses</T>)
  }, [])

  const takeNextHandler = () => {
    axios.get("/api/responses/takenext").then((resp)=>{
      if (resp.data.result==="ok") {
        navigate("/responses/"+resp.data.data._id)
      }
      else if (resp.data.result==="okerror") {
        Alert(resp.data.message)
        navigate("/responses/"+resp.data.data._id)
        
      } else {
        Alert(resp.data.message)
      }
    })
  }


  return (
    <Box sx={{px: 2}}>
      <Heading heading={<T>expert responses</T>} subheading={<T>expert responses help</T>} style={{marginBottom:0}}></Heading>
      <div style={{width:"100%",maxWidth:window.maxWidth, margin:"1rem auto"}}>
        <Typography><T>count news requests</T>: <strong>{window.glob("newRequestsCount")}</strong></Typography>
        <Button sx={{mt:1}} onClick={takeNextHandler} variant="contained"><T>take next</T></Button>
      </div>

      <MyDataGrid
        columns={columns}
        table="responses"
        searchCols={[{ col: 'date' }]}

      />
    </Box>
  );
}