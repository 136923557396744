import React, { useEffect } from 'react';
//import { useLoaderData } from "react-router-dom";
import MyDataGrid from "../components/MyDataGrid"
import GExpense from '../components/graphics/GExpense.js';
import {  Box } from '@mui/material';

import { T, t} from "../Globals.js"
import Heading from '../components/Heading';
import dayjs from 'dayjs';

const columns = [
  { field: 'date', headerName: t("date"), width: 130, renderCell: (params) => {
    if (params.formattedValue) return dayjs(params.formattedValue).format("DD.MM.YYYY HH:mm");
  } },
  { field: 'amount_rub', headerName: t("amount rub"), width: 130, renderCell: (params) => {
    if (params.formattedValue) {
      const value = params.formattedValue;
      let color = "green";

      if (Math.sign(value) === -1 && Math.sign(value) !== 0) color = "red";
      return <span style={{color: color}}>{value}</span>
    };
  } },
  { field: 'note', headerName: t("note"), flex: 1, renderCell:(p)=>{
    if ((p.row?.amount_rub < 0) && (p.row._response?.reprocessing || p.row._response?.refund)) {
      return p.row._response?.reprocessing || p.row._response?.refund;
    }
    return "";
  } },
//  { field: '_response', headerName: t("response item"), width: 130, valueGetter:(p)=>p.row._response?._request?.name||'' },
  //{ field: 'requestid', headerName: t("request"), width: 130 },
  //{ field: 'expertid', headerName: t("expert"), width: 130 },
];  


export function ExpertpaymentsList() {


  useEffect(() => {
    window.glob("title", <T>expert payments</T>)
  }, [])



  return (
    <Box sx={{px: 2}}>
      <Heading heading={<T>expert payments</T>} subheading={<T>expert payments help</T>}></Heading>
      <MyDataGrid
        columns={columns}
        table="expertpayments"
      />
      
    
    </Box>
  );
}
export function ExpertpaymentsListStatistics() {
  return <GExpense sx={{mt: 5}} table="expertpayments" charts={["seriesRubPlus"]} />
}