import { useState, useEffect } from 'react';
// import dayjs from 'dayjs';

//import { useTheme } from '@mui/material/styles';

import ReactApexChart from 'react-apexcharts';
import {T,t,axios, Alert} from "../../Globals.js"
import {
  Box,
  Button, ButtonGroup,
  Grid,
  Stack,
  Typography
} from '@mui/material';

const DashboardDefault = ({data, sx, table="transactions", charts=[]}) => {


  const [slot, setSlot] = useState('week');
  //const [startPeriod, setStartPeriod] = useState('minusmonth');
  let now = new Date();
  let minusMonth = new Date();
  minusMonth.setMonth(now.getMonth()-1);
  let minusYear = new Date();
  minusYear.setYear(now.getFullYear() - 1);



  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  useEffect(()=>{
    axios.post("/api/"+table+"/statistics",{slot:slot, year:now.getFullYear(), /*period:[startPeriod==='minusyear'?minusYear:minusMonth,now]*/}).then((resp) => {
      if (resp.data.result !== "ok") {
        Alert(resp.data.message).then(()=>{});
        return;
      }

      setOptions((prevState) => ({
        ...prevState,
        xaxis: {
          categories: resp.data.data?.options
        },
        markers: {
          size: 2,
        }
      }));
      setSeries(charts.map((x)=>{return {
        name:t(x),
        data: resp.data.data?.[x]
      }}))
        
    })
    // eslint-disable-next-line
  },[slot]);

  if (series) {
    const index = series.findIndex(item => item.name === "seriesAIMinus");
    if (index !== -1) series.splice(index, 1);
  }

  return (
    <Box sx={sx}>
      <Grid container alignItems="center" justifyContent="center" gap={4}>
        <Grid item>
          <Typography component="h2" variant="myDefault22"><T>{"statistic "+table}</T></Typography>
        </Grid>
        <Grid item>
          <Stack direction="row" alignItems="center" spacing={2}>
            <ButtonGroup>
              <Button
                size="small"
                onClick={() => setSlot('year')}
                variant={slot === 'year' ? 'contained' : 'outlined'}
              >
                <T>year</T>
              </Button>
              <Button
                size="small"
                onClick={() => setSlot('month')}
                variant={slot === 'month' ? 'contained' : 'outlined'}
              >
                <T>month</T>
              </Button>
              <Button
                size="small"
                onClick={() => setSlot('week')}
                variant={slot === 'week' ? 'contained' : 'outlined'}
              >
                <T>week</T>
              </Button>
              <Button
                size="small"
                onClick={() => setSlot('day')}
                variant={slot === 'day' ? 'contained' : 'outlined'}
              >
                <T>day</T>
              </Button>
            </ButtonGroup>
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ pt: 1 }}>
        <ReactApexChart options={options} series={series} type="bar" height={450} />
        {/* <IncomeAreaChart slot={slot} /> */}
      </Box>
    </Box>
  );
};

export default DashboardDefault;
