import React, { useState } from 'react';
import { Box, CircularProgress, Button } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomOutMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomOutMap';

// position: absolute;
// width: 100vw;
// height: 100vh;
// z-index: 1000000;
// top: 0;

const Controls = ({ zoomIn, zoomOut, resetTransform, handleFullScreen }) => {

  return (
    <Box className={"imageControls"} sx={{position:"absolute", top:0, left:0, zIndex:1999, display:"flex", gap:1, m:1}}>
      <Button variant="contained" onClick={() => zoomIn()}><ZoomInIcon /></Button>
      <Button variant="contained" onClick={() => zoomOut()}><ZoomOutIcon /></Button>
      <Button variant="contained" onClick={() => resetTransform()}><ZoomOutMapIcon /></Button>
      <Button variant="contained" onClick={() => handleFullScreen()}><ZoomInMapIcon /></Button>
    </Box>
  );
};

function MyImage({src, alt, className, handleFullScreen, sx={}}) {

  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [saveSrc, setSaveSrc] = useState("");
  //const isDesktop = useMediaQuery('(min-width: 915px)');
  // console.log(loaded,error)
  const handleSetError = () => {
    setSaveSrc(src);
    setError(true);
  }

  return (
    <Box className={className} position="relative"  bgcolor="grey.300" display="flex" justifyContent="center" alignItems="center" sx={{
      mb:2,
      minWidth: 285,
      minHeight:285,
      ...sx
    }}>

      {!loaded && !error && src && <CircularProgress />}

      {!error || (saveSrc !== src) ? (
        <Box>
          <TransformWrapper     
            initialScale={1}
            minScale={1}
          >
            {(utils) => (
              <>
                <Controls {...utils} handleFullScreen={handleFullScreen}/>
                <TransformComponent  wrapperStyle={{
                  width: "100%",
                  height: "100%",
                  ...sx
                }} contentStyle={{ width: "100%", height: "100%" }}>
                  <img 
                    src={src}
                    alt={alt}
                    onError={() => handleSetError()}
                    onLoad={() => setLoaded(true)}
                    style={{
                      width: "100%",
                      height: "100%",
                      aspectRatio: "1 / 1",
                      
                      objectFit:"contain"}}
                  />
                </TransformComponent>
              </>
            )} 
          </TransformWrapper>
        </Box>
      ) : (
        <ImageIcon sx={{ fontSize: 200 }} />
      )}
    </Box>
  );
}

export default MyImage;
