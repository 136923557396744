import React, { useEffect } from 'react';
//import { useLoaderData } from "react-router-dom";
import MyDataGrid from "../components/MyDataGrid"
//import { Button } from '@mui/material';
import { Box, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { T, t } from "../Globals.js"
import { useNavigate } from 'react-router-dom';
import Heading from '../components/Heading';
import dayjs from 'dayjs';
//import IncomeAreaChart from '../components/graphics/GExpensePayments';

const columns = [
  { field: 'date', headerName: t("date"), width: 130, renderCell: (params) => {
    if (params.formattedValue) return dayjs(params.formattedValue).format("DD.MM.YYYY HH:mm");
  } },
  { field: '_clinic', headerName: t("clinic"), flex:1, valueGetter:(p)=>p.row._clinic?.name||'' },

  { field: 'amount_so', headerName: t("amount so"), width: 130, renderCell: (params) => {
    if (params.formattedValue) {
      const value = params.formattedValue;
      let color = "green";
      if (Math.sign(value) === -1 && Math.sign(value) !== 0) color = "red";
      return <span style={{color: color}}>{value}</span>
    };
  } },  
  { field: 'amount_ai', headerName: t("amount ai"), width: 130, renderCell: (params) => {
    if (params.formattedValue) {
      const value = params.formattedValue;
      let color = "green";
      if (Math.sign(value) === -1 && Math.sign(value) !== 0) color = "red";
      return <span style={{color: color}}>{value}</span>
    };
  } },
  { field: 'amount_rub', headerName: t("amount rub"), width: 130, renderCell: (params) => {
    if (params.formattedValue) {
      const value = params.formattedValue;
      let color = "green";

      if (Math.sign(value) === -1 && Math.sign(value) !== 0) color = "red";
      return <span style={{color: color}}>{value}</span>
    };
  } },
  { field: 'paymentmethod', headerName: t("payment method"), width: 130, renderCell:(params)=>t("payment method "+params.formattedValue)  },
  { field: 'status', headerName: t("payment status"), width: 130, renderCell:(params)=>t("payment "+params.formattedValue)  },
];  


export function AccountantList() {
  const navigate = useNavigate();


  useEffect(() => {
    window.glob("title", <T>accountant</T>)
  }, [])

  const create = () => {
    navigate('/accountant/create');
  }

  return (
    <Box sx={{px: 2}}>
      <Fab sx={{position:"fixed", right:20, bottom:20}} onClick={create} variant="extended" color="primary"><AddIcon /><T>create</T></Fab>
      <Heading heading={<T>bills</T>} style={{margin: "20px auto 0 auto"}}></Heading>
      <MyDataGrid
        columns={columns}
        table="accountant"
      />
      
    </Box>
  );
}