import React, {  useState } from 'react';

import {  T } from "../Globals.js"
import { Stack,TextField } from '@mui/material';
import ItemTemplate from "../components/ItemTemplate";
import LinkField from "../components/LinkField";
import ReadOnlyField from '../components/ReadOnlyField.js';

export function ExpertpaymentsItem(props) {
  let [data, setData] = useState({ });

  const saveHandler = async () => {}
  const deleteHandler = async () => {}
  const cancelHandler = async () => {}

  return (
    <>
      <ItemTemplate {...props} title={<T>expert payments item</T>} table="expertpayments" data={data} setData={setData} onSave={saveHandler} onCancel={cancelHandler} onDelete={deleteHandler}>
        <ExpertpaymentsItemView  />
      </ItemTemplate>
    </>
  )
}



export function ExpertpaymentsItemView(prop) {
  const handleChange = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.value })
  }
  /*
  const handleCheck = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.checked })
  }
  */
  return (
    <Stack sx={{gap:2}}>

      {prop.data?._expert?.role_superadmin ?
        <TextField
          fullWidth
          label={<T>amount rub</T>}   
          value={prop.data?.amount_rub || ""}
          name="amount_rub"
          onChange={handleChange}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        />
       : 
       <ReadOnlyField label={<T>amount rub</T>} value={prop.data?.amount_rub || ""}/>
       }

      <LinkField data={prop.data} table="responses" field="_response" label={<T>response item</T>} name="responseid" onChange={handleChange} readOnly={true} disabledCreate={prop.data?._expert?.role_superadmin ? false : true}/>
      {(prop.data?._response?.reprocessing || prop.data?._response?.refud) && <ReadOnlyField label={<T>note</T>} value={prop.data?._response?.reprocessing || prop.data?._response?.refud}/>}
    </Stack>
  )
}