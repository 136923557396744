import { useEffect, useState } from "react";


function useValidation(data = {}, recuireds = []) {
  const [myerrors, setErrors] = useState(recuireds);

  useEffect(() => {
    const emptys = [];
    let currentRecuireValue = "";

    function recursion(d) {
      if (typeof d === "object") {
        if (Object.keys(d).length > 0) {
          let vals = []
          Object.values(d).forEach(el => {
            if (typeof el === "object") recursion(el);
            else vals.push(el);
          })
          const goodVals = vals.filter(val => !!val);
          if (!goodVals.length) addEmptys();

        } else addEmptys();
      } else check(d);
    }

    function check(el) { if (!el) addEmptys() }

    function addEmptys() { if (!emptys.includes(currentRecuireValue)) emptys.push(currentRecuireValue) }

    function validation(d) {
      recuireds.forEach(rec => {
        currentRecuireValue = rec;
        if (d?.[rec]) recursion(d[rec])
        else if (rec === "image") {
          if (!d?.hasOwnProperty("image") && !d?.hasOwnProperty("_image")) addEmptys();
        } 
        else check(d[rec])
      });
    }

    if (data) validation(data);
    setErrors(emptys);
    // eslint-disable-next-line
  }, [data, setErrors]);

  function filtered(val) {
    if (myerrors.includes(val)) setErrors(myerrors.filter(er => er !== val));
    return myerrors; 
  }

  return {myerrors, setErrors, filtered};
}

export default useValidation;