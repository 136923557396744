import React, {  } from 'react';
import { T} from "../Globals.js"
import {  Box, Typography,   } from '@mui/material';

import Heading from '../components/Heading.js';



export function Contacts() {
  return (
    <Box sx={{width:"100%", maxWidth:window.maxWidth, margin:"20px auto", px: 2}}>
      <Heading heading={<T>contacts</T>} ></Heading>
      <Typography>
      Телефон: 8 800 333 96 87<br />
      Email: <a href="mailto:info@melanomenet.ru">info@melanomenet.ru</a>
      </Typography>

      
      <Heading heading="Реквизиты"></Heading>
      <Typography>
        Обладателем исключительной лицензии на платформу app.melanomenet.ru
        является <span style={{whiteSpace: "nowrap"}}>ООО «Клиника Премиум Эстетикс»</span><br /><br />
        ООО "КЛИНИКА ПРЕМИУМ ЭСТЕТИКС"<br />
        ИНН 7703392033<br />
        ОРГН 1157746770862<br />
        КПП 770301001<br />
        123022, г. Москва, ул. Рочдельская, дом 15, строение 16, этаж 1, помещение I, комната 3<br />
        р/с 40702810838000051930, в банке ПАО Сбербанк, БИК 044525225, к/с 30101810400000000225
      </Typography>
      
      
    </Box>
  );
} 