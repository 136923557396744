import React, {  useState } from 'react';

import {  T } from "../Globals.js"
import { Stack,Typography,Box } from '@mui/material';
import ItemTemplate from "../components/ItemTemplate";
import LinkField from "../components/LinkField";

export function AispaymentsItem(props) {
  let [data, setData] = useState({ });

  const saveHandler = async () => {  }
  const deleteHandler = async () => { }
  const cancelHandler = async () => {}

  return (
    <>
      <ItemTemplate {...props} title={<T>ais payments item</T>} table="aispayments" data={data} setData={setData} onSave={saveHandler} onCancel={cancelHandler} onDelete={deleteHandler}>
        <AispaymentsItemView  />
      </ItemTemplate>
    </>
  )
}



export function AispaymentsItemView(prop) {
  const handleChange = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.value })
  }
  /*
  const handleCheck = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.checked })
  }
  */
  return (
    <Stack sx={{gap:3}}>
      <Box sx={{display: "flex", flexDirection: "column"}}>
        <Stack sx={{display: "flex", flexDirection: "row", mt: 0.5, alignItems: "center", gap: 2}}>
          <Box sx={{display: "flex", flexDirection: "column"}}>
            <Typography variant='h6' component="div"><T>amount rub</T>:</Typography>
          </Box>
          <Box sx={{display: "flex", flexDirection: "column"}}>
            <Typography variant='h6' component="div">{prop.data?.amount_rub}</Typography>
          </Box>
        </Stack>
      </Box>

      <LinkField data={prop.data} table="ais" field="_ais" label={<T>ais item</T>} name="aisid" onChange={handleChange} />
    </Stack>
  )
}