import React, {useContext,useEffect, useState} from 'react';
import { Chip,AppBar,IconButton, Stack,Box,SvgIcon, Divider, FormControlLabel, Switch , Button, Tooltip} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import {Context, T, axios, formatNumber, getFirstEnddateDermatoscope} from "../Globals.js";
import { Link as RouterLink } from 'react-router-dom';
import {ReactComponent as IconAi} from "../icons/ai.svg"
import {ReactComponent as IconSo} from "../icons/so.svg"
import {ReactComponent as IconRub} from "../icons/rub.svg"
import {ReactComponent as IconProfile} from "../icons/profile.svg"
import {ReactComponent as IconAlarm} from "../icons/alarm.svg"
import {socket} from "../controllers/socketController.js"

import scss from "./Header.module.scss";
import dayjs from 'dayjs';

import Cookies from 'js-cookie';

function Header({burgerActive, handleDrawerToggle}) {
  const [firstDermatoscop, setFirstDermatoscop] = useState(null);
  const {globalState} = useContext(Context);
  const [saInfo, setSaInfo] = useState(null);
  const user = window.glob("user");
  
  useEffect(() => {
    socket.emit('bondage', {
      sa_clinicid: Cookies.get("sa_clinicid"),
      sa_userid: Cookies.get("sa_userid")
    });
    getFirstEnddateDermatoscope().then((resp) => setFirstDermatoscop(resp));
    // eslint-disable-next-line
  }, [saInfo]); 

  if (user.role_superadmin) {
    const fetchSaInfo = async (url, label) => {
      try {
        const resp = await axios.get(url);
        if (resp.data.result === "ok") {
          setSaInfo(`${label}: ${resp.data.data.name} || Id: ${resp.data.data._id}`);
        }
      } catch (error) {
        console.error(error);
      }
    }

    const id = Cookies.get("sa_clinicid") || Cookies.get("sa_userid");
    if (Cookies.get("sa_clinicid")) {
      fetchSaInfo(`/api/clinics/${id}`, "Клиника");
    } else if (Cookies.get("sa_userid")) {
      fetchSaInfo(`/api/users/${id}`, "Юзер");
    }
  }

  let exitSa = function (){
    Cookies.remove("sa_clinicid");
    Cookies.remove("sa_userid");
    document.location.reload()
    
  }


  return (

    <React.Fragment>
      <AppBar
        component="div"
        position="static"
        elevation={0}
        sx={{ zIndex: 0, py: 1, backgroundColor:window.grayColor,color:"black" }}
        className="appbar"
      >
        <Toolbar>
          <Stack direction="row" spacing={1} sx={{justifyContent:"flex-end", alignItems:"center", width:"100%"}}>
            {/* <Box sx={{marginRight:"auto", ...burgerStyles}} > */}
            {burgerActive && (
              <Box sx={{marginRight:"auto"}} >
                <IconButton
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            )}
            
            <Box sx={{display:"flex", justifyContent: "space-between", flex:1}}>
              {saInfo && <Box sx={{alignSelf: "center"}}>{saInfo} <Button onClick={exitSa}>exit</Button></Box>}

              <Stack sx={{flexDirection: "row", gap: 1, justifyContent: "flex-end", flex: 1}}>
                <FormControlLabel 
                  control={<Switch />} 
                  label={<T>show help short</T>}
                  onChange = {()=>{window.glob("user.show_help",!window.glob("user").show_help)}}
                  checked={window.glob("user").show_help||false}
                  sx={{display: {xs: "none", lg: "block"}}}
                />

                <ChipsMoney user={user} expertbalance={globalState?.expertbalance} aisbalance={globalState?.aisbalance}/>
                <ChipsBalance so={globalState.balance?.so} ai={globalState.balance?.ai} firstDermatoscop={firstDermatoscop} />

                {/* {!window.globalState.isMobileDevice && (
                  <>
                  <Chip color="primary" className={scss.mychip} icon={<SvgIcon><IconSo /></SvgIcon>} variant="outlined" label={<T>{globalState.balance?.so || "0"}</T>} />
                  <Chip color="primary" className={scss.mychip} icon={<SvgIcon><IconAi /></SvgIcon>} variant="outlined" label={<T>{globalState.balance?.ai || "0"}</T>} />
                  {firstDermatoscop && <Chip sx={{}} color="primary" className={scss.mychip} icon={<SvgIcon><IconAlarm /></SvgIcon>} variant="outlined" label={dayjs(firstDermatoscop).format("DD.MM.YYYY")} />}
                  <Divider orientation="vertical" flexItem sx={{mx: 0}}/>
                  </>
                )} */}

                <RouterLink to="/profile">
                  <Tooltip title={<T>profile</T>}>
                    <Chip color="primary" className={scss.mychip} icon={<SvgIcon><IconProfile /></SvgIcon>} variant="outlined" label={globalState.user?.name.split(" ").map(w=> {
                      if (w[0]) return w[0]+"."
                      else return "" 
                    }).join("")}/>
                  </Tooltip>
                </RouterLink>
              </Stack>
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>
      
    </React.Fragment>
  );
}

function ChipsMoney({user, expertbalance, aisbalance}) {
  return (
    <>
    {(user.role_expert || user.role_superadmin) && <Chip color="primary" className={scss.mychip} icon={<SvgIcon ><IconRub /></SvgIcon>} variant="outlined"  label={<>{formatNumber(expertbalance?.rub || 0)}₽</>} />}
    {(user.role_ai || user.role_superadmin) && <Chip color="primary" className={scss.mychip} icon={<SvgIcon ><IconRub /></SvgIcon>} variant="outlined"  label={<>{formatNumber(aisbalance?.rub || 0)}₽</>} />}
    </>
  );
}

function ChipsBalance({so, ai, firstDermatoscop}) {
  if (window.globalState.isMobileDevice) return null;
  console.log(window.features);
  
  return (
    <>
      {window?.features?.so && 
      <Tooltip title={<T>count available so</T>}>
        <Chip color="primary" className={scss.mychip} icon={<SvgIcon><IconSo /></SvgIcon>} variant="outlined" label={<T>{so || "0"}</T>} />
      </Tooltip>
      }
      {window?.features?.ai &&
      <Tooltip title={<T>count available ai</T>}>
        <Chip color="primary" className={scss.mychip} icon={<SvgIcon><IconAi /></SvgIcon>} variant="outlined" label={<T>{ai || "0"}</T>} />
      </Tooltip>
      }
      {firstDermatoscop && <Tooltip title={<T>end date tarif</T>}><Chip sx={{}} color="primary" className={scss.mychip} icon={<SvgIcon><IconAlarm /></SvgIcon>} variant="outlined" label={dayjs(firstDermatoscop).format("DD.MM.YYYY")} /></Tooltip>}
      <Divider orientation="vertical" flexItem sx={{mx: 0}}/>
    </>
  );
}

export default Header;