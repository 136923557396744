import React from 'react'
import { axios, T } from "../Globals.js"
import ItemTemplate from "../components/ItemTemplate"
import CameraCapture from '../components/CameraCapture.js';
import { Button, Box, Stepper, Step, StepLabel, StepContent, Stack } from '@mui/material';
import DiagnosisSelector from '../components/DiagnosisSelector.js';
import LinkField from '../components/LinkField.js';
import { RequestItem } from './RequestItem.js';
import { CircularProgress } from '@mui/material';

export default function AiRequest(props) {
  let [data, setData] = React.useState({});
  

  return (
    <ItemTemplate {...props} title={<T>Ai снимок</T>} data={data} setData={setData} buttons={[]}>
      <AiRequestContent />
    </ItemTemplate>
  )
}

function AiRequestContent({data, setData}) {

  const [saveImage, setSaveImage] = React.useState(null);
  const [aiLoading, setAiLoading] = React.useState(false);

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  }

  const sendRequest = (variant) => {
    setAiLoading(true);
    axios.post("/api/ai", {
      imagefile: data.image,
      variant: variant
    })
    .then(function(response) {
      setAiLoading(false);
      if (response.data.success) {
        const obj = { 
          ...response.data,
          image: data.image
        };

                
        // if (obj.confidence <= 0.2) {
        //   obj.diagnosis = "safely";
        // } else if (obj.confidence <= 0.49) {
        //   obj.diagnosis = "observation";
        // } else if (obj.confidence <= 1) {
        //   obj.diagnosis = "oncologist";
        // }

        localStorage.setItem('AiImage', obj.image);
        localStorage.setItem('AiDiagnosis', obj.outclass_name);
        localStorage.setItem('AiConfidence', obj.confidence);
        
        setData({...obj, diagnosis: obj.outclass_name});
        setSaveImage(data.image);
        return

      } else {
        console.log("Ошибочка");
      }
      
    })
    .catch(function(error) {
      console.log(error)
      setAiLoading(false);
    });

  }

  const steps = [
    {
      label: 'Создание снимка',
      content: 
        <Stack sx={{mt: 3}}>
          <CameraCapture data={data} setData={setData}/>
          {aiLoading &&  
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:20}}>
              <CircularProgress />
            </div>
          }
          {!aiLoading && data?.image && data?.image !== saveImage && (
            <div className=' flex mt-3 justify-center items-center gap-3 flex-col md:flex-row'>
              <Button sx={{p:2, maxWidth: "fit-content"}}  color="primary" onClick={() => sendRequest("v1")}>Отправить снимок на оценку Ai v1</Button>
              <Button sx={{p:2, maxWidth: "fit-content"}}  color="secondary" onClick={() => sendRequest("v2")}>Отправить снимок на оценку Ai v2</Button>
            </div>
            )}
          <center>
            <DiagnosisSelector data={data}/>
          </center>
        </Stack>,
    },
    {
      label: 'Выбор пациента',
      content: <LinkField table="pacients" name="pacientid" data={data} label={<T>pacients</T> } onChange={handleChange}  enableCreate={true} />
    },
    {
      label: 'Отправка запроса второго мнения',
      content: <RequestItem defaults={data} setAiRequestData={setData}/>,
    },
  ];

  return (<Stack sx={{ mt:4}}>
    {/* <DiagnosisSelector data={data}/> */}

    <VerticalLinearStepper steps={steps} data={data}/>
  </Stack>)
}



export function VerticalLinearStepper({steps, data}) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const nextDisable = (index) => {
    switch (index) {
      case 0:
        return !!!data?.outclass_name  
      case 1:
        return !!!data?.pacientid  
      default:
        return false;
    }
  }

  return (
    <Box sx={{ maxWidth: "auto" }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              {step.content}
              <Box sx={{ mb: 2 }}>
                <div>
                  {index !== 0 &&
                    <Button
                      variant="outlined"
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      <T>back</T>
                    </Button>    
                  }
                  {index !== steps.length - 1 &&
                      <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                      disabled={nextDisable(index)}
                    >
                      <T>continue</T>
                    </Button>
                  }
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

