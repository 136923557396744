const tr = {
    "login": {"ru":"Вход","en":"Sign In"},
    "logout": {"ru":"Выход из аккаунта","en":"Sign Out"},
    "register": {"ru":"Регистрация","en":"Sign Up"},
    "already register": {"ru":"Я уже зарегистрирован, войти"},
    "forgott password": {"ru":"Восстановление пароля"},
    "forgott password code":{"ru":"Проверочный код"},
    "forgott password finish": {"ru":"Новый пароль сохранён"},
    "input code from email":{"ru":"Введите проверочный код, который пришёл на email"},
    "create new password": {"ru":"Придуймайте новый пароль"},
    "email not found": {"ru":"Пользователь с такием email не найден"},
    "Invalid email or password": {"ru":"Неверный Email или пароль"},
    "Invalid name": {"ru":"Имя должно содержать строковое значение"},
    "Password must be at least 6 characters long": {"ru":"Пароль должен содержать не менее 6 символов"},
    "Email already exists": {"ru":"Такой Email уже существует"},
    "successful registration": {"ru":"Вы успешно зарегистрированы"},
    "you are blocked": {"ru":"Ваш аккаунт заблокирован"},
    "no access this collections": {"ru":"Нет прав доступа"},
    "blocked cause": {"ru":"Причина блокировки"},
    "data processing error": {"ru":"Возникла ошибка при обработке данных"},
    "data processing error verificationcode": {"ru":"Возникла ошибка при отправке кода подтверждения на почту"},
    "next verificationcode 1": {"ru":"После подтверждения, вам на почту придёт сообщение с кодом для прохождения дальнейшего этапа регистрации"},
    "next verificationcode 2": {"ru":"Введите проверочный код из сообщения, отправленного на почту"},
    "code doesnt match": {"ru":"Код не совпадает"},
    
    "search by": {"ru":"Искать по"},

    "profile": {"ru":"Личный кабинет"},
    "open item": {"ru":"Открыть"},
    "create item": {"ru":"Создать"},
    "more": {"ru":"Подробнее"},
    "continue": {"ru":"Продолжить"},
    "back": {"ru":"Назад"},
    "password": {"ru":"Пароль"},
    "confirm password": {"ru":"Подтвердите пароль"},
    "current password": {"ru":"Текущий пароль"},
    "new password": {"ru":"Новый пароль"},
    "surname" :{"ru":"Фамилия"},
    "name": {"ru":"Имя"},
    "legal name": {"ru":"Название юрлица (например: Самозанятый гражданин Иванов Иван Иванович)"},
    "dogovor": {"ru":"Основание для выплат (например: Договор №111)"},
    "patronymic" :{"ru":"Отчество"},
    "show help":{"ru":"Показывать подсказки"},
    "show help short":{"ru":"Подсказки"},
    "Wrong current password": {"ru":"Неверный текущий пароль"},
    "Email exists": {"ru":"Пользователь с таким Email уже существует"},
    "Invalid email format": {"ru":"Некорректный формат Email"},
    "Passwords do not match": {"ru":"Пароли не совпадают"},
    "change password": {"ru":"Сменить пароль"},
    "All password change fields are required": {"ru":"При смене пароля все поля обязательны для заполнения"},

    "email notify newresponse":{"ru":"Уведомления на почту о новых ответах"},
    "push enable": {"ru":"Push уведомления"},
    "push subscribed": {"ru":"Push подключены на это устрйоство"},
    "push test": {"ru":"Push тест"},
    "push help": {"ru":"Если Push-уведомления не отображаются, возможно они отключены в настройках операционной системы. На windows проверьте раздел 'Управление уведомлениями' в правом нижнем углу экрана, а так же отключите настройку 'фокусировка внимания'. На MacOs проверьте в настройках - уведомления - режим \"не беспокоить\". Для включения уведомлений на iPhone необходимо установить приложение 'На экран «Домой»' и обновить iOS до версии 16.4 или выше. Если переключатель PUSH не активен, ваш браузер не поддерживает PUSH уведомления"},
    "enable permissions in browser instruction": {"ru":"Разрешите отправку PUSH-уведомлений в настройках браузера (значёк 'Замок' в адресной строке браузера)"},
    "your photo": {"ru":"фото профиля"},
    "default dermatoscope" :{"ru":"Дерматоскоп по-умолчанию"},
    "add dermatoscopes" :{"ru":"Добавить оборудование"},
    "register as" :{"ru":"Я регистрируюсь как"},
    "as doctor": {"ru":"Врач"},
    "as clinic": {"ru":"Представитель клиники"},

    "not enougt money" :{"ru":"Недостаточно средств"},
    

    "doctor": {"ru":"Врач"},
    "administrator": {"ru":"Администратор"},
    "role payment": {"ru":"Оплата / финансы"},
    "expert": {"ru":"Эксперт"},
    "superadmin": {"ru":"Суперадмин"},

    "attention": {"ru":"Внимание"},
    "equipment": {"ru":"Оборудование"},
    "equipment help": {"ru":"Список цифровых дерматоскопов у Вас или в Вашей клинике. Параметры дерматоскопа (масштаб/кратность) будут использоваться при оценке новообразований"},

    "mainpage": {"ru":"Главная страница"},
    "available": {"ru":`Количество доступных
оценок новообразований`},
    "experts": {"ru":"Наши эксперты"},
    "service description": {"ru":"Сервис Меланоменет - это сервис, предоставляющий услуги по оценке новообразований (опухолей) с помощью экспертов второго мнения и искусственного интеллекта. Когда клиент загружает данные об определенном новообразовании, сервис Меланоменет запускает процесс оценки, который включает в себя две составляющие: экспертное мнение и использование искусственного интеллекта."},
    "main page description": {"ru":"Равным образом новая модель организационной деятельности влечет за собой процесс внедрения и модернизации соответствующий условий активизации. Разнообразный и богатый опыт реализация намеченных плановых заданий играет важную роль в формировании систем массового участия. Повседневная практика показывает, что рамки и место обучения кадров в значительной степени обуславливает создание соответствующий условий активизации. Товарищи! сложившаяся структура организации позволяет выполнять важные задания по разработке форм развития."},
    "count help ai": {"ru":"при помощи Ai"},
    "count help so": {"ru":"специалистом второго мнения"},
    "card ai": {"ru":"Оценка Ai"},
    "card so": {"ru":"Второе  мнение специалиста"},
    "card ai descr": {"ru":"Оценки новообразоваий на основе сверхточной нейросети. Нейросеть поможет в принятии решения о наличии риска злокачественных меланоцитарных и немелоцитарных новообразований. Эффективность (более 95%) в клинических испытаниях."}, 
    "card so descr": {"ru":"Отправьте снимки новообразований и ваше заключение напрямую команде экспертов и получите их ответ в течение 48 часов. Отчет автоматически возвращается в вашу учетную запись и будет загружен в виде PDF-документа."}, 


    "main title": {"ru":"МЕЛАНОМЕ НЕТ"},
    "menu mainpage": {"ru":"Главная страница"},
    "menu contacts": {"ru":"Контакты"},
    "menu login": {"ru":"Вход"},
    "menu register": {"ru":"Регистрация"},
    "menu doctor": {"ru":"Функции врача"},
    "menu so": {"ru":"Сервис Второго мнения"},
    "menu myequipment": {"ru":"Мои устройства"},
    "hand calibration": {"ru":"Ручная калибровка"},

    "pacients": {"ru":"Пациенты"},
    "doctor requests": {"ru":"Мои запросы"},
    "requests descr": {"ru":"На данной странице отображаются результаты всех сделанных запросов. Запросы с непрочитанными ответами выделяются жирным шрифтом"},
    "contacts": {"ru":"Контакты"},


    "requests": {"ru":"Запросы"},
    "create request": {"ru":"Создать запрос"},
    "create recuest snapshot": {"ru":"Создать снимок"},
    "picture ai": {"ru":"Снимок Ai"},
    "result ai": {"ru":"Результат обследования искусственным интеллектом"},
    //"request item": {"ru":"Создать запрос"},
    "request item": {"ru":"Создать снимок"},
    "request form": {"ru":"Форма запроса"},
    "idNo":{"ru":"№"},
    "request dermatoscope":{"ru":"Используемый дерматоскоп"},
    "doctor's comment":{"ru":"Комментарий доктора"},
    "count news requests":{"ru":"новых запросов"},

    "operator ai":{"ru":"Оператор Ai"},

    "request dermatoscope help":{"ru":"Необходимо выбрать цифровой дерматоскоп, на который была сделана фотография. Выберите один из стандартных дерматоскопов или создайте свой. Ваш дерматоскоп по-умолчанию можно задать в настройках профиля."},
    

    "description": {"ru":"Описание"},
    "pacient select": {"ru":"Выбор пациента"},
    "pacient": {"ru":"Пациент"},
    "open pacient": {"ru":"Открыть"},
    "create pacient": {"ru":"Создать пациента"},
    "create new pacient": {"ru":"Создать нового пациента"},
    "pacient item": {"ru":"Пациент"},
    "pacient name": {"ru":"Имя"},
    "pacient card": {"ru":"Номер амбулаторной карты"},
    "birth": {"ru":"Дата рождения"},
    "age": {"ru":"Возраст"},
    "sex": {"ru":"Пол"},
    "male": {"ru":"Мужчина"},
    "female": {"ru":"Женщина"},
    "fio": {"ru":"Ф. И. О."},
    "phone": {"ru":"Телефон"},
    "table fio": {"ru":"Фамилия И. О."},
    "sub_pacients": {"ru":`На данной странице отображается список ваших Пациентов и данные по сделанным запросам.
Для создания нового запроса на диагностику новообразования выберите Пациента или создайте нового Пациента`},

    
    "academic...": {"ru":"Ученая степень | Категория | Ученое звание"},
    "specialization...": {"ru":"Специализация | Должность"},
    "experience...": {"ru":"Стаж работы"},

    "phototype": {"ru":"Фототип по шкале Фицпатрика"},
    "phototype 1": {"ru":"I - кельтский"},
    "phototype 2": {"ru":"II - нордический"},
    "phototype 3": {"ru":"III - европейский"},
    "phototype 4": {"ru":"IV - средиземноморский"},
    "phototype 5": {"ru":"V - индонезийский"},
    "phototype 6": {"ru":"VI - африканский"},


    "data pacient": {"ru":"Данные пациента"},
    "family anamnez": {"ru":"Семейный анамнез"},

    "request type not selected": {"ru":"Не выбран тип запроса ('оценка Ai' или 'Второе мнение')"},

    "ai diagnostic": {"ru":"запрос Ai"},
    "request descr": {"ru":`Для получения оценки экспертом «Второе мнение» необходимо загрузить снимок новообразования или сделать его при помощи дерматоскопа, подключенного к вашему смартфону`},
    "request descr2": {"ru":`Для проведения оценки новообразования экспертом 'Второе Мнение' необходимо ответить на несколько вопросов. В поле «Комментарии врача»  просьба указать дополнительную информацию, которую нужно знать эксперту для принятия решения.`},
    "requirements photo": {"ru":`Размер не менее 1000x1000 px | Наличие калибровочной шкалы`},
    // "requirements photo": {"ru":`Размер не менее 1000x1000 px | формат JPG/PNG/GIF/HEIF | Наличие калибровочной шкалы`},
    "requirements photo title": {"ru":`Требования к снимку`},
    "recommend photo": {"ru":`Во время съёмки выставить кратность от х2 до х3. Используйте поляризованную подсветку.`},
    "recommend photo title": {"ru":`Рекомендации для съёмки`},
    "load img screen": {"ru":`Фотография новообразования`},
    "second opinion": {"ru":"запрос второго мненеия"},
    "opinion count": {"ru":"Количество запросов на сервис \"Второе мнение\""},
    "when appear": {"ru":"В каком году появилось образование"},
    "causes occurrence": {"ru":"Причина появления"},
    "trauma": {"ru":"травма"},
    "sun exposure": {"ru":"воздействие солнца"},
    "heredity": {"ru":"наследственность"},
    "no reason": {"ru":"без причин"},
    "neoplasm changed": {"ru":"Изменялось ли новообразование"},
    "growing fast": {"ru":"быстро растет"},
    "size changed": {"ru":"изменился размер"},
    "form changed": {"ru":"изменилась форма"},
    "color changed": {"ru":"изменился цвет"},
    "education treatment": {"ru":"Было ли лечение образования"},
    "request yes": {"ru":"да лечили"},
    "no": {"ru":"нет"},
    "yes": {"ru":"да"},
    "subjective feelings": {"ru":"Субъективные ощущения"},
    "soreness": {"ru":"болезненность"},
    "itching": {"ru":"зуд"},
    "burning sensation": {"ru":"жжение"},
    "bleeding": {"ru":"кровоточивость"},
    "not bother": {"ru":"не беспокоит"},
    "number formations": {"ru":"Количество образований"},
    "single": {"ru":"единичное"},
    "min 50": {"ru":"меньше 50"},
    "max 50": {"ru":"больше 50"},
    "many formations": {"ru":"множество образований"},
    "doctor comment": {"ru":"Комментарий врача"},
    "humanselector location": {"ru":"Укажите расположение новообразования на теле"},
    "humanselector location response": {"ru":"Расположение новообразования"},
    "humanselector front": {"ru":"фронтальный"},
    "humanselector back": {"ru":"задний"},
    "additional information": {"ru":"дополнительная информация "},
    "readonly request so": {"ru":"Анкета пациента для запроса “Второе мнение”"},
    "time reached zero":{"ru":"Время истекло"},
    "time left":{"ru":"Время на ответ"},
    "make photo":{"ru":"Загрузите снимок новообразования или выберите камеру и сделайте снимок"},
    "request options" : {"ru":"Выберите количество запросов"},
    "image": {"ru":"Картинка"},
    "or": {"ru":"или"},
    "upload image": {"ru":"Из галереи"},
    "galery": {"ru":"Галерея"},
    "camera": {"ru":"камера"},
    "camera list": {"ru":"Список камер"},
    "camera permission error": {"ru":"Вы запретили доступ к камере, пожалуйста, разрешите его, нажав на кнопку 'замок' в адресной строке браузера и перезагрузите страницу"},
    "camera read error": {"ru":"Выбранная камера не работает. Выберите другую камеру"},
    "camera not found": {"ru":"Камера не найдена"},
    "selected camera": {"ru":"камера выбрана"},
    "start samera": {"ru":"Снимок"},
    "preview": {"ru":"предпросмотр"},
    "create snapshot": {"ru":"снимок"},
    "select divice": {"ru":"выберите камеру"},
//    "failed start camera": {"ru":"Не удалось запустить камеру. Проверьте её подключение и разрешение доступа к камере в браузере, затем перезагрузите страницу"},
//    "failed allowed camera": {"ru":"Доступ к камере запрещен. Проверьте разрешение доступа к камере в браузере, затем перезагрузите страницу"},
    "failed picture": {"ru":"Не удалось сделать снимок"},
    "pacient gallery help": {"ru":"Список запросов 'Второе мнение' пациента. Одно фото = один запрос. Запрос может быть обработан одним, двумя или тремя специалистами второго мнения. Непрочитанные ответы выделяются жирным."},
    "capture variant upload" :{"ru":"Загрузить из галереи"},
    //"capture variant upload" :{"ru":"Загрузить из галереи / сделать фото"},
    "capture variant photo" :{"ru":"Сделать снимок"},
    "required":{"ru":"Поле обязательно для заполнения"},
    "required error alert":{"ru":"Ошибка! Все поля обязательны к заполнению"},
    "error image px":{"ru":"Пожалуйста, выберите изображение размером не менее 1000x1000 пикселей"},
    "jpeg or jpg":{"ru":"Пожалуйста, выберите файл с расширением .jpeg или .jpg"},

    "linkfieldgroup my":{"ru":"Мои дерматоскопы"},
    "linkfieldgroup standard":{"ru":"Стандартные модели дерматоскопов"},
    "linkfieldgroup none":{"ru":" "},
    
    "error trial active":{"ru":"Возникла ошибка при активации промоверсии"},


    "menu administrator": {"ru":"Функции администратора"},
    "administrator clinic": {"ru":"Настройки клиники"},
    "administrator doctors": {"ru":"Сотрудники"},
    "administrator fotofinders": {"ru":"Дерматоскопы"},
    "clinic": {"ru":"Клиника"},
    "create doctor": {"ru":"Добавить врача"},
    "doctors see all": {"ru":"Врачи видят всех пациентов клиники"},
    "doctors see only own": {"ru":"Врачи видят только своих пациентов"},
    "clinic name": {"ru":"Название клиники"},
    "doctor item": {"ru":"Сотрудник"},
    

    "download": {"ru":"Скачать"},
    "upload": {"ru":"Загрузить на сервер"},
    "roles": {"ru":"Роли пользователя"},


    "menu payment": {"ru":"Оплата"},
    "detalization": {"ru":"Детализация расходов"},
    "detalization help": {"ru":"На этой странице отображается список всех расходов и пополнений счёта. "},
    "payments": {"ru":"История оплаты"},
    "make payment": {"ru":"Пополнить баланс"},
    "transaction item": {"ru":"Транзакция"},
    "amount rub": {"ru":"Сумма в рублях"},
    "amount bonus": {"ru":"Сумма в бонусах"},
    "balance so": {"ru":"Второе мнение"},
    "balance ai": {"ru":"Запросов Ai"},
    "balance expert": {"ru":"эксперту"},
    "balance ais": {"ru":"менеджеру Ai"},
    "sum payment": {"ru":"Сумма к выплате"},
    "transactions": {"ru":"Транзакции"},
    "transactions item": {"ru":"Транзакция"},
    "statistics": {"ru":"Статистика"},
    "statistic transactions": {"ru":"Статистика транзакций"},
    "statistic expertpayments": {"ru":"Статистика выплат эксперту второго мнения"},
    "statistic aispayments": {"ru":"Статистика выплат оператору Ai"},
    "wait confirmation payment": {"ru":"Ожидайте подтверждение оплаты, в течение двух рабочих дней вам на почту будет отправлена инструкция по установке программного обеспечения."},
    "wait confirmation payment 2": {"ru":"Мы получили ваш запрос. Инструкция по подключению будет выслана на email, указанный при регистрации."},
    "questions contacts": {"ru":"В случае возникновения вопросов обращайтесть"},
    "payment successful": {"ru":"Для активации доступа к сервису «Меланоме НЕТ» в ближайшее время с вами свяжется специалист службы технической поддержки."},
    "contact yourself": {"ru":"Самостоятельно связаться со службой техподдержки сервиса возможно в рабочие часы с 10.00 до 18.00 в будние дни."},
    "expert act": {"ru":"Акты"},
    "expert act help": {"ru":"Здесь генерируются ваши акты выполненных работ. По окончании месяца будет доступен акт за прошедший месяц. Вам необходимо подписать его и отправить нам по ЭДО, затем вам будет осуществлена выплата на расчётный счёт"},
    "deposit card" :{"ru":"Поступления Ф.Л."},
    "deposit bank" :{"ru":"Поступления Юр.Л."},
    "spending card" :{"ru":"Реализации Ф.Л."},
    "spending bank" :{"ru":"Реализации Юр.Л."},
    "doctor rejected answer" :{"ru":"Врач отклонил ответ"},
    "note" :{"ru":"Примечание"},
    "isactive": {"ru":"активен"},
    "connecting": {"ru":"подключение..."},
    "ispaid": {"ru":"оплачен"},
    "isnotpaid": {"ru":"не оплачен"},
    "timeisup": {"ru":"срок истек"},
    
    "paid success": {"ru":"Оплата совершена успешно"},
    "paid pending": {"ru":"Ожидание поступления денег на счёт"},
    
    "org_name":{"ru":"Название юридического лица"},
    "org_inn":{"ru":"ИНН"},
    "org_kpp":{"ru":"КПП"},
    "org_bik":{"ru":"БИК"},
    "org_bank":{"ru":"Название банка"},
    "org_bankaccount":{"ru":"Номер счёта"},
    "org_address":{"ru":"Юридический адрес"},

    "billNo":{"ru":"Счёт на оплату №"},
    

    "menu expert opinion": {"ru":"Функции эксперта"},
    "expert show on main":{"ru":"Показывать эксперта на главной странице"},
    "expert responses": {"ru":"Ответы на запросы"},
    "expert responses help": {"ru":"Здесь отображаются запросы 'Второе Мнение', на которые вы уже ответили. В системе есть очередь запросов, которые обрабатываются экспертами Второго Мнения. Нажав на кнопку 'Взять следующий запрос' у вас будет 30 минут на ответ. Если в течение 30 минут вы не отправили ответ, запрос автоматически закрывается и помещается обратно в очередь"},
    "responses": {"ru":"Второе мнение"},
    "response item": {"ru":"Запрос второго мнения"},
    "responses item help": {"ru":"Далее находится форма для вашего экспертного мнения. Отвечайте максимально подробно. У вас есть 30 минут на ответ, если не успеете, запрос автоматически закроется и все введённые данные пропадут. Если изображение не достаточно чёткое или данные анкеты не позволяют провести оценку, нажмите кнопку 'отклонить', напишите причину отклонения. Выплаты за отклонённые запросы не производятся, а деньги возвращаются в клинику, откуда поступил запрос."},
    "expert payments": {"ru":"Выплаты"},
    "expert payments help": {"ru":"Здесь отображаются суммы, заработанные на ответах 'Второе мнение'. Зелёным цветом отмечены суммы за отвеченные запросы, красным - выплаты на ваш банковский счёт. В шапке сайта отображается долг сервиса перед вами (сумма дохода минус сумма выплат). Выплаты осуществляются по итогам месяца после подписания актов. Ваши акты генерируются автоматически в соответствующем разделе."},
    "expert payments item": {"ru":"Выплата эксперту"},
    "expert payments item help": {"ru":""},
    "take next": {"ru":"Взять следующий запрос"},
    "hazard": {"ru":"риск"},
    "malignancy risk assessment": {"ru":"Оценка риска малигнизации новообразования"},
    "report expert" : {"ru":`Отчет "Второе мнение"`},
    "report ai" : {"ru":`Отчет Ai`},
    "report ai descr": {"ru":`Отчет полученный с помощью Искусственного Интеллекта (Ai) о степени опасности новообразования кожи по результатам анализа цифрового изображения`},
    
    "menu ai reports": {"ru":"Функции менеджера по Ai"},
    "ais": {"ru":"Запросы на Ai"},
    "ai payments": {"ru":"Выплаты"},
    
    "menu accountant": {"ru":"Функции бухгалтера"},
    "bills": {"ru":"Счета"},
    "accountant": {"ru":"Бухгалтерские счета"},
    "accountant item": {"ru":"Подробности счёта"},
    "months": {"ru":"месяцы"},
    "weeks": {"ru":"недели"},
    "days": {"ru":"дни"},
    "period year": {"ru":"период год"},
    "period month": {"ru":"период месяц"},
    "rubminus": {"ru":"расходы"},
    "rubplus": {"ru":"пополнения"},
    "onetime tariffs": {"ru":"Тарифы"},
    "tariffs": {"ru":"Второе мнение"},
    "tariffs AI": {"ru":"Искусственный интеллект"},
    "tariffs pay ai": {"ru":"Оплата сервиса «Второе мнение»"},
    "unlimited tariffs": {"ru":"Оплата безлимитного доступа к оценке новообразований при помощи Ai"},
    "onetime tariffs descr": {"ru":`Выберите необходимое количество запросов к экспертам сервиса «Второе мнение»`},
    "not NDC": {"ru":`НДС не облагается`},
    "transactions table": {"ru":`Таблица транзакций`},
    
    
    "accountant expert payments":{"ru":"Выплаты экспертам"},
    "expert statistic":{"ru":"Статистика заработка экспертами"},
    "count requests": {"ru":`Количество запросов`},


    "save": {"ru":"Сохранить"},
    "create": {"ru":"Создать"},
    "close": {"ru":"Закрыть"},
    "draft": {"ru":"Черновик"},
    "send": {"ru":"Отправить"},
    "cancel": {"ru":"Отмена"},
    "ok": {"ru":"ОК"},
    "delete": {"ru":"Удалить"},
    "apply": {"ru":"Применить"},
    "reject": {"ru":"Отклонить"},
    "print": {"ru":"Печать"},
    "topdf": {"ru":"Скачать PDF"},

    "delete?":{"ru":"Вы уверены, что хотите удалить?"},
    "can not delete":{"ru":"Не возможно удалить, так как у этой записи есть активные взаимосвязи"},

    "status": {"ru":"Статус"},
    "status sent": {"ru":"Отправлено"},
    "status answered": {"ru":"Отвечено"},
    "status taken": {"ru":"Взят"},
    "status draft": {"ru":"Черновик"},
    "status new": {"ru":"В обработке"},
    "status reject": {"ru":"Отклонено"},
    "status answer": {"ru":"Статус ответа"},

    "diagnosis safely": {"ru":"Риск < 20%"},
    "diagnosis observation": {"ru":"Риск < 50%"},
    "diagnosis oncologist": {"ru":"Риск > 50%"},

    "probability": {"ru":"вероятность"},

    "ai_diagnos_malignant": {"ru":"злокачественное новообразование"},
    "ai_diagnos_benign": {"ru":"доброкачественное новообразование"},

    "probability malignant": {"ru":"вероятность злокачественного новообразования"},

    "answer grade" : {"ru":"Оценка эксперта"},
    "answer differential diagnosis" : {"ru":"Дифференцировать с"},
    "answer recommendations" : {"ru":"Рекомендации для врача"},
    "answer answer" : {"ru":"Комментарий"},
    "answer confirm" : {"ru":"Подтвердите правильность введённых данных"},
    "response reject" : {"ru":"Напишите причину отклонения запроса"},

    "count available so": {"ru":`Количество доступных оценок "Второе мнение"`},
    "count available ai": {"ru":`Количество доступных оценок AI`},

    "transactions create socount": {"ru":`Количество запросов "Второе мнение"`},
    "transactions create aicount": {"ru":`Запросы "Ai"`},
    "pay": {"ru":"Оплатить"},
    "not selected": {"ru":"Не выбран"},
    "payment method card": {"ru":"Банковская карта"},
    "payment method bank": {"ru":"По счёту"},
    "payment method gift": {"ru":"Подарок"},
    "payment method trial": {"ru":"Промоверсия"},
    "download bill": {"ru":"Скачать счёт"},
    "create an account": {"ru":"Сформировать счёт"},
    "not auto renewal": {"ru":"Автоматическое продление подписки по тарифу не предусмотрено."},
    
   
    "unlimitedFF": {"ru":"Безлимит"},
    "unlimitedFF description": {"ru":"Безлимитный доступ дает возможность производить неограниченное число оценок дерматоскопических изображений новообразований при помощи Искусственного Интеллекта (Ai) в течение выбранного периода."},
    "unlimitedFF description 2": {"ru":"Данный сервис доступен только для владельцев цифровых дерматоскопов handyscope/Dermlite и мобильных устройств Apple."},
    "unlimitedFF description 3": {"ru":"В течении двух рабочих дней на указанную при регистрации почту вам придет письмо с данными для активации ПО."},
    "unlimitedFF description 4": {"ru":`Промоверсия доступна только один раз.`},
    "select tariff": {"ru":"Выберите тариф"},
    "selected tariff": {"ru":"Срок действия тарифа"},
    "order": {"ru":"Оплата"},
    "use": {"ru":"Использовать"},
    "trial": {"ru":"Промоверсия"},
    "trial version 20": {"ru":`Промоверсия 20 дней`},
    "trial version 30": {"ru":`Промоверсия 30 дней`},
    "quartal": {"ru":"Квартал"},
    "90 days": {"ru":"90 дней"},
    "30 days": {"ru":"30 дней"},
    "20 days": {"ru":"20 дней"},
    "year": {"ru":"Год"},
    "month": {"ru":"Месяц"},
    "week": {"ru":"Неделя"},
    "day": {"ru":"День"},
    "itogo": {"ru":"Итого"},
    "invalid payment method": {"ru":"Не выбран способ оплаты"},
    "invalid payment rekvizits": {"ru":"Не заполнены реквизиты юр.лица в меню \"Настройки клиники\""},
    "transaction method": {"ru":"Способ оплаты"},
    "transaction type": {"ru":"Тип"},
    "transaction status": {"ru":"Статус оплаты"},
    "transaction status pending": {"ru":"Ожидание зачисления на счёт"},
    "transaction status completed": {"ru":"Исполнено"},
    "transaction status failed": {"ru":"Произошла ошибка"},
    "transaction type deposit":{"ru":"Пополнение счёта"},
    "transaction type spending":{"ru":"Расход"},
    "transaction type unlimitedFF":{"ru":"Покупка безлимитного доступа к оценке Ai"},
    "payment method": {"ru":"Способ оплаты"},
    "payment status": {"ru":"Статус оплаты"},
    "denomination": {"ru":"Наименование"},
    "count": {"ru":"Кол-во"},
    "price": {"ru":"Цена"},
    "sum": {"ru":"Сумма"},
    "more unlimitedFF link": {"ru":"Подробнее о безлимитных тарифах можно прочитать "},
    "here": {"ru":"здесь"},

    "unknown":{"ru":"Неизвестно"},
    "set access date":{"ru":"Установите дату начала доступа"},
    "dates not reset":{"ru":"Даты не сбросились"},
    "add quartal": {"ru":`Добавить Квартал`},
    "add year": {"ru":`Добавить Год`},
    "clear dates": {"ru":`Сбросить даты`},
    "date from": {"ru":`Дата начала доступа`},
    "end date to": {"ru":`Дата окончания доступа`},
    "end date": {"ru":"Дата окончания доступа"},
    "end date tarif": {"ru":"Дата окончания тарифа"},
    "start date": {"ru":"Дата начала доступа"},
    "ff name": {"ru":"Название дерматоскопа"},
    "ff name create": {"ru":"Придумайте название для дерматоскопа"},
    "ff name help": {"ru":"Придуймайте уникальное название для дерматоскопа, напишите владельца, особенности или иные атрибуты"},
    "serial": {"ru":"Серийный номер"},
    "where serial number": {"ru":"где находится серийный номер"},
    "ff bills": {"ru":"Счета на FotoFinder"},
    "ff apparats": {"ru":"Все дерматоскопы"},
    "ff login": {"ru":"Логин"},
    "ff password": {"ru":"Пароль"},
    "ff password generate": {"ru":"Сгенерировать"},
    "ff password generate confirm": {"ru":"Подтвердите автоматическую смену пароля"},
    "ff comment": {"ru":"Комментарий технического специалиста"},
    "ff comment private": {"ru":"Приватная заметка"},
    "ff download": {"ru":"Скачать программу на ПК"},
    "ff iphone download": {"ru":"Скачать программу на iPhone"},
    "ff download instruction": {"ru":"Инструкция по установке программы"},
    "ff access": {"ru":"Доступ к безлимитной оценке новообразований с помощью Ai"},
    "ff please create":{"ru":"Добавьте свой дерматоскоп в список оборудования"},
    "ff list manage":{"ru":"Управление списком устройств"},
    "ff serial help":{"ru":`Серийный номер необходим для привязки устройства к программе цифровой оценки новообразований (Ai).`},
    "ff license addr": {"ru":"Адрес сервера лицензий"},
    "ff sql addr": {"ru":"Адрес SQL сервера"},
    "ff sql database": {"ru":"База данных на SQL сервере"},
    "ff smb addr": {"ru":"Адрес файлового сервера"},
    "ff smb folder": {"ru":"Папка файлового сервера"},
    "ff port number": {"ru":"Номер порта"},
    "ff database": {"ru":"Имя базы данных"},
    "ff days": {"ru":"Текущий срок доступа"},
    "ff sql auth": {"ru":"Аутентификация"},
    "ff vpn address": {"ru":"Адрес VPN сервера"},
    "ff universewireless address": {"ru":"Адрес сервера"},
    "PC VPN Settings": {"ru":"Настройки VPN на Windows"},
    "PC Universe Settings": {"ru":"Настройки программы MelanomeNet на Windows"},
    "Iphone Wireless Settings": {"ru":"Настройки программы MelanomeNet Wireless на iPhone"},
    "ff wireless help": {"ru":"После того, как вы подключитесь, программа выдаст ошибку доступа. Вам необходимо связаться с нашей технической поддержкой для авторизации устройства на сервере."},
    "ff vpn help": {"ru":"В комплект установки программы MelanomeNet входит преднастроенный VPN-клиент для доступа к базе данных. Вам необходимо ввести следующие данные для авторизации на VPN"},
    "ff universe help": {"ru":"Для работы программы MelanomeNet для Windows необходимо запущенное VPN подключение"},
    
    "date": {"ru":"Дата"},
    "ff bill confirm": {"ru":"Подтверить ввод всех параметров"},
    "transaction confirm": {"ru":"Подтвердить получение денег на счёт"},
    "make payment ff": {"ru":"Оплата безлимитного тарифа"},
    "select fotofinders for payment": {"ru":"Для оплаты тарифа добавьте или выберите дерматоскоп, который будет использоваться для создания снимков"},
    "loading list of fotofinders": {"ru":"Загрузка списка ваших дерматоскопов"},
    "add fotofinders": {"ru":"Список аппаратов пуст, добавьте аппараты в меню \"Дерматоскопы\""},
    "payment pending": {"ru":"Ожидание зачисления"},
    "payment completed": {"ru":"ОК"},
    "payment failed": {"ru":"Оплата не удалась"},
    "payment refunded": {"ru":"Возврат средств"},
    "payments for": {"ru":"Оплаты для аппарата"},
    "ff bill status": {"ru":"Статус технического подключения"},
    "ff bill status pending": {"ru":"Ожидание"},
    "ff bill status completed": {"ru":"OK"},
    "ff bill status failed": {"ru":"Ошибка"},
    "ff bill status undefined": {"ru":"неизвестно"},
    "ffapparats item": {"ru":"Аппарат"},
    "dermatoscopes item": {"ru":"Дерматоскоп"},
    "dermatoscope model":{"ru":"Модель дерматоскопа"},
    "dermatoscope model other":{"ru":"Другой"},
    "dermatoscope model handyscope":{"ru":"Handyscope для ipod, iphone 6,7,8"},
    "dermatoscope model dermlitehandyscope":{"ru":"Handyscope Dermlite"},
    "dermatoscope model medicam":{"ru":"Medicam 800/1000"},
    "dermatoscope scale":{"ru":"Масштаб калибровочной линейки (размер одного деления)"},
    "dermatoscope pay":{"ru":"Перейти к форме оплаты программы для цифровой оценки новообразований (Ai)"},
    "dermatoscope othername" : {"ru":"Название и модель дерматоскопа"},
    "dermatoscope model undefined" : {"ru":"Не выбран"},
    "1mm":{"ru":"1 мм"},
    "0.5mm":{"ru":"0.5 мм"},
    "1/16in":{"ru":"1/16 дюйма"},
    "1/32in":{"ru":"1/32 дюйма"},
    
    "you dont finish last":{"ru":"Вы еще не ответили на предыдущий запрос"},
    "no more requests":{"ru":"Запросов больше нет, зайдите через некоторое время"},

    "doctor name": {"ru":"Имя врача"},
    "function doctor": {"ru":"функции врача"},
    "function administartor": {"ru":"функции администратора"},
    "function payment": {"ru":"функции оплаты"},
    "service so": {"ru":"Сервис Второго мнения"},
    "service ai": {"ru":"Сервис Ai"},


    "comment": {"ru":"Комментарии"},
    "amount so": {"ru":"Вторых мнений"},
    "amount ai": {"ru":"Запросов Ai"},
    
    "unlimitedFF period": {"ru":"Период доступа"},
    "selected fotofinders": {"ru":"Выбранные дерматоскопы"},

    "aboutme": {"ru":"О себе"},
    "direction activity": {"ru":"Направление деятельности"},

    "regalii": {"ru":"Специальзация / должность"},
    "short aboutme": {"ru":"Текст для главной страницы 10-20 слов"},
    "long aboutme": {"ru":"Длинный текст о себе"},
    "": {"ru":""},

    "January": {"ru":"Январь"},
    "February": {"ru":"Февраль"},
    "March": {"ru":"Март"},
    "April": {"ru":"Апрель"},
    "May": {"ru":"Май"},
    "June": {"ru":"Июнь"},
    "July": {"ru":"Июль"},
    "August": {"ru":"Август"},
    "September": {"ru":"Сентябрь"},
    "October": {"ru":"Октябрь"},
    "November": {"ru":"Ноябрь"},
    "December": {"ru":"Декабрь"},


    "humanselector neck": {"ru":"Шея"},
    "humanselector face": {"ru":"Лицо"},
    "humanselector shoulder right": {"ru":"Плечо правое"},
    "humanselector shoulder left": {"ru":"Плечо левое"},
    "humanselector armpits right": {"ru":"Подмышка справа"},
    "humanselector armpits left": {"ru":"Подмышка слева"},
    "humanselector neckline": {"ru":"Декольте"},
    "humanselector breast": {"ru":"Грудь"},
    "humanselector hip right": {"ru":"Бедро правое"},
    "humanselector hip left": {"ru":"Бедро левое"},
    "humanselector groin area": {"ru":"Паховая область"},
    "humanselector hair part head": {"ru":"Волосистая часть головы"},
    "humanselector foot right": {"ru":"Свод стопы правый"},
    "humanselector foot left": {"ru":"Свод стопы левый"},
    "humanselector shin right": {"ru":"Голень правая"},
    "humanselector shin left": {"ru":"Голень левая"},
    "humanselector stomach": {"ru":"Живот"},
    "humanselector forearm right": {"ru":"Предплечье правое"},
    "humanselector forearm left": {"ru":"Предплечье левое"},
    "humanselector palm right": {"ru":"Ладонь правая"},
    "humanselector palm left": {"ru":"Ладонь левая"},
    "humanselector null": {"ru":"Не выбрано"},
    /*************************************************** */
    "humanselector neck back": {"ru":"Шея задняя часть"},
    "humanselector back head": {"ru":"Затылок"},
    "humanselector shin lower right": {"ru":"Голень правая задняя часть"},
    "humanselector shin lower left": {"ru":"Голень левая задняя часть"},
    "humanselector back lower": {"ru":"Спина нижняя часть"},
    "humanselector back upper": {"ru":"Спина верхняя часть"},
    "humanselector hip back right": {"ru":"Бедро правое задняя часть"},
    "humanselector hip back left": {"ru":"Бедро левое задняя часть"},
    "humanselector buttocks": {"ru":"Ягодицы"},
    "humanselector shoulder inner right": {"ru":"Плечо правое задняя часть"},
    "humanselector shoulder inner left": {"ru":"Плечо левое задняя часть"},
    "humanselector forearm inner right": {"ru":"Предплечье правое задняя часть"},
    "humanselector forearm inner left": {"ru":"Предплечье левое задняя часть"},
    "humanselector foot inner left": {"ru":"Подошва левая"},
    "humanselector foot inner right": {"ru":"Подошва правая"},
    "humanselector wrist right": {"ru":"Кисть справа"},
    "humanselector wrist left": {"ru":"Кисть слева"},

    "error password simbols": {"ru":"Пароль должен содержать минимум 6 символов"},

    "about service": {"ru":`Сервис анализа цифровых
изображений с целью оценки
степени злокачественности
новообразований кожи`},

    "documents":{"ru":"Ознакомьтесь с документами:"},
    "policy": {"ru":"Политика в отношении обработки персональных данных"},
    "terms": {"ru":"Пользовательское соглашение"},
    "kpe": {"ru":"Согласие на обработку персональных данных"},
    "agreement": {"ru":"Согласие на получение информации и рекламных сообщений"},
    "confirm oferta": {"ru":"я согласен с пользовательским соглашением"},
    "confirm confidentiality": {"ru":"я согласен с политикой конфиденциальности"},
    "confirm policy": {"ru":"я согласен на обработку персональных данных"},
    "confirm spam": {"ru":"я согласен на получение информационных и рекламных материалов"},

    "suspicious": {"ru":"подозрительное"},
    "not suspicious": {"ru":"неподозрительное"},
    

    "rule_3": {"ru":"Правило 3-х признаков"},
    "rule_7": {"ru":"Правило 7-ми признаков"},
    "rule_ABCD": {"ru":"Правило ABCD"},
    
    "neoplasm": {"ru":"Новообразование"},
    "asymmetry": {"ru":"Асимметрия"},
    "atypical drawing": {"ru":"Атипичный рисунок"},
    "white blue structures": {"ru":"Бело-голубые структуры"},
    "atypical pigment pattern": {"ru":"Атипичный пигментный рисунок"},
    "blueGray areas blueWhite veil": {"ru":"Серо-голубые области / бело-голубовая вуаль"},
    "atypical vascular pattern": {"ru":"Атипичный сосудистый рисунок"},
    "radialRays(stripes) irregular stripes": {"ru":"Радиальные лучи(полосы) / нерегулярные полосы"},
    "irregularSpots/irregular pigmentation": {"ru":"Нерегулярные пятна / нерегулярная пигментация"},
    "irregular points and globules": {"ru":"Нерегулярные точки и глобулы"},
    "uneven boundaries": {"ru":"Неровность границ"},
    "color distribution": {"ru":"Распределение цвета"},
    "distinctive structures": {"ru":"Отличительные структуры"},
    "regression sites structures": {"ru":"Участки регрессии / структуры регресии"},
    "grade": {"ru":"Оценка"},


}

export default tr;
