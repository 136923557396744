import React, { useEffect } from 'react';
//import { useLoaderData } from "react-router-dom";
import MyDataGrid from "../components/MyDataGrid"
//import { Button } from '@mui/material';
import { Box, Button, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { T, t } from "../Globals.js"
import Heading from '../components/Heading';


const columns = [
  { field: 'name', headerName: t("ff name"), flex:1, minWidth: 200},

  { field: '_availabilitystatus', headerName: t("ff bill status"), align: 'center', width: 150,
  renderCell: (params) => {
    return <T>{params.value}</T>;
  }},

  { field: 'enddate', headerClassName: 'col-enddate', headerName: t("end date"), align: 'center', width: 150, type: "date",
  valueGetter: (params) => {
    return params.value ? new Date(params.value) : null;
  }},

  { field: 'serial', headerClassName: 'col-serialnum', headerName: t("serial"), flex:0.4, minWidth:150 },
];  


export function DermatoscopesList() {

  useEffect(() => {
    window.glob("title", <T>menu myequipment</T>)
  }, [])

  const create = () => {
    window.navigate('/dermatoscopes/create');
  }

  return (
    <Box sx={{px: 2}}>
      <Fab sx={{position:"fixed", right:20, bottom:20}} onClick={create} variant="extended" color="primary"><AddIcon/><T>add dermatoscopes</T></Fab>
      <Heading heading={<T>menu myequipment</T>} subheading={<T>equipment help</T>} style={{marginBottom:0}}></Heading>
      <Button onClick={create} color="primary" sx={{maxWidth: "fit-content", margin: "1rem auto 0 auto", display: {xs: "none", sm: "flex"}}}><T>add dermatoscopes</T></Button>
      <MyDataGrid
        columns={columns}
        table="dermatoscopes"
        searchCols={[{ col: 'name' }, {col: 'serial'}]}
      />
    </Box>
  );
}