import React, {useState, useEffect} from 'react';
import {Autocomplete, TextField, Box, Typography, Fab} from '@mui/material';
import {T,axios, naming,t,isValidObjectId} from "../Globals.js"
import { PacientsItem } from '../pages/PacientsItem.js';
import { RequestItem } from '../pages/RequestItem.js';
import { TransactionsItem } from '../pages/TransactionsItem.js';
import { DermatoscopesItem } from '../pages/DermatoscopesItem.js';
import { ClinicsItem } from '../pages/ClinicsItem.js';
import { UsersItem } from '../pages/UsersItem.js';

import VisibilityIcon from '@mui/icons-material/Visibility';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ResponsesItem } from '../pages/ResponsesItem.js';
import { ExpertsItem } from '../pages/ExpertsItem.js';

export default function LinkField({table,label, data, name, onChange, viewButton=true, disabledCreate=false, readOnly=false}) {
  const [itemOption, setItemOption] = useState(null);
  const [items, setItems] = useState([]);
  const [openNew, setOpenNew] = useState(false)
  const [idState, setIdState] = useState("create")
  let value = data && data[name]
  //console.log(value)
  //при большом количестве возможны тормоза, переделать запрос так, что бы возвращалось несколько последних элементов, а при вводе новых символов в поле делался новый запрос к серверу
  const update = (data) => {
    if (data) {
      setItemOption(data?._id)
      if (onChange) onChange({target:{"name":name,value:data?._id}})
    }

    axios.get("/api/"+table+"?pageSize=10000").then((resp)=>{
      let list = [];

      if (table==="accountantexpertpayments") {
        list = resp.data.data.map((a)=>{return {...a, id:a?._expert._id}})
      } else {
        list = resp.data.data.map((a)=>{return {...a, id:a._id}})
      }
      setItems(list);
      //console.log(pacientOption, value)
      //console.log(list, value)
      //if (!itemOption && value) 
      if (value && isValidObjectId(value)) setItemOption({value:value, label:naming(list.find(item => item.id === value), table)})
      else if (value) setItemOption({value:value, label:t("dermatoscope model "+value)})
    })
  }

  useEffect(()=>{
    
    update()
    // eslint-disable-next-line 
  },[value, table])
  

  const handleChange = (event,newOption) => {
    setItemOption(newOption)
    if (onChange) onChange({target:{"name":name,value:newOption?.value}})
  };

  const createHandler = ()=>{
    setIdState("create")
    setOpenNew(true)
  }
  const openHandler = () => {
    setIdState(itemOption?.value)
    setOpenNew(true)
  }
/*
  const createCallback = (data) => {
    const newItem = {...data, id: data._id}
    setItems([...items, newItem])
    const newOption = {value: newItem.id, label: newItem.name}
    setItemOption(newOption)
    if (onChange) onChange(name,newOption.value)
  };
*/

  let options = items.map((item) => {
    let label = naming(item,table);

    if (table==="accountantexpertpayments") return {label:label, value:item.expertid};
    return {label:label, value:item._id}
  });
  if (table==="dermatoscopes") {
    options.forEach(x=>{x.group="my"})
    options = [
      {group:"none", value:undefined, label:t("dermatoscope model undefined")},
      ...options,
      {group:"standard", value:"handyscope", label:t("dermatoscope model handyscope")},
      {group:"standard", value:"dermlitehandyscope", label:t("dermatoscope model dermlitehandyscope")},
      {group:"standard", value:"medicam", label:t("dermatoscope model medicam")},
      {group:"standard", value:"other", label:t("dermatoscope model other")},
    ]
  }

  const handleDisableOpenFab = () => {
    if (table !== "dermatoscopes") return !!!itemOption?.value;
    if (!itemOption?.value) return true;
    
    const disableGroups = ["standard"];
    const group = options.filter(opt => opt.value === itemOption.value)?.[0]?.group;

    return disableGroups.some(el => el === group);
  }

  return (
    <Box sx={{display:"flex", mb:2, gap:2, alignItems:"flex-end"}}>
      <Autocomplete
        value={itemOption || null }
        name={name}
        disabled={readOnly}
        groupBy={(o)=>o.group}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.label || "???"}
        sx={{ flexGrow:1}}
        renderInput={(params) => <TextField  {...params} label={label} inputProps={{...params.inputProps, readOnly: true}}/>}
        options={options}
        renderGroup={(params) => (
          <li key={params.key}>
            <Typography variant="subtitle2" color="textSecondary" sx={{ backgroundColor: "#eeeeee" }}>
              <T>{"linkfieldgroup " + params.group}</T>
            </Typography>
            <ul style={{padding: 0}}>
              {params.children.map((el, index) => (
                <div key={index}>{el}</div>
              ))}
            </ul>
          </li>
        )}
      />

      {viewButton && <Fab size="small" color="primary" onClick={openHandler} disabled={handleDisableOpenFab()}><VisibilityIcon/></Fab>}
      {viewButton && <Fab size="small" color="primary" onClick={createHandler} disabled={disabledCreate}><AddCircleOutlineIcon/></Fab>}

      {table==="pacients"&&<PacientsItem openState={[openNew, setOpenNew]} template="popup" id={idState} onCreate={update} onSave={update} />}
      {table==="requests"&&<RequestItem openState={[openNew, setOpenNew]} template="popup" id={idState} onCreate={update} onSave={update} />}
      {table==="transactions"&&<TransactionsItem openState={[openNew, setOpenNew]} template="popup" id={idState} onCreate={update} onSave={update} />}
      {table==="dermatoscopes"&&<DermatoscopesItem openState={[openNew, setOpenNew]} template="popup" id={idState} onCreate={update} onSave={update} />}
      {table==="responses"&&<ResponsesItem openState={[openNew, setOpenNew]} template="popup" id={idState} onCreate={update} onSave={update} />}
      {table==="clinics"&&<ClinicsItem openState={[openNew, setOpenNew]} template="popup" id={idState} onCreate={update} onSave={update} />}
      {(table==="accountantexpertpayments" || table==="users/experts")&&<ExpertsItem openState={[openNew, setOpenNew]} template="popup" id={idState} onCreate={update} onSave={update} />}
      {table==="users" && <UsersItem openState={[openNew, setOpenNew]} template="popup" id={idState} onCreate={update} onSave={update} />}
    </Box>
  );
}