import React, { useEffect,useContext, useState } from 'react';

import Login from './pages/Login';
import ForgottPassword from './pages/ForgottPassword';
import Loading from './pages/Loading';
import Register from './pages/Register';
import MainPage from './pages/MainPage';
import {Contacts} from './pages/Contacts';

import {RequestList} from './pages/RequestList';
import {RequestItem} from './pages/RequestItem';
import {PacientsList} from './pages/PacientsList';
import {PacientsItem} from './pages/PacientsItem';

import {UsersList} from './pages/UsersList'
import {UsersItem} from './pages/UsersItem'

import {ClinicsItem} from './pages/ClinicsItem';
import {ClinicsList} from './pages/ClinicsList';

import {TransactionsList,TransactionsListStatistics} from './pages/TransactionsList';
import {TransactionsItem,TransactionsItemSuccess} from './pages/TransactionsItem';

import {ResponsesList} from './pages/ResponsesList';
import {ResponsesItem} from './pages/ResponsesItem';

import {AisList} from './pages/AisList';
import {AisItem} from './pages/AisItem';

import {ExpertsItem} from './pages/ExpertsItem';


import {ExpertpaymentsList,ExpertpaymentsListStatistics} from './pages/ExpertpaymentsList';
import {ExpertpaymentsItem} from './pages/ExpertpaymentsItem';
import {ExpertpaymentsAct} from './pages/ExpertpaymentsAct';

import {AccountantExpertpaymentsList} from './pages/AccountantExpertpaymentsList';
import {AccountantExpertpaymentsItem} from './pages/AccountantExpertpaymentsItem';

import {AispaymentsList, AispaymentsListStatistics} from './pages/AispaymentsList';
import {AispaymentsItem} from './pages/AispaymentsItem';


import {AccountantList} from './pages/AccountantList';
import {AccountantItem} from './pages/AccountantItem';
import {AccountantReports} from './pages/AccountantReports';

import {FfbillsList} from './pages/FfbillsList';
import {FfbillsItem} from './pages/FfbillsItem';
import {FfapparatsList} from './pages/FfapparatsList';
import {FfapparatsItem} from './pages/FfapparatsItem';

import {DermatoscopesList} from './pages/DermatoscopesList';
import {DermatoscopesItem} from './pages/DermatoscopesItem';


import {ProfilesList} from './pages/ProfilesList';
import {ProfilesItem} from './pages/ProfilesItem';
import Template from './Template';
import TemplateGuest from './TemplateGuest';
import Alert from './components/Alert';
import AiRequest from './pages/AiRequest.js';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {Context,checkToken} from "./Globals.js"


import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
  //useLoaderData
} from "react-router-dom";
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import HandCalibration from './pages/HandCalibration.js';
import ServiceControl from './pages/ServiceControl.js';


function PrivateRoute(props) {
  const navigate = useNavigate();
  window.navigate = navigate
  const {globalState} = useContext(Context);
  //console.log(1111111111111, globalState);
  if (globalState.user === "error") {
    setTimeout(()=>{navigate('/login');},100);
    return <></>;
  }
  //return <div>wer</div>
  return  <div>{ typeof globalState.user == "object" ? <Template fullWidth={props.fullWidth}>{props.children}</Template> : <Loading />}</div>
}
/*
function Pipiska(p){
  console.log(p);
  let z = useLoaderData()
  console.log(z)
  return (
    <div>111</div>
  );
}
*/
const router = createBrowserRouter([
  { path: "/", element: <PrivateRoute ><MainPage /></PrivateRoute>},
  { path: "/login", element: <TemplateGuest><Login /></TemplateGuest>},
  { path: "/register", element: <TemplateGuest><Register /></TemplateGuest>},
  { path: "/forgottpassword", element: <TemplateGuest><ForgottPassword /></TemplateGuest>},
  { path: "/contacts", element: <PrivateRoute><Contacts /></PrivateRoute>},

  { path: "/airequest", element: <PrivateRoute><AiRequest /></PrivateRoute>},
  { path: "/handcalibration", element: <PrivateRoute><HandCalibration /></PrivateRoute>},
  { path: "/requests", element: <PrivateRoute><RequestList /></PrivateRoute>},
  { path: "/requests/:id", element: <PrivateRoute><RequestItem /></PrivateRoute>},
  { path: "/pacients", element: <PrivateRoute><PacientsList /></PrivateRoute>},
  { path: "/pacients/:id", element: <PrivateRoute ><PacientsItem /></PrivateRoute>},
  { path: "/users", element: <PrivateRoute><UsersList /></PrivateRoute>},
  { path: "/users/:id", element: <PrivateRoute><UsersItem /></PrivateRoute>},
  { path: "/transactions", element: <PrivateRoute><TransactionsList /></PrivateRoute>},
  { path: "/transactions/statistics", element: <PrivateRoute><TransactionsListStatistics /></PrivateRoute>},
  { path: "/transactions/:id", element: <PrivateRoute><TransactionsItem /></PrivateRoute>},
  { path: "/transactions/:id/success", element: <PrivateRoute><TransactionsItemSuccess /></PrivateRoute>},

  { path: "/responses", element: <PrivateRoute><ResponsesList /></PrivateRoute>},
  { path: "/responses/:id", element: <PrivateRoute><ResponsesItem /></PrivateRoute>},

  //{ path: "/experts", element: <PrivateRoute><ExpertsList /></PrivateRoute>},
  { path: "/experts/:id", element: <PrivateRoute><ExpertsItem /></PrivateRoute>},


  { path: "/ais", element: <PrivateRoute><AisList /></PrivateRoute>},
  { path: "/ais/:id", element: <PrivateRoute><AisItem /></PrivateRoute>},

  { path: "/expertpayments", element: <PrivateRoute><ExpertpaymentsList /></PrivateRoute>},
  { path: "/expertpayments/statistics", element: <PrivateRoute><ExpertpaymentsListStatistics /></PrivateRoute>},
  { path: "/expertpayments/:id", element: <PrivateRoute><ExpertpaymentsItem /></PrivateRoute>},
  { path: "/expertpayments/act", element: <PrivateRoute><ExpertpaymentsAct /></PrivateRoute>},

  { path: "/aispayments", element: <PrivateRoute><AispaymentsList /></PrivateRoute>},
  { path: "/aispayments/statistics", element: <PrivateRoute><AispaymentsListStatistics /></PrivateRoute>},
  { path: "/aispayments/:id", element: <PrivateRoute><AispaymentsItem /></PrivateRoute>},

  { path: "/accountant", element: <PrivateRoute><AccountantList /></PrivateRoute>},
  { path: "/accountant/reports", element: <PrivateRoute><AccountantReports /></PrivateRoute>},
  { path: "/accountant/:id", element: <PrivateRoute><AccountantItem /></PrivateRoute>},

  { path: "/accountantexpertpayments", element: <PrivateRoute><AccountantExpertpaymentsList /></PrivateRoute>},
  { path: "/accountantexpertpayments/:id", element: <PrivateRoute><AccountantExpertpaymentsItem /></PrivateRoute>},
  { path: "/control", element: <PrivateRoute><ServiceControl /></PrivateRoute>},
  


  { path: "/ffbills", element: <PrivateRoute><FfbillsList /></PrivateRoute>},
  { path: "/ffbills/:id", element: <PrivateRoute><FfbillsItem /></PrivateRoute>},
  { path: "/ffapparats", element: <PrivateRoute><FfapparatsList /></PrivateRoute>},
  { path: "/ffapparats/:id", element: <PrivateRoute><FfapparatsItem /></PrivateRoute>},

  { path: "/dermatoscopes", element: <PrivateRoute><DermatoscopesList /></PrivateRoute>},
  { path: "/dermatoscopes/:id", element: <PrivateRoute><DermatoscopesItem /></PrivateRoute>},


  /*  { path: "/pipiska/:id", element: <Pipiska />, loader:(a)=>a}, */
  { path: "/profile", element: <PrivateRoute><ProfilesItem /></PrivateRoute>},
  { path: "/clinic", element: <PrivateRoute><ClinicsItem /></PrivateRoute>},
  { path: "/clinics", element: <PrivateRoute><ClinicsList /></PrivateRoute>},
  { path: "/clinics/:id", element: <PrivateRoute><ClinicsItem /></PrivateRoute>},

  { path: "/profiles", element: <PrivateRoute><ProfilesList /></PrivateRoute>},
  { path: "/profiles/:id", element: <PrivateRoute><ProfilesItem /></PrivateRoute>},


]);

function App() {
  //let {globalState} = useContext(Context);
  

  let [globalState, setGlobalState] = useState({ 
    alert:{open:false}, 
    popup:{open:false},
    balance:{so:"?", ai:"?"},
    isMobileDevice: (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)
  })
  window.globalState = globalState;
  window.setGlobalState = setGlobalState;
//  window.glob = function (a,b) {
//    if (!b) return window.globalState[a]
//    else window.setGlobalState({...window.globalState, [a]:b})
//  }
  
  window.glob = function (path, newValue) {
    const keys = path.split('.');
    let result = window.globalState;
    for (let i = 0; i < keys.length - 1; i++) {
      result = result[keys[i]];
    }
    if (newValue === undefined) return result[keys[keys.length - 1]];
   
    result[keys[keys.length - 1]] = newValue;
    setGlobalState({...window.globalState});
    //return obj;
  }
  

  useEffect( ()=>{
    async function check() {
      let [user, node_mode] = await checkToken();
      window.features = {
        aiapi: node_mode==="prod" ? false : true,
        ai: node_mode==="prod" ? false : false,
        so: node_mode==="prod" ? false : true,
        unlimitedFF: node_mode==="prod" ? true : true,
      }
      //setGlobalState({...globalState, user:user})
      window.glob("user", user)
      console.log("включено:",window.features);
      console.log("mode:", node_mode);
      //console.log(22222222222)
    }
    check();
    console.log("Моб. устройство: "+globalState?.isMobileDevice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  //window.setGlobalState = setGlobalState;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Context.Provider value={{globalState, setGlobalState}}>
        <CssBaseline />
        <StyledEngineProvider injectFirst>
        <Alert />
        <RouterProvider router={router} />
        </StyledEngineProvider>
      </Context.Provider>
    </LocalizationProvider>
  );
}

export default App;