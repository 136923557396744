import axios from 'axios';
import { useState, useEffect } from 'react';

function useTransaction(data = {}) {

  let [transactionData, setTransactionData] = useState(data);

  const getCalcResult = (data) => {
    if (!data._id) {
      axios.post("/api/transactions/calc", data).then(resp => {
        transactionData = {...data, ...resp.data.data};
        setTransactionData(transactionData);
      })
      return transactionData;
    } 
  }

  useEffect(() => {
    getCalcResult(data);
    // eslint-disable-next-line
  }, [Object.keys(data).length]);

  return {transactionData, setTransactionData, getCalcResult};
}

export default useTransaction;