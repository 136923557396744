import * as React from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
// import Logo from './components/Logo';
import { ThemeProvider } from '@mui/material/styles';
import { T } from './Globals';
import {ReactComponent as IconMelanomenet} from "../src/icons/melanomenetv1.svg"

let theme = window.theme;

export default function Template(props) {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', flexDirection: {xs: "column", md:"row"}, alignItems: 'center', height: '100%', bgcolor: 'background.default' }}>
        <Box sx={{width: { md:"35vw"}, backgroundColor: "#262424", backgroundImage: "url(/img/templateGuest1.png)", backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
          <Stack sx={{margin: "0 auto", maxWidth: 480, p: 4, height: "100%", justifyContent: "center", alignItems: "center", "svg": {transform: {xs: "scale(0.9)", lg: "scale(1.4)"}}}}>
            <Typography variant='myDefault22' fontSize={{xs: "1.2rem", lg:"1.35rem"}}  color={window.theme.palette.white.v2} component="pre" sx={{fontFamily: 'inherit', mb: {xs: 2, md: 8}, textAlign: {xs: "center"}}}><T>about service</T></Typography>
            <IconMelanomenet />
          </Stack>
        </Box>
        <Paper sx={{ width:"100%", display: {xs: "block", md:'flex'}, flexDirection: 'column', justifyContent: 'center', px: 2, pb:2, boxShadow: 0, flex: 1}}>
          {/* <Typography variant="h4" component="h1" align="center" gutterBottom><T>main title</T></Typography> */}
          {/* <Logo style={{maxWidth: "375px"}}/> */}
          <Box sx={{ width: '100%' }}>
            {props.children}
          </Box>
        </Paper>
      </Box>
    </ThemeProvider>
    
  );
}
