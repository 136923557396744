import React, { useState, useEffect } from 'react';
import ImageIcon from '@mui/icons-material/Image';

function Img({ src, alt, style }) {
  let style1 = {
    width: "50px",
    height: "50px",
    objectFit: "cover",
    ...style
  }
  const [initialLoad, setInitialLoad] = useState(true);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setInitialLoad(true);
    setLoaded(false);

    const img = new Image();
    img.src = src;

    img.onload = () => {
      if (initialLoad) {
        setInitialLoad(false);
      }
      setLoaded(true);
    };
    // eslint-disable-next-line
  }, [src]);

  return (
    <>
      {loaded ? (
        <img
          src={src}
          alt={alt}
          style={style1}
        />
      ) : (
        <ImageIcon sx={{ fontSize: 20, ...style1 }} />
      )}
    </>
  );
}

export default Img;
