import React, { useEffect } from 'react';
//import { useLoaderData } from "react-router-dom";
import MyDataGrid from "../components/MyDataGrid"
//import { Button } from '@mui/material';
import { Box, Button, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { T, t } from "../Globals.js"
import Heading from '../components/Heading';
import dayjs from 'dayjs';

function download(e){
  e.stopPropagation();
//  console.log(e.target)

  //window.open()

  var link = document.createElement("a");
  // If you don't know the name or want to use
  // the webserver default set name = ''
  link.setAttribute('download', '');
  link.href = e.target.getAttribute("link");
  document.body.appendChild(link);
  link.click();
  link.remove();
}
const columns = [

  { field: 'ffportnumber', headerName: t("ff port number"), width:100},
  { field: 'name', headerName: t("ff name"), flex:1},
  { field: '_clinic', headerName: t("clinic"), flex:1, valueGetter: (params) => params.row?._clinic?.name },
  { field: '_doctor', headerName: t("user"), flex:1, valueGetter: (params) => params.row?._doctor?.name },

  { color:"primary", field: 'startdate', headerName: t("start date"), width: 170, type: "date", 
    renderCell: (params) => {
      if (params.row.startdate) return dayjs(params.row.startdate).format("DD.MM.YYYY");
    },
    valueGetter: (params) => {
      return params.row.startdate ? new Date(params.row.startdate) : null;
    },
  },
  
  { color:"primary", field: 'enddate', headerName: t("end date"), width: 190, type: "date", 
    valueGetter: (params) => {
      return params.value ? new Date(params.row.enddate) : null;
    },
  },

  { field: 'serial', headerName: t("serial"), width: 130 },
  { color:"primary", field: 'buttons', headerName: t("download"), width: 300, renderCell:(data)=>{

    return <Box gap={5}>
      <Button variant="outlined" size="small" onClick={download} link={"/api/ffapparats/"+data.id+"/1."+data.row.ffportnumber+"_ad.ps1"}>ad</Button>
      <Button variant="outlined" size="small" onClick={download} link={"/api/ffapparats/"+data.id+"/2."+data.row.ffportnumber+"_db.sql"}>sql</Button>
      <Button variant="outlined" size="small" onClick={download} link={"/api/ffapparats/"+data.id+"/3."+data.row.ffportnumber+"_docker.ps1"}>docker</Button>
      <Button variant="outlined" size="small" onClick={download} link={"/api/ffapparats/"+data.id+"/4."+data.row.ffportnumber+"_reconfig.ps1"}>reconf</Button>
    </Box>
  }}

];  
    


export function FfapparatsList() {

  useEffect(() => {
    window.glob("title", <T>ff apparats</T>)
  }, [])

  const create = () => {
    window.navigate('/ffapparats/create');
  }


  return (
    <Box sx={{px: 2}}>
      <Fab sx={{position:"fixed", right:20, bottom:20}} onClick={create} variant="extended" color="primary"><AddIcon /><T>create</T></Fab>
      <Heading heading={<T>ff apparats</T>} style={{marginBottom:0}}></Heading>
      <MyDataGrid
        columns={columns}
        table="ffapparats"
        linktable="ffapparats"
        maxWidth="1500px"
        sortModel={{field: "ffportnumber", sort: "desc"}}

      />
    </Box>
  );
}