import { Paper } from '@mui/material';
import React from 'react'
import LinkField from './LinkField';
import { T } from '../Globals';
import {Box, InputLabel, FormControl, NativeSelect} from '@mui/material';

function SuperAdminPanel({table, ...props}) {
  const user = window.glob("user");
  
  if (!user.role_superadmin) {
    return (null);
  }

  const getContent = () => {
    switch (table) {
      case "pacients":
        return PacientSuperAdminPanel(props);       
      case "requests":
        return RequestSuperAdminPanel(props);       
      case "dermatoscopes":
        return DermatoscopeSuperAdminPanel(props);       
      case "responses":
        return ResponseSuperAdminPanel(props);       
      default:
        return (null);
    }
  }
  
  return (
    <Paper sx={{my: 1, backgroundColor: window.grayColor, p:2, display: "flex", flexDirection: "column", }}>
      <div style={{textAlign: "center"}}>АДМИНКА</div>
      {getContent()}
    </Paper>
  )
}

const PacientSuperAdminPanel = ({data, handleChange}) => {
  return (
    <LinkField variant="elevation" data={data} table="users" label={<T>doctor</T>} name="doctorid" onChange={handleChange} disabledCreate/>
  );
}
const RequestSuperAdminPanel = ({data, handleChange}) => {
  return (
    <LinkField variant="elevation" data={data} table="users" label={<T>doctor</T>} name="doctorid" onChange={handleChange} disabledCreate/>
  );
}
const DermatoscopeSuperAdminPanel = ({data, handleChange}) => {
  return (
    <LinkField data={data} table="users" label={<T>doctor</T>} name="doctorid" onChange={handleChange}/>
  );
}
const ResponseSuperAdminPanel = ({data, handleChange}) => {
  
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel variant="standard" htmlFor="status-response">Статус ответа</InputLabel>
        <NativeSelect
          defaultValue={data.status}
          inputProps={{name: 'status', id: "status-response"}}
          onChange={handleChange}
        >
          <option value={"answered"}>answered</option>
          <option value={"taken"}>taken</option>
          <option value={"new"}>new</option>
        </NativeSelect>
      </FormControl>
    </Box>
  );
}

export default SuperAdminPanel