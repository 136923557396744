import React from 'react';
import { Button,Stack } from '@mui/material';
import { T } from '../Globals';

const Refill = ({isAlert=false, createRequestBtn=true, aiBtn=true, soBtn=true, sx={}, btnProps={}}) => {
  
  const handleClick = (pathRedirect = "/") => {
    if (isAlert) window.glob("alert",{open:false});
    window.navigate(pathRedirect); 
  }

  return (
    <Stack sx={{flexDirection: {xs:"column", md:"row"}, flexWrap: "wrap", justifyContent: "center", gap:2, ...sx}}>
      {(aiBtn && window.features.unlimitedFF) && <Button variant="outlined" color="primary" sx={{p:1, font:"icon",fontWeight:600, fontSize: "1rem", alignSelf:"center" }} onClick={() => {handleClick('/transactions/createFF')}} {...btnProps}>Подключить безлимитный<br />тариф AI</Button>}     
      {(soBtn && (window.features.so || window.features.ai)) && <Button variant="outlined" color="primary" sx={{p:1, font:"icon",fontWeight:600, fontSize: "1rem", alignSelf:"center" }} onClick={() => {handleClick('/transactions/create')}} {...btnProps}>Оплатить тариф<br />“Второе мнение”</Button>}
      {createRequestBtn && <Button variant="contained" color="secondary" sx={{p:1, fontSize: "1rem", alignSelf:"center" }} onClick={()=>{window.navigate('/pacients');}}><T>create recuest snapshot</T></Button>}
    </Stack>
  );
};

export default Refill;