import React, { useState } from 'react';
import { T, Alert } from "../Globals.js"
import { Stack,TextField, FormGroup, FormControlLabel,Switch } from '@mui/material';
import ItemTemplate from "../components/ItemTemplate";
import useValidation from '../hooks/useValidation.js';


export function UsersItem(props) {

  let [data, setData] = useState({});
  const {myerrors} = useValidation(data, ["name", "email", ...data?.id === "create" ?  ["password", "password_confirmation"] : []]);
  
  const saveHandler = async () => {
    return new Promise((resolve,reject)=>{
      if (myerrors.length===0) resolve();
      else {
        Alert(<T>required error alert</T>).then(()=>{reject()})
      }
    })
  }
  const cancelHandler = async () => {}
  const deleteHandler = async () => {}

  return (
    <ItemTemplate {...props} myerrors={myerrors} title={<T>doctor item</T>} table="users" data={data} setData={setData} onSave={saveHandler} onCancel={cancelHandler} onDelete={deleteHandler}>
      <UsersItemView  />
    </ItemTemplate>
  )
}



export function UsersItemView({myerrors, ...prop}) {
  const handleChange = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.value })
  }
  
  const handleCheck = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.checked })
  }
  
  return (
    <Stack sx={{gap:2}}>
      <TextField
        fullWidth
        name="name"
        label={<T>doctor name</T>}
        onChange={handleChange}
        value={prop.data?.name || ""}
        error={!!myerrors.includes("name")}
        helperText={!!myerrors.includes("name") ? <T>required</T> : ""}
      />

      <TextField
        fullWidth
        label={<T>Email</T>}
        value={prop.data?.email || ""}
        name="email"
        onChange={handleChange}
        error={!!myerrors.includes("email")}
        helperText={!!myerrors.includes("email") ? <T>required</T> : ""}
      />
      <TextField
        fullWidth
        label={<T>phone</T>}
        value={prop.data?.phone || ""}
        name="phone"
        onChange={handleChange}
        error={!!myerrors.includes("phone")}
        helperText={!!myerrors.includes("phone") ? <T>required</T> : ""}
      />

      {prop.data?.id === "create" && (
        <>
          <TextField
            fullWidth
            label={<T>password</T>}
            value={prop.data?.password || ""}
            name="password"
            onChange={handleChange}
            error={!!myerrors.includes("password")}
            helperText={<T>required</T>}
          />

          <TextField
            fullWidth
            label={<T>confirm password</T>}
            value={prop.data?.password_confirmation || ""}
            name="password_confirmation"
            onChange={handleChange}
            error={!!myerrors.includes("password_confirmation")}
            helperText={<T>required</T>}
          />
        </>
      )}
      

      <FormGroup>
        <FormControlLabel 
          control={<Switch />} 
          label={<T>menu so</T>}
          onChange = {handleCheck}
          name="role_doctor"
          checked={prop.data.role_doctor||false}
        />
        <FormControlLabel 
          control={<Switch />} 
          label={<T>function administartor</T>}
          onChange = {handleCheck}
          name="role_administrator"
          checked={prop.data.role_administrator||false}
        />
        <FormControlLabel 
          control={<Switch />} 
          label={<T>function payment</T>}
          onChange = {handleCheck}
          name="role_payment"
          checked={prop.data.role_payment||false}
        />
      </FormGroup>

    </Stack>
  )
}