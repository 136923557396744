import React, { useEffect, useState} from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Box, Stack, Button, CircularProgress,Dialog,  DialogTitle,  DialogContent,  DialogActions, IconButton } from '@mui/material';
import { T , axios, Alert, globalMethods} from "../Globals.js";
import PrintIcon from '@mui/icons-material/Print';
//import PdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import scss from "./ItemTemplate.module.scss";
import Heading from './Heading.js';
import ToPdfButton from "../components/ToPdfButton"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function Template(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const[loadingState, setLoadingState] = useState(true);
  const[disableBtns, setDisableBtns] = useState(false);
  //const[buttonsDisabled, setButtonsDisabled] = useState(false);
  

  let id = props.data?._id || props.id || (props.template !=='popup' && params.id)  || 'create';
//console.log("!!!!!!!!!!!!"+id, props.table)
  useEffect(() => {
    if (props.template!=='popup') window.glob("title", props.title)
    if (props.data?._id === id) {
      setLoadingState(false)
      return; //предотвращает перезагрузку в случае когда нажали на apply при создании элемента
    }

    if (id === 'create' || id==='createFF') {
      props.setData({...props.defaults, id:id})
      setLoadingState(false);
    } else if((props.template==='popup' && props.openState[0]) || props.template!=='popup') {
      setLoadingState(true);
     // console.log("loading "+props.table+id)
      axios.get("/api/"+props.table+"/" + id).then((resp)=>{
        if (resp.data.result === "ok") {
          resp.data.data.id = resp.data.data._id;
          props.setData(resp.data.data)
          setLoadingState(false);
          //console.log("loaded")
        }
        if (resp.data.result === "error") {
          Alert(resp.data.message).then(()=>{
            cancelHandler();
          })
        }
      })
    }
    // eslint-disable-next-line
  }, [id, props.table,props.defaults?.pacientid,props.openState&&props.openState[0]])

  const loading = (loadingState || !props.data?._id) && id!=='create' && id!=='createFF';

  const saveHandler = async () =>{
    try{
      if (props.onSave) await props.onSave();
      // console.log(props.data)
      setLoadingState(true)

      let resp = null;
      if (id === 'create' || id==='createFF') resp = await axios.post("/api/"+props.table, props.data);
      else resp = await axios.put("/api/"+props.table+"/"+props.data._id, props.data);

      if (resp.data.result==="ok") {
        props.setData(null)
        if (props.template==="popup") props.openState[1](false);
        else navigate("/"+(["clinics","users","profile"].includes(props.table)?"":props.table))
        if (props.onCreate) props.onCreate(resp.data.data)
      }
      else if (resp.data.result==="okerror") {
        props.setData(null)
        if (resp.data?.callback) globalMethods[resp.data.callback](resp.data);
        else Alert(resp.data.message);
        if (props.template==="popup") props.openState[1](false);
        else navigate("/"+(["clinics","users","profile"].includes(props.table)?"":props.table))
        if (props.onCreate) props.onCreate(resp.data.data)
      } else {
        Alert(resp.data.message)
      }
    }catch(e){}
    setLoadingState(false)
  }
  const sendHandler = async () =>{
    try { //штука для отмены сохранения - в алерте срабатывает да-нет - если нет - вылетает исключение
      if (props.onSend) await props.onSend()
      saveHandler()
    } catch(e) {}
    
  }
  const rejectHandler = async () =>{
    try {
      if (props.onReject) await props.onReject()
      saveHandler()
    } catch(e) {}
  }

  const applyHandler = async () => {
    setDisableBtns(true);
    try {
      if (props.onApply) await props.onApply();
      let resp = null;
      if (id === 'create' || id === 'createFF') resp = await axios.post("/api/"+props.table, props.data);
      else resp = await axios.put("/api/"+props.table+"/"+props.data._id, props.data);

      if (resp.data.result==="ok") {
        props.setData(resp.data.data)

        //if (id==="create") navigate("/"+props.table+"/"+resp.data.data._id)
        if (props.onCreate) props.onCreate()
      }
      else if (resp.data.result==="okerror") {
        Alert(resp.data.message)
        props.setData(resp.data.data)
        //if (id==="create") navigate("/"+props.table+"/"+resp.data.data._id)
        if (props.onCreate) props.onCreate()
      } else {
        Alert(resp.data.message)
      }
    }catch(e){}
    setDisableBtns(false);
  }


  const cancelHandler = async () =>{
    if (props.onCancel) props.onCancel()
    setLoadingState(true)
    props.setData(null)
    if (location?.pathname === "/profile" || location?.pathname === "/clinic" || location?.pathname === "/handcalibration") navigate("/")
    else if (props.template==="popup") props.openState[1](false);
    else navigate("/"+props.table)
  }
  const deleteHandler = async () =>{
    Alert(<T>delete?</T>,"","confirm").then(async ()=>{
      if (props.onDelete) props.onDelete()
      let resp = null;
      resp = await axios.delete("/api/"+props.table+"/" + props.data._id);
      if (resp.data.result === "ok") {
        props.setData(null)
        setLoadingState(true)
        if (props.onDelete) props.onDelete();
        if (props.template==="popup") props.openState[1](false);
        else navigate("/"+props.table)
      } else {
        Alert(resp.data.message)
      }
 
    }).catch((e)=>{})
  }
  const printHandler = () =>{
    window.print()
  }

  
  const getButtonsStatus = () => {
    if (loadingState) return true;
    return disableBtns;
  }

  const handlers = {delete:deleteHandler, save:saveHandler, cancel:cancelHandler, send:sendHandler, apply:applyHandler}

  const buttons = [
    { name: "apply", el: <Button disabled={getButtonsStatus()} variant="contained" color="purpllight" onClick={applyHandler}><T>apply</T></Button> },
    { name: "draft", el: <Button disabled={getButtonsStatus()} variant="outlined" color="primary" onClick={saveHandler}><T>draft</T></Button> },
    { name: "save", el: <Button disabled={getButtonsStatus()} variant="contained" color="primary" onClick={saveHandler}><T>save</T></Button> },
    { name: "send", el: <Button disabled={getButtonsStatus()} variant="contained" color="primary" onClick={sendHandler}><T>send</T></Button> },
    { name: "cancel", el: <Button disabled={getButtonsStatus()} variant="outlined" color="primary" onClick={cancelHandler} startIcon={<ArrowBackIosIcon />}><T>cancel</T></Button> },   
    { name: "close", el: <Button disabled={getButtonsStatus()} variant="outlined" color="primary" onClick={cancelHandler} startIcon={<ArrowBackIosIcon />}><T>close</T></Button> },
    { name: "reject", el: <Button disabled={getButtonsStatus()} variant="contained" color="error" onClick={rejectHandler}><T>reject</T></Button> },
    { name: "delete", el: <IconButton disabled={getButtonsStatus()} variant="contained" color="error" onClick={deleteHandler} ><DeleteForeverIcon /></IconButton> },
    { name: "print", el: <Button variant="contained" color="white" onClick={printHandler} startIcon={<PrintIcon />}><T>print</T></Button> },
    { name: "pdf", el: <ToPdfButton url={"/"+props.table+"/"+id} /> },
  ];

const getButtons = (btns = []) => {
  if (id === "create") {
    btns = btns.filter(btn => btn !== "delete" && btn !== "print");
  }
  if (window.glob("user").role_superadmin) {
    btns.push("save");
  }
  if (!window.glob("user").role_superadmin && props.table === "expertpayments") {
    btns = btns.filter(btn => btn !== "delete" && btn !== "save");
  }
  if (window.location.pathname === "/airequest") {
    btns = btns.filter(btn => btn !== "draft");
  }
  return buttons.filter(btn => btns.includes(btn.name));
}

  /*
  const getButtons = () => { 
    const replaceKeys = Object.keys(props).map(key => key.toLowerCase().replace(/on/g, ""));
    return btns.filter(btn => replaceKeys.includes(btn.name));
  }
  */

 // console.log(loading, loadingState, !!!props.data);

  //if (!id || (id && id!=='create' && !props.data._id)) { 
  if(props.template==="popup" && !props.openState[0]) {
    return <></>
  }
  if (props.template==="popup") return (
    <Dialog className="MyMainMuiDialog" sx={{"& div.MuiDialog-container > div": {maxWidth: 'fit-content', width:"1000px", m: 0}}} open={props.openState[0]}>
      <DialogTitle sx={{pb:0}}><Heading heading={props.title} style={{margin: 0}}/></DialogTitle>
      <DialogContent className="MyMainDialogContent">
        {
          loading ?
            <Box sx={{display:"flex", height:"100%", alignItems:"center", justifyContent:"center"}}><CircularProgress /></Box>
          :
            React.Children.map(props.children, (child, index) => { return React.cloneElement(child, { ...props, ...handlers, children:undefined}) })
            //через ...props попадает props.children в новый элемент, который рендерит что-то лишнее
        }
      </DialogContent>
      <DialogActions className='dialogActions' sx={{flexWrap: "wrap", gap: 1, justifyContent: "center"}}>
        
        {getButtons(props.buttons || ["save", "cancel", "delete"]).map(btn => <div key={btn.name}>{btn.el}</div>)}

      </DialogActions>
    </Dialog>
  )


  return (

      <Box sx={{maxWidth:window.maxWidth, width:"100%", margin:"20px auto", px:2}}>
        {props.title && <Heading heading={props.title} style={{margin: 0}}/>}
        {
          loading ?
            <Box sx={{display:"flex", height:"100%", alignItems:"center", justifyContent:"center"}}><CircularProgress /></Box>
          :
            React.Children.map(props.children, (child, index) => { return React.cloneElement(child, { ...props, ...handlers }) })
        }

        <Stack className={scss.buttons} direction="row" sx={{ mt:2, gap: 1, flexWrap: "wrap", justifyContent: "center"}}>
          {(getButtons(props.buttons || ["save", "close", "delete"])).map(btn => <div key={btn.name}>{btn.el}</div>)}
        </Stack>
      </Box>
  )
}

export default Template;