import React, { useEffect } from 'react';
//import { useLoaderData } from "react-router-dom";
import MyDataGrid from "../components/MyDataGrid"

import {  Box } from '@mui/material';

import { T, t } from "../Globals.js"
import GExpense from '../components/graphics/GExpense.js';
import Heading from '../components/Heading';
import dayjs from 'dayjs';

const columns = [
  { field: 'date', headerName: t("date"), width: 130, renderCell: (params) => {
    if (params.formattedValue) return dayjs(params.formattedValue).format("DD.MM.YYYY HH:mm");
  } },
  { field: 'amount_rub', headerName: t("amount rub"), width: 130, renderCell: (params) => {
    if (params.formattedValue) {
      const value = params.formattedValue;
      let color = "green";

      if (Math.sign(value) === -1 && Math.sign(value) !== 0) color = "red";
      return <span style={{color: color}}>{value}</span>
    };
  } },
//  { field: 'amount_bonus', headerName: t("amount bonus"), width: 130 },
//  { field: '_request', headerName: t("pacient"), width: 130, valueGetter:(p)=>p.row._request?._pacient?.name||'' },
  //{ field: 'requestid', headerName: t("request"), width: 130 },
  //{ field: 'expertid', headerName: t("expert"), width: 130 },
];  


export function AispaymentsList() {


  useEffect(() => {
    window.glob("title", <T>ai payments</T>)
  }, [])


  return (
    <Box sx={{px: 2}}>
      <Heading heading={<T>ai payments</T>}></Heading>
      <MyDataGrid
        columns={columns}
        table="aispayments"
      />
    </Box>
  );
}

export function AispaymentsListStatistics() {
  return <GExpense sx={{mt: 5}} table="aispayments" charts={["seriesRubPlus"]}  />
 
}
