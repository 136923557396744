import React, {  useState } from 'react';
import { Button,  TextField, Switch,FormGroup,FormControlLabel, Box, Stack, Typography, ListItemButton, ListItemText, Collapse, Paper} from '@mui/material';
import {T,logout,pushSubscribe,axios,getImageUrl,Alert} from '../Globals';
import ItemTemplate from "../components/ItemTemplate";
import ImageUpload from "../components/ImageUpload";
import LinkField from "../components/LinkField"
//import Heading from '../components/Heading';
import {  useParams } from "react-router-dom";
import PasswordField from '../components/PasswordField';
import Help from '../components/Help';
import Cookies from 'js-cookie';
// import ExpertProfileTable from '../components/ExpertProfileTable';
// import MyExpertProfileTable from '../components/MyExpertProfileIditeTable';

export function ProfilesItem(props) {
  const params = useParams()
  const user = window.glob("user");

  const defaults = {
    role_doctor:false, 
    role_administrator:false, 
    role_expert:false, 
    role_payment:false, 
    role_superadmin:false, 
    role_ai:false, 
    role_accountant:false
  }
  //console.log (params)
  let [data, setData] = useState({})
  let id = params.id || user._id

  const saveHandler = async () => { if (
    window.glob("user")._id === data._id) window.glob("user", data) 
  }
  // const deleteHandler = async () => {}
  const cancelHandler = async () => {}

  return (
    <>
      <ItemTemplate {...props} title={<T>profile</T>} id={id} table="profile" defaults={defaults} data={data} setData={setData} buttons={["save", "close"]} onSave={saveHandler} onCancel={cancelHandler} >
        <ProfileView  />
      </ItemTemplate>
    </>
  )
}

function ProfileView(prop) {
  // if (!prop.data.updatingProfile) prop.setData({...prop.data, updatingProfile: true}); 

  const handleChange = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.value })
  }
  
  const handleCheck = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.checked })
  }
  const pushTest = async () => {
    await axios.get("/api/pushtest");
  }
  const handleUncontrolledChange = (event) => {
    prop.data[event.target.name] = event.target.value;
  }

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);

  const removeDefaultRoles = () => {
    window.glob("user")["role_doctor"] = false;
    window.glob("user")["role_payment"] = false;
    window.glob("user")["role_administrator"] = false;
    delete window.glob("user")?.["clinicid"];

    Cookies.remove("sa_clinicid"); //не нужно привязываться к клинике, если привязываемся к бухгалтеру или эксперту
  }

  const handleSuperUser = (event) => {
    const value = event.target.value;
    const user = window.glob("user");  

    if (value) {
      removeDefaultRoles();
      if (value === "expert") {
        user["role_expert"] = true;
        user["role_accountant"] = false;    
      } else if (value === "accountant") {
        user["role_expert"] = false;
        user["role_accountant"] = true;
      }

      Cookies.set("sa_userid", prop.data._id);
      window.navigate("/"); 
    }
  }

  const handleGetRole = () => {
    if (prop.data.role_expert) return "expert";
    if (prop.data.role_accountant) return "accountant";
    return null;
  }

  const userBlocking = async () => {
    Alert("Блокировка", "Введите пречину блокировки пользователя", "prompt").then(d => {
      axios.put(`/api/users/blocked/${prop.data._id}`, {
        blockedmessage: d.comment,
      }).then(resp => {
        if (resp.data.result !== "ok") {
          Alert(<T>{resp.data.message}</T>);
          return
        };
        prop.setData({...prop.data, ...resp.data.data});
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  const unlock = async () => {
    const response = await axios.put(`/api/users/blocked/${prop.data._id}`, {
      blockedmessage: null
    });

    if (response.data.result !== "ok") {
      Alert(<T>{response.data.message}</T>);
      return;
    }

    prop.setData({ ...prop.data, ...response.data.data });
  }
  
  return (
    <>
      <Stack sx={{width:"100%", gap: "1.5rem", mt: 2}}>
        {(prop.data.role_expert || prop.data.role_accountant) && <Button onClick={handleSuperUser} value={handleGetRole()}>Поработить душу юзера</Button>}
        <Box sx={{display: "flex", gap: "2rem", flexWrap: "wrap", alignItems: "center", justifyContent: "center"}}>
          <Box sx={{gap: 2, minWidth: "300px", flex: 2}}>
            <Stack sx={{gap: 2, marginBottom: 3}}>
              <TextField
                label={<T>fio</T>}
                fullWidth
                onChange = {handleUncontrolledChange}
                name="name"
                defaultValue={prop.data.name}
              />
              <TextField
                label={<T>Email</T>}
                fullWidth
                onChange = {handleUncontrolledChange}
                name="email"
                defaultValue={prop.data.email}
              />
              <TextField
                label={<T>phone</T>}
                fullWidth
                onChange = {handleUncontrolledChange}
                name="phone"
                defaultValue={prop.data.phone}
              />
              <LinkField table="dermatoscopes" name="dermatoscopeid" data={prop.data} label={<T>default dermatoscope</T> } onChange={handleChange}  enableCreate={true} />

              <Paper>
                <ListItemButton onClick={handleOpen} sx={{"span": {fontWeight: "bold !important"}}}>
                  <ListItemText primary={<T>change password</T>} primaryTypographyProps={{color: "primary"}} />
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit sx={{padding: 2, pt:0}}>
                  <PasswordField
                    required
                    fullWidth
                    name="current_password"
                    label={<T>current password</T>}
                    onChange={handleChange}
                    value={prop.data?.current_password || ""}
                  />
                  <PasswordField
                    required
                    fullWidth
                    name="new_password"
                    label={<T>new password</T>}
                    onChange={handleChange}
                    value={prop.data?.new_password || ""}
                    sx={{mt:0.5}}
                  />
                  <PasswordField
                    required
                    fullWidth
                    name="password_confirmation"
                    label={<T>confirm password</T>}
                    onChange={handleChange}
                    value={prop.data?.password_confirmation || ""}
                    sx={{mt:0.5}}
                  />
                </Collapse>
              </Paper>
              
            </Stack>
            
            <Stack>  
              <Help sx={{mb:2}}><T>push help</T></Help>
              <Stack sx={{gap: 1, flex: 1, flexDirection:"row"}}>
                <FormControlLabel 
                  control={<Switch />} 
                  label={<T>push enable</T>}
                  onChange = {handleCheck}
                  onClick = {(e)=>{
                    if (e.target.checked) pushSubscribe()
                  }}
                  disabled={!("Notification" in window)}
                  name="push_notify"
                  checked={prop.data.push_notify||false}
                />
                <Button variant="outlined" color="primary" onClick={()=>{pushTest()}}><T>push test</T></Button>
              </Stack>
              <FormControlLabel 
                control={<Switch />} 
                label={<T>email notify newresponse</T>}
                onChange = {handleCheck}
                name="email_notify_newresponse"
                checked={prop.data.email_notify_newresponse||false}
              />
              <FormControlLabel 
                control={<Switch />} 
                label={<T>show help</T>}
                onChange = {handleCheck}
                name="show_help"
                checked={prop.data.show_help||false}
              />
              {prop.data.role_expert && 
                <FormControlLabel 
                  control={<Switch />} 
                  label={<T>email notify newrequest</T>}
                  onChange = {handleCheck}
                  name="email_notify_newrequest"
                  checked={prop.data.email_notify_newrequest||false}
                />
              }
            </Stack>
          </Box>
          
          {prop.data.role_expert && 
            <Box sx={{display: "flex", justifyContent: "center", flexDirection: "column", alignSelf:"flex-start", alignItems:"center", flex: 1}}>
              <Typography component="h1" variant='myDefault22' sx={{}}><T>your photo</T></Typography>
              <ImageUpload name="image" src={getImageUrl(prop.data)} onChange={(d)=>prop.setData({ ...prop.data, "image": d })} />
            </Box>
          }
        </Box>


        {/* <MyExpertProfileTable/>
        <ExpertProfileTable/> */}

        {prop.data.role_expert && 
          <Stack sx={{width: "100%", minWidth: "300px", m: "0 auto", gap: 2}}>
            <TextField 
              label={<T>academic...</T>}
              fullWidth
              multiline
              onChange = {handleUncontrolledChange}
              name="academic"
              defaultValue={prop.data.academic}
            />
            <TextField 
              label={<T>experience...</T>}
              fullWidth
              multiline
              onChange = {handleUncontrolledChange}
              name="experience"
              defaultValue={prop.data.experience}
            />
            <TextField 
              label={<T>regalii</T>}
              fullWidth
              multiline
              onChange = {handleUncontrolledChange}
              name="regalii"
              defaultValue={prop.data.regalii}
            />
            <TextField 
              label={<T>short aboutme</T>}
              fullWidth
              multiline
              onChange = {handleUncontrolledChange}
              name="aboutme"
              defaultValue={prop.data.aboutme}
            />
            <TextField 
              label={<T>long aboutme</T>}
              fullWidth
              multiline
              onChange = {handleUncontrolledChange}
              name="longaboutme"
              defaultValue={prop.data.longaboutme}
            />
            <TextField 
              label={<T>legal name</T>}
              fullWidth
              onChange = {handleUncontrolledChange}
              name="legalname"
              defaultValue={prop.data.legalname}
            />
            <TextField 
              label={<T>dogovor</T>}
              fullWidth
              onChange = {handleUncontrolledChange}
              name="dogovor"
              defaultValue={prop.data.dogovor}
            />

          </Stack>
        }
        <T>roles</T>
        <FormGroup sx={{ flexDirection: "column", maxWidth: "fit-content"}}>
          <FormControlLabel 
            control={<Switch />} 
            label={<T>menu so</T>}
            onChange = {handleCheck}
            name="role_doctor"
            checked={prop.data.role_doctor||false}
          />
          <FormControlLabel 
            control={<Switch />} 
            label={<T>menu administrator</T>}
            onChange = {handleCheck}
            name="role_administrator"
            checked={prop.data.role_administrator||false}
          />
          <FormControlLabel 
            control={<Switch />} 
            label={<T>menu payment</T>}
            onChange = {handleCheck}
            name="role_payment"
            checked={prop.data.role_payment||false}
          />

          {(window.glob("user").role_superadmin || window.glob("user").email==="vvpolyakov@gmail.com") && <>
            <FormControlLabel 
              control={<Switch />} 
              label={<T>menu expert opinion</T>}
              onChange = {handleCheck}
              name="role_expert"
              checked={prop.data.role_expert||false}
            />
            <FormControlLabel 
              control={<Switch />} 
              label={<T>expert show on main</T>}
              onChange = {handleCheck}
              name="expert_show"
              checked={prop.data.expert_show||false}
            />
            <FormControlLabel 
              control={<Switch />} 
              label={<T>superadmin</T>}
              onChange = {handleCheck}
              name="role_superadmin"
              checked={prop.data.role_superadmin||false}
            />
            <FormControlLabel 
              control={<Switch />} 
              label={<T>menu ai</T>}
              onChange = {handleCheck}
              name="role_ai"
              checked={prop.data.role_ai||false}
            />
            <FormControlLabel 
              control={<Switch />} 
              label={<T>menu accountant</T>}
              onChange = {handleCheck}
              name="role_accountant"
              checked={prop.data.role_accountant||false}
            />
          </>}
        </FormGroup>
      </Stack>


      <Box sx={{display: "flex", justifyContent: "space-between"}}>
        <Button variant="contained" color="primary" onClick={()=>{logout()}} sx={{my:2}}><T>logout</T></Button>
        {window.glob("user")?.role_superadmin && <>
          {prop.data?.blockedmessage 
            ? <Button variant="contained" color="success" value={false} onClick={unlock} sx={{my:2}}>разблокировать</Button>
            : <Button variant="contained" color="error" value={true} onClick={userBlocking} sx={{my:2}}>заблокировать</Button>
          } 
        </>}
      </Box>
    </>
  );
}

