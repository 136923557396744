import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

export default function ReadOnlyField({label, value, chips, index=null, divider=true, sx={}}) {
  return (
    <>
      <Box sx={{my:1, ...sx}}>
        <Typography variant="subtitle2" color="textSecondary">{index ? index : null}{label}: {chips} </Typography>
        <Typography variant="body1">{value}</Typography>
        {divider && <Divider sx={{backgroundColor: "grey", opacity: 0.5}}/> }
      </Box>
    </>
  );
}
