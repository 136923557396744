import React, { useState, useEffect } from 'react';
import { T} from "../Globals.js"
import {  Box, Typography, Button, Stack } from '@mui/material';
//import ToPdfButton from '../components/ToPdfButton.js';
import dayjs from 'dayjs';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Heading from '../components/Heading.js';



export function AccountantReports() {
    const [value, setValue] = useState(0);
    const [yearsData, setYearsData] = useState(null);
  
    const user = window.glob("user");
    //console.log (user)
    if (typeof user.date === 'string' && user.date.includes('1970')) {
      user.date = new Date(2023, 6, 1);
    }
    const userRegDate = dayjs(user.date);
  
  
    const getYearsDataList = (startDate) => {
      const [startYear, startMonth] = [startDate.year(), startDate.month()];
      const currentDate = dayjs();
      const yearMonthObject = {};
    
      for (let year = startYear; year <= currentDate.year(); year++) {
        const months = [];
        const startMonthIndex = year === startYear ? startMonth : 0;
        for (let month = startMonthIndex; month < 12; month++) {
          if (year === currentDate.year() && month > currentDate.month()) break;
    
          const monthDate = dayjs().year(year).month(month);
          months.push(monthDate.format('MMMM'));
        }
        yearMonthObject[year] = months;
      }
      return yearMonthObject;
    };
  
    useEffect(() => {
      setYearsData(getYearsDataList(userRegDate));
      // eslint-disable-next-line
    }, []);
    
  
    return (
      <Box sx={{width:"100%", maxWidth:window.maxWidth, margin:"20px auto", px: 2}}>
        <Heading heading={<T>accountant reports</T>} style={{marginTop:0}}></Heading>
        {yearsData && (
          <Box sx={{ display: "flex", height: "auto", minHeight:300, mt: 2, justifyContent: "center"}}>
            <Tabs
              value={value}
              onChange={(event, value) => setValue(value)}
              scrollButtons="auto"
              variant="scrollable"
              orientation="vertical"
              sx={{
                display: 'flex', // Обязательно для вертикального направления
                backgroundColor: window.theme.palette.purpl.v2,
                margin: 0,
                borderRadius: "1rem 0 0 1rem"
              }}
            >
              {Object.keys(yearsData).sort((a, b) => b - a).map((year, index) => (
                <Tab key={year} label={<Typography variant="myDefault22" color="black">{year}</Typography>} value={index} />
              ))}
            </Tabs>
            {Object.keys(yearsData).sort((a, b) => b - a).map((year, index) => (
              <Box key={year} hidden={value !== index} sx={{ backgroundColor:window.grayColor, width: 800, textAlign: "center", px:2, pb:2, borderRadius: "0 1rem 1rem 0"}}>
                <Typography sx={{my: 2}} variant="myDefault22" color="black" component="div">{year}</Typography>
                <Stack>
                  {yearsData[year].map((month, index) => (
                    <Box sx={{display:"flex"}} key={month}>
                      <Button component="a" target="_blank" href={`/api/accountant/exceldeposit/card/${year}-${dayjs(month, 'MMMM').format('MM')}`}  sx={{width: "250px",m:1}}><T>{month}</T><br /><T>deposit card</T></Button>
                      <Button component="a" target="_blank" href={`/api/accountant/exceldeposit/bank/${year}-${dayjs(month, 'MMMM').format('MM')}`}  sx={{width: "250px",m:1}}><T>{month}</T><br /><T>deposit bank</T></Button>
                      <Button component="a" target="_blank" href={`/api/accountant/excelspending/card/${year}-${dayjs(month, 'MMMM').format('MM')}`}  sx={{width: "250px",m:1}}><T>{month}</T><br /><T>spending card</T></Button>
                      <Button component="a" target="_blank" href={`/api/accountant/excelspending/bank/${year}-${dayjs(month, 'MMMM').format('MM')}`}  sx={{width: "250px",m:1}}><T>{month}</T><br /><T>spending bank</T></Button>
                    </Box>
                  ))}
                </Stack>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    );
  } 