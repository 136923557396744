import React, { useState } from "react";
import { Box, Typography, Fab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export default function NumberInput({min=1, max=3, name, data, setData, label}) {
  const [value, setValue] = useState(1);

  const decreaseValue = () => {
    if (value > min) {
      setValue(value - 1);
      data[name] = value - 1;
      setData(data)
    }
  };

  const increaseValue = () => {
    if (value < max) {
      setValue( value + 1);
      data[name] = value + 1;
      setData(data)
    }
  };
  return (
    <Box>
      {label && <Typography variant="subtitle1" color="textSecondary" align="center">{label}</Typography>}
      <Box display="flex" alignItems="center" justifyContent="center" gap={1.5}>
        <Fab sx={{width: "30px", height:"30px", minHeight: "30px"}} color="primary" onClick={decreaseValue} aria-label="remove"><RemoveIcon/></Fab>
        <Box sx={{fontSize:"1.5rem"}}>{value}</Box>
        <Fab sx={{width: "30px", height:"30px", minHeight: "30px"}} color="primary" onClick={increaseValue} aria-label="add"><AddIcon/></Fab>
      </Box>
    </Box>
  );
}
