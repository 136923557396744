import React, { useState } from 'react';
import {T} from "../Globals.js"
import {Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    ThemeProvider,
    Typography,
    } from '@mui/material';

const Alert = () => {
  //const {globalState} = useContext(Context);
  const [data, setData] = useState({});
  const myalert = window.glob("alert");

  const onCancel = () => {
    window.glob("alert",{open:false})
    if (myalert.onCancel) myalert.onCancel();
  }
  const onOK = () => {
    window.glob("alert",{open:false})
    if (myalert.onOK) myalert.onOK(data);
  }

  const handleChange = (event) => {
    setData({...data, [event.target.name]: event.target.value});
  }

  //if ((myalert.description && !myalert.msg) && (!Cookies.get("tocken"))) {
  if (myalert?.alertData?.blockeduser) {
    return (<DialogBlockedUser myalert={myalert} onOK={onOK}/>);
  }

  return (
    <ThemeProvider theme={window.theme}>
      <Dialog open={myalert.open}>
        <DialogTitle><T>{myalert.msg}</T></DialogTitle>
        <DialogContent>
          <DialogContentText component="div" sx={{color: "black"}}><T>{myalert.description}</T></DialogContentText>
          {myalert.type === "prompt" && 
            <TextField fullWidth multiline name="comment" defaultValue={data?.comment || ""} onChange={handleChange}></TextField>
          }
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onOK}>
            <T>ok</T>
          </Button>
          {(myalert.type==="confirm" || myalert.type==="prompt") &&
            <Button color="primary" onClick={onCancel} autoFocus>
              <T>cancel</T>
            </Button>
          }
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

function DialogBlockedUser({myalert, onOK}) {
  return (
    <ThemeProvider theme={window.theme}>
      <Dialog open={myalert.open}>
        <DialogTitle sx={{pb:0.5}}><T>you are blocked</T></DialogTitle>
        <DialogContent>
          <DialogContentText component="div" sx={{color: "black"}}>
            <Typography textAlign={"center"} fontSize={18} sx={{pb:2}}><T>blocked cause</T>:</Typography>
            <T>{myalert.alertData?.message}</T>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onOK}><T>ok</T></Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default Alert