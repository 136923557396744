import React,{useEffect, useState,} from 'react';
import { DataGrid,  GridToolbarContainer, GridToolbarQuickFilter, ruRU } from '@mui/x-data-grid';
// import { Box,  CircularProgress} from '@mui/material';
//import { Button } from '@mui/material';
import {  axios , naming, t, T, Alert, technicalСonnectionPalette } from "../Globals.js"
import { useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import dayjs from 'dayjs';
import { RadioGroup, FormControlLabel, Radio, Fab, Box } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

function CustomToolbar({CustomToolbar, filter, setFilter}) {
  const columns = CustomToolbar?.columns || [];
  const searchCols = CustomToolbar?.searchCols || [];
  const [radios, setRadios] = useState([]);
  const [quickFilterSelected, setQuickFilterSelected] = useState(null);
  const [dateComponent, setDateComponent] = useState(false);
  const [dpkey, setDPKey] = useState("random");

  useEffect(() => {
    setRadios(columns.filter((el) => searchCols.map(sc => sc.col).includes(el.field)));
    // eslint-disable-next-line
  }, [])

  const handleChange = (event) => {
    const selectedCol = searchCols.find(el => el.col === event.target.defaultValue);
    const type = columns.find(el => el.field === selectedCol?.col)?.type;
    const updatedFilter = {...filter, quickFilterSelected: selectedCol, quickFilterValues: []};
  
    if (type === "date" || type === "dateTime") {
      updatedFilter.quickFilterSelected = { ...selectedCol, type: "date" };
      setDateComponent(true);
    } else {
      setDateComponent(false);
    }
  
    setFilter(updatedFilter);
    setQuickFilterSelected(updatedFilter.quickFilterSelected);
  };

  const handleChangeDate = (date) => {
    if (date) {
      /*
        если добавить к полю тип dateTime то помимо  даты придется вводить еще и время. (муторно вводить все это дело)
        задумка была такая, чтобы сервер вывел все строки за дату, которую указал пользователь независимо от времени
      */
      const startDate = dayjs(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      const endDate = dayjs(startDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      setFilter({ ...filter, quickFilterValues: [startDate, endDate] });
    } else {
      const alphabet = 'abcdefghijklmnopqrstuvwxyz';
      const randomString = [...Array(5)].map(() => alphabet[Math.floor(Math.random() * alphabet.length)]).join('');
      setDPKey(randomString);
      setFilter({ ...filter, quickFilterValues: [] });
    }
  }

  return (
    <GridToolbarContainer sx={{flexWrap: "wrap"}}>
      {radios.length > 0 && (<>
        <T>search by</T>:
        <RadioGroup row>  
          {radios.map((el, key) => <FormControlLabel key={key} value={el.field} control={<Radio onChange={handleChange} size="small"/>} label={el.headerName} />)}
        </RadioGroup>
        {(quickFilterSelected && !dateComponent) && <GridToolbarQuickFilter sx={{flex:1, minWidth: 230}} />}
        {(quickFilterSelected && dateComponent) && (
        <Box sx={{position: "relative"}}>
          <DatePicker
            key={dpkey}
            name="date"
            format="DD-MM-YYYY"
            inputFormat="YYYY-MM-DD"
            onChange={(date) => handleChangeDate(date)}
          />
          <Fab color="secondary" sx={{width: 24, height: 24, backgroundColor: "transparent", boxShadow: "none", position: "absolute", top: -2.5, right: 32, "&>svg": {color: "black"}, ":active": {boxShadow: "none"}, ":hover": {backgroundColor: "transparent"}}}
            onClick={() => handleChangeDate(false)}
          >
            <ClearOutlinedIcon fontSize='small' sx={{color:'gray'}} />
          </Fab>
        </Box>
        )}
      </>)}
    </GridToolbarContainer>
  );
}


export default function MyDataGrid(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: 20 });
  var [filter, setFilter] = useState(props.filter || {items:[], quickFilterSelected: null});
  const [sortModel, setSortModel] = useState([{field: 'date', sort: 'desc', ...props.sortModel}]);
  
  if (props.filter) {
    filter = props.filter;
    setFilter = null;
  }
  
  //if (props.filter) filter = props.filter;
  //filter.items[0] = {"field": "fullname", "operator": "contains", "value": filter?.quickFilterValues || ""}
  
  useEffect(() => {
    setLoading(true);

    // if ((filter?.items?.[0]?.field === "birth" || filter?.items?.[0]?.field === "enddate" || filter?.items?.[0]?.field === 'startdate') && filter?.items?.[0]?.value) {
    //   filter.items[0].value = dayjs(filter.items[0].value).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    // } 

    axios.get(`/api/${props.table}?page=${paginationModel.page}&pageSize=${paginationModel.pageSize}&filter=${encodeURIComponent(JSON.stringify(filter))}&sort=${sortModel[0]?.field}&sortDirection=${sortModel[0]?.sort}`).then((resp) => {
      if (resp.data.result !== "ok") {
        Alert("",resp.data.message).then(()=>{});
      }

      const d = resp.data.data.map((a) => { return { ...a, id: a._id } })
      
      setData(d);
      setTotal(resp.data.total)
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }, [props.table,paginationModel,filter,sortModel]);

  let cols = [...props.columns];
  // для суперадминов 
  if (window.glob("user").role_superadmin) {
    // добавить колонку Клиника (но только, если она еще не добавлена)
    /*
    if(!cols.some(obj => obj.field === "_clinic")) {
      cols.push({ field: '_clinic', headerName: t("clinic"), width: 250, valueGetter:(p)=>naming(p.row._clinic,"clinics") },)
    }
    */
    if (props.table === "responses") {
      cols.push({ field: '_expert', headerName: t("expert"), width: 250, valueGetter:(p)=>naming(p.row._expert,"users")})
    }
  }

  cols.forEach(col => {
    if (col.field === "enddate") renderEndDate(col)
    if (col.field === "_availabilitystatus") renderAvailabilitystatus(col)   
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (props.hideCols) cols = hideCols(props, cols);
  if (isMobile) cols = mobile(props, cols);

  return (
    <>
      <div style={{width:"100%", overflowX: 'scroll', maxWidth:props.maxWidth || window.maxWidth, margin:"20px auto"}}>
        
        <DataGrid 

          {...props}
          sx={{
            "& .MuiDataGrid-overlayWrapper": {height: 50}, 
            "& .MuiDataGrid-columnHeaders": {backgroundColor: "#deebff"},
            "& .MuiDataGrid-columnHeader:not(:first-child):not(:last-child)": {borderRight: "1px solid rgba(0,0,0,.04)", borderLeft: "1px solid rgba(0,0,0,.04)"},
            "& .MuiDataGrid-columnHeaderTitle": {fontWeight: "bolder"},  
            "& .MuiDataGrid-cell": {border: "1px solid rgba(0,0,0,.04)"},  
            "& .MuiDataGrid-row": {cursor: "pointer"},  
            display: "inline-grid", 
            width: "100%",
          }}

          localeText={{
            ...ruRU.components.MuiDataGrid.defaultProps.localeText,
          }}
        
          //disableColumnFilter
          //disableColumnSelector
          //disableDensitySelector
          //disableExport
        
          rowHeight={props?.rowHeight || 52}

          onRowClick={(row) => {
            navigate(`/${props.linktable || props.table}/${row.id}`)
          }}
          rows={data}
          columns={cols}
          rowCount={total} 
        
          disableSelectionOnClick
        
          pageSizeOptions={[20,100]}
        
          loading={loading}
          slots={{ 
            toolbar: CustomToolbar,
            loadingOverlay: LinearProgress,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { 
                debounceMs: 500,
                placeholder: t("sarch fio"),
              },
              CustomToolbar: {
                columns: props.columns,
                searchCols: props.searchCols,
              },
              filter: filter,
              setFilter: setFilter,
            },
          }}
        
          paginationModel={paginationModel}
          paginationMode="server"
          sortingMode="server"
          filterMode="server"
        
          sortingOrder={['desc', 'asc']}
          sortModel={sortModel}
          filterModel={filter}

          onPaginationModelChange={setPaginationModel}
          onFilterModelChange={setFilter}
          onSortModelChange={setSortModel}
        />
      </div>
    </>
  )
}

function renderEndDate(col) {
  col.renderCell = (params) => {
    if (params.value) {
      let color = "black";
      const endDate = dayjs(params.value);
      
      if (dayjs() > dayjs(endDate).subtract(15, "day")) color = "sienna";
      if (dayjs() >= dayjs(endDate).subtract(5, "day")) color = "red";

      return <div style={{ color: color}}>{dayjs(params.value).format("DD.MM.YYYY")}</div> 
    }
  }
}
function renderAvailabilitystatus(col) {
  col.renderCell = (params) => {
    return <div style={{color: technicalСonnectionPalette[params?.value]}}><T>{params?.value}</T></div>
  }
}

function hideCols(props, cols) {
  props.hideCols.forEach(val => {
    const index = cols.findIndex(item => item.field === val);
    if (index !== -1) cols.splice(index, 1);
  });
  
  return cols;
}

function mobile(props, cols) {
  cols.forEach(col => {
    if (col.field === "date") {
      col.width = 90;
      col.renderCell = (params) => {
        return params.row?.date ? <div><div>{dayjs(params.row.date).format("DD.MM.YYYY")}</div><div>{dayjs(params.row.date).format("HH:mm")}</div></div> : null;
      };
    }
    if (col.field === "_pacient" || col.field === "_doctor") {
      col.width = 150;
      col.renderCell = (params) => {
        const fio = params.value.split(" ");
        let fullname = fio[0]+" ";
        if (fio[1]?.[0]) fullname += fio[1][0]+".";
        if (fio[2]?.[0]) fullname += fio[2][0]+".";
        return fullname;
      };
    }
  });

  if (props.hideColsForMobile) props.hideColsForMobile.forEach((val) => {
    cols = cols.filter(col => col.field !== val);
  });

  return cols;
}
