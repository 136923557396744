import React, {  } from 'react';

function PdfViewer({ src }) {

  return (
    <div>
      {src && (
        <embed
          src={src+"#toolbar=0&navpanes=0"}
          type="application/pdf"
          width="100%"
          height="300"
        ></embed>
      )}
    </div>
  );
}

export default PdfViewer;
