import React, {  useState} from 'react';
import { Button } from "@mui/material";
import { T , axios} from "../Globals.js";
import PdfIcon from '@mui/icons-material/PictureAsPdf';

export default function ToPdfButton(props) {
  const[buttonsDisabled, setButtonsDisabled] = useState(false);
  const topdfHandler = () =>{
    setButtonsDisabled(true);
    axios.get("/api/topdf?path=" + encodeURIComponent(props.url), {
      responseType: 'blob',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf');
      link.click();
      setButtonsDisabled(false);
    });
  }
  return  <Button variant="contained" color="white" onClick={topdfHandler} disabled={buttonsDisabled} startIcon={<PdfIcon/>} {...props}>{props.children || <T>topdf</T>}</Button>
    
}