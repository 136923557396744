import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {T} from "../Globals"

const CountdownTimer = ({ dateTaken , minutes=30, zeroHandler}) => {
  const [timeRemaining, setTimeRemaining] = useState(0);
  dateTaken = new Date(dateTaken)
  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = window.dateServer;
      const difference = (dateTaken.getTime() + minutes * 60 * 1000) - currentTime;

      if (difference <= 0) {
        clearInterval(intervalId);
        handleTimeReachedZero(); // Обработчик достижения нуля
      } else {
        setTimeRemaining(difference);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, [dateTaken, minutes]);

  const handleTimeReachedZero = () => {
    if (zeroHandler) zeroHandler();
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const isLessThanOneMinute = timeRemaining < 60000; // Меньше 1 минуты

  return (
    <Box>
      <Typography variant="h4" sx={isLessThanOneMinute ? { color: 'red' } : {}}>
        <T>time left</T>: {formatTime(timeRemaining)}
      </Typography>
    </Box>
  );
};

export default CountdownTimer;
