import { Box, Divider, Stack, Typography } from "@mui/material";
import { React } from "react";

const items = [
  {
    feature: ["unlimitedFF"],
    color: "purpl",
    title: "Сервис цифрового анализа дерматоскопических изображений новообразований включает:",
    lis: [
      <Typography>Оценку дерматоскопических снимков: Геометрия/ Асимметрия/Границы и неровности/ Цвет/Структура</Typography>,
      <Typography>Цифровой анализ с определением риска злокачественности по «от 0 до 1», время выполнения анализа до 10 секунд</Typography>,
      <Typography>Сравнение снимков новообразований, сделанных в разное время для определения эволюции новообразований и риска злокачественности</Typography>,
      <Typography>Все результаты оценки предоставляются в виде PDF-отчета</Typography>,
    ],
    additionalTitle: "При оплате сервиса вы получаете безлимитный доступ ко всем его функциям",
    additionalContent: [
      <Stack sx={{flexDirection: {xs:"column", sm:"row"}, width: {xs: "90%", sm: "65%"}, mx: "auto", alignItems: "center", justifyContent: "space-between", pt:4, pb:2}}>
        <Typography fontSize={18} color="#fff">Промоверсия <span style={{whiteSpace: "nowrap"}}> <strong>30 дней</strong></span></Typography>
        <Typography fontSize={22} fontWeight={600} color="#fff" sx={{pl:1, whiteSpace: "nowrap", mt: {xs: 2, sm:0}}}>99 ₽</Typography>
        {/* <Typography fontSize={18} color="#fff">Промоверсия <span style={{whiteSpace: "nowrap"}}> <strong>20 дней</strong></span></Typography>
        <Typography fontSize={22} fontWeight={600} color="#fff" sx={{pl:1, whiteSpace: "nowrap", mt: {xs: 2, sm:0}}}>Бесплатно</Typography> */}
      </Stack>,
      <Divider sx={{borderColor: "#fff", width: "85%", margin: "0 auto"}}/>,
      <Stack sx={{flexDirection: {xs:"column", sm:"row"}, width: {xs: "90%", sm: "65%"}, mx: "auto", alignItems: "center", justifyContent: "space-between", pt:2, pb:2}}>
        <Typography fontSize={18} color="#fff">Стоимость при оплате <span style={{whiteSpace: "nowrap"}}>за <strong>90 дней</strong></span></Typography>
        <Typography fontSize={22} fontWeight={600} color="#fff" sx={{pl:1, whiteSpace: "nowrap", mt: {xs: 2, sm:0}}}>30 000 ₽</Typography>
      </Stack>,
      <Divider sx={{borderColor: "#fff", width: "85%", margin: "0 auto"}}/>,
      <Stack sx={{flexDirection: {xs:"column", sm:"row"}, width: {xs: "90%", sm: "65%"}, mx: "auto", alignItems: "center", justifyContent: "space-between", pt:2, pb:4}}>
        <Typography fontSize={18} color="#fff">Стоимость при оплате <span style={{whiteSpace: "nowrap"}}>за <strong>1 год</strong></span></Typography>
        <Typography fontSize={22} fontWeight={600} color="#fff" sx={{pl:1, whiteSpace: "nowrap", mt: {xs: 2, sm:0}}}>90 000 ₽</Typography>
      </Stack>,
    ]
  },
  {
    feature: ["ai", "so"],
    color: "orange",
    title: "Сервис «Второе мнение» включает:",
    lis: [
      <Typography>Оценку дерматоскопического снимка новообразования врачом - экспертом в области онкологии и дерматологии</Typography>,
      <Typography>Заключение эксперта по трем пунктам: оценка эксперта/ рекомендации по постановке дифференциального диагноза / рекомендации эксперта</Typography>,
      <Typography>Срок выполнения запроса «Второе мнение» до 2 рабочих дней</Typography>,
      <Typography>Результаты отображаются в личном кабинете пользователя и могут быть загружены в виде <span style={{whiteSpace: "nowrap"}}>PDF-отчета</span></Typography>,
    ],
    additionalContent: [
      <Stack sx={{flexDirection: "row", width: "70%", mx: "auto", justifyContent:{xs:"center", md: "space-between"}, py:4, flexWrap:"wrap", alignItems: "center"}}>
        <Typography fontSize={18} color="#fff" textAlign="center">Стоимость одного запроса на <span style={{whiteSpace: "nowrap"}}>“Второе мнение”</span></Typography>
        <Typography fontSize={22} fontWeight={600} color="#fff" sx={{whiteSpace: "nowrap", pl:1, mt: {xs: 2, sm:0}}}>3 000 ₽</Typography>
      </Stack>,
    ]
  },
];

export default function PromoDescription () {
  return (
    items.filter(({ feature }) => { 
      if (!feature) return true;
      if (feature.length > 1) return (window.features["ai"] || window.features["so"]) ? true : false;
        else if (window.features[feature]) return true;
      return false;
     }).map((item, key) =>
      <Box key={key} sx={{borderRadius: 1, width: "100%", px:2, backgroundColor: "#f2f2f5", position: "relative"}}>
        <Box sx={{width: "40px", height: {xs: "40px", lg: "90px"}, backgroundColor: window.theme.palette?.[item.color]?.light, position: "absolute", left: "2%"}}></Box>
        
        <Stack sx={{maxWidth: 780, margin: "0 auto", py: 8}}>
          <Typography variant='myH2'>{item.title}</Typography>
          <ul style={{paddingLeft: 15, display: "flex", flexDirection: "column", gap:"1em", margin: "0 auto", marginTop:"1.5em"}}>
            {item.lis.map((li, k) => <li key={k}>{li}</li>
            )}
          </ul>
          <Typography fontSize={22} sx={{my: 3}}>{item.additionalTitle}</Typography>
          <Box sx={{bgcolor: window.theme.palette.purpl.light}}>
            {item.additionalContent.map((el, k) => <div key={k}>{el}</div>)}
          </Box>
        </Stack>
      </Box>
    )
  );
}