import React, {  useState, useRef } from 'react';
import {   T, t, axios, Alert} from "../Globals.js"
import { Stack, TextField,Box,Button,MenuItem} from '@mui/material';
import MyDataGrid from "../components/MyDataGrid";
import ItemTemplate from "../components/ItemTemplate";
//import LinkField from "../components/LinkField";
//import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Heading from '../components/Heading.js';
import LinkField from '../components/LinkField.js';

export function FfapparatsItem(props) {
  let [data, setData] = useState({ });
  const saveHandler = async () => { }
  const deleteHandler = async () => { }
  const cancelHandler = async () => {}

  return (
    <>
      <ItemTemplate {...props} title={<T>ffapparats item</T>} defaults={{status:"completed", paymentmethod:"gift"}} table="ffapparats" data={data} setData={setData} onSave={saveHandler} onCancel={cancelHandler} onDelete={deleteHandler}>
        <FfapparatsItemView  />
      </ItemTemplate>
    </>
  )
}


export function FfapparatsItemView(prop) {

  let [dateStart, setDateStart] = useState(prop.data?.startdate ? dayjs(prop.data?.startdate) : "");
  let [dateEnd, setDateEnd] = useState(prop.data?.enddate ? dayjs(prop.data?.enddate) : "");
  let passwordRef = useRef();

  if (!prop.data.ffsqladdr) prop.data.ffsqladdr = "10.23.45.22"
  if (!prop.data.fflicenseaddr) prop.data.fflicenseaddr = "10.23.45.21"
  if (!prop.data.ffsmbaddr) prop.data.ffsmbaddr = "10.23.45.11"

  const handleUncontrolledChange = (event) => {
    prop.data[event.target.name] = event.target.value;
  }
  const handleChange = (event) => {
    //prop.data[event.target.name] = event.target.value;
    prop.setData({...prop.data, [event.target.name] : event.target.value })
  }

  const handleChangeDate = (event) => {
    if (!dateStart) {
      Alert(<T>set access date</T>);
      return false;
    }
    if (!dateEnd) dateEnd = dateStart;

    let newEnddate = null;
    switch (event.target.value) {
      case "quartal":
        newEnddate = dayjs(dateEnd).add(91, "day");
        break;
      case "year":
        newEnddate = dayjs(dateEnd).add(366, "day");
        break;
      case "trial20":
        newEnddate = dayjs(dateEnd).add(21, "day");
        break;
      case "trial30":
        newEnddate = dayjs(dateEnd).add(31, "day");
        break;
      default:
        break;
    } 
    prop.setData({...prop.data, startdate: dayjs(dateStart).format('YYYY-MM-DDTHH:mm:ss.SSSZ'), enddate: dayjs(newEnddate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')});
    setDateEnd(dayjs(newEnddate));
  }
  const handleReloadDates = () => {
    axios.get(`/api/dermatoscopes/${prop.data._id}`).then((resp) => {
      if (resp.data.data) {
        prop.setData({...prop.data, startdate: resp.data.data?.startdate ?? "", enddate: resp.data.data?.enddate ?? ""}); 
        setDateStart(resp.data.data?.startdate ?? "");
        setDateEnd(resp.data.data?.enddate ?? "");
      } 
    }).catch(() => {
      Alert(<T>dates not reset</T>);
    }); 
  }

  const passwordGenerate = (a) =>{
    function generatePassword() {
      var length = 8,
          charset1 = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
          charset2 = "abcdefghijklmnopqrstuvwxyz",
          charset3 = "0123456789",
          charset = charset1+charset2+charset3,
          retVal = "";

          retVal += charset1.charAt(Math.floor(Math.random() * charset1.length));
          retVal += charset2.charAt(Math.floor(Math.random() * charset2.length));
          retVal += charset3.charAt(Math.floor(Math.random() * charset3.length));
      
      for (var i = retVal.length, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    }
    Alert(<T>ff password generate confirm</T>,"","confirm").then(()=>{
      prop.data.ffpassword = generatePassword()
      passwordRef.current.querySelector("input[name=ffpassword]").value = prop.data.ffpassword
    }).catch(()=>{})

    //prop.setData(prop.data);
  }

  /*
  const handleCheck = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.checked })
  }
  */
/*  const confirmHandler = async function () {
    const res = await axios.post("/api/ffapparats/confirm/"+prop.data._id)
    if (res.data.result === "ok") window.navigate("/ffapparats")
    else {Alert(res.data.message); }
  }
*/

return (
    <Stack sx={{ gap: 2 }}>
      ID: {prop.data._id}
      <LinkField label={<T>clinic</T>} table="clinics" data={prop.data} name="clinicid"  field="_clinic" onChange={handleChange} />
      <LinkField label={<T>doctor</T>} table="users" data={prop.data} name="doctorid"  field="_doctor" onChange={handleChange} />

      <TextField
        fullWidth
        label={<T>ff name</T>}
        defaultValue={prop.data?.name || ""}
        name="name"
        onChange={handleUncontrolledChange}
      />

      <TextField
        fullWidth
        label={<T>serial</T>}
        defaultValue={prop.data?.serial || ""}
        name="serial"
        onChange={handleUncontrolledChange}
      />
      <DatePicker
        label={<T>date from</T>}
        name="startdate"
        value={dateStart ? dayjs(dateStart) : ""}
        format="DD-MM-YYYY"
        inputFormat="YYYY-MM-DD"
        onChange={(date) => {setDateStart(date); prop.setData({...prop.data, startdate: dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')})}}
        sx={{maxWidth: 330}}
      />
      <DatePicker
        label={<T>end date to</T>}        
        name="enddate"
        value={dateEnd ? dayjs(dateEnd) : ""}
        format="DD-MM-YYYY"
        inputFormat="YYYY-MM-DD"
        onChange={(date) => {setDateEnd(date); prop.setData({...prop.data, enddate: dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')})}}
        sx={{maxWidth: 330}}

      />
      ПРОВЕРКА: Дата конца минус срок должно быть в прошлом: {
        dayjs(dateEnd).add(-prop.data?.ffdays - 1, "day").toDate() < new Date() ? "true":"false"
      }
      <Box sx={{display:"flex", gap:2}}>
        <Button onClick={handleChangeDate} value="quartal" color="primary" variant="outlined"><T>add quartal</T></Button>
        <Button onClick={handleChangeDate} value="year" color="primary" variant="outlined"><T>add year</T></Button>
        <Button onClick={handleChangeDate} value="trial20" color="primary" variant="outlined">Триал 20</Button>
        <Button onClick={handleChangeDate} value="trial30" color="primary" variant="outlined"><T>trial</T> 30</Button>
        <Button onClick={handleReloadDates} value="year" color="warning" variant="text"><T>clear dates</T></Button>
      </Box>
      <TextField
        fullWidth
        select
        label={<T>ff days</T>}
        value={prop.data?.ffdays || ""}
        name="ffdays"
        onChange={handleChange}
      >
        <MenuItem key='k0' value={0}>0</MenuItem>
        <MenuItem key='k20' value={20}>20</MenuItem>
        <MenuItem key='k30' value={30}>30</MenuItem>
        <MenuItem key='k90' value={90}>90</MenuItem>
        <MenuItem key='k365' value={365}>365</MenuItem>
      </TextField>
      {/*
      <TextField
        fullWidth
        label={<T>ff login</T>}
        defaultValue={prop.data?.fflogin || ""}
        name="fflogin"
        onChange={handleUncontrolledChange}
      />
      */}
      <TextField
        fullWidth
        label={<T>ff port number</T>}
        defaultValue={prop.data?.ffportnumber || "" }
        name="ffportnumber"
        onChange={handleUncontrolledChange}
      />

      <Box sx={{display:"flex", gap:2}}>
        <TextField
          fullWidth
          label={<T>ff password</T>}
          defaultValue={prop.data?.ffpassword || ""}
          name="ffpassword"
          onChange={handleUncontrolledChange}
          ref={passwordRef}
        />
        <Button sx={{width:"200px"}} onClick={passwordGenerate}><T>ff password generate</T></Button>
      </Box>
      <TextField
        fullWidth
        label={<T>ff license addr</T>}
        defaultValue={prop.data?.fflicenseaddr || ""}
        name="fflicenseaddr"
        onChange={handleUncontrolledChange}
      />
      <TextField
        fullWidth
        label={<T>ff sql addr</T>}
        defaultValue={prop.data?.ffsqladdr || ""}
        name="ffsqladdr"
        onChange={handleUncontrolledChange}
      />
      {/*
      <TextField
        fullWidth
        label={<T>ff sql database</T>}
        defaultValue={prop.data?.ffsqldatabase|| ""}
        name="ffsqldatabase"
        onChange={handleUncontrolledChange}
      />
      */}
      <TextField
        fullWidth
        label={<T>ff smb addr</T>}
        defaultValue={prop.data?.ffsmbaddr || ""}
        name="ffsmbaddr"
        onChange={handleUncontrolledChange}
      />
      {/*
      <TextField
        fullWidth
        label={<T>ff smb folder</T>}
        defaultValue={prop.data?.ffsmbfolder || ""}
        name="ffsmbfolder"
        onChange={handleUncontrolledChange}
      />
      */}



      <TextField
        fullWidth
        label={<T>ff comment private</T>}
        defaultValue={prop.data?.comment_private || ""}
        name="comment_private"
        onChange={handleUncontrolledChange}
      />
      <TextField
        fullWidth
        label={<T>ff comment</T>}
        defaultValue={prop.data?.comment || ""}
        name="comment"
        onChange={handleUncontrolledChange}
      />

      <br />
      <Heading heading={<><T>payments for</T>: {prop.data?.name || ""}</>} />
      <MyDataGrid
        columns={[
          { field: 'date', headerName: t("date"), width: 130, renderCell: (params) => {
            return params.row.date ? <div style={{textWrap: "wrap"}}>{dayjs(params.row.date).format("DD.MM.YYYY")} {dayjs(params.row.date).format("HH:mm")}</div> : null;
          } },
          { field: 'amount_rub', headerName: t("amount rub"), width: 130, renderCell: (params) => {
            if (params.formattedValue) {
              const value = params.formattedValue;
              let color = "green";
        
              if (Math.sign(value) === -1 && Math.sign(value) !== 0) color = "red";
              return <span style={{color: color}}>{value}</span>
            };
          } },
          { field: 'unlimitedFF', headerName: t("unlimitedFF period"), width: 130, renderCell:(p)=>t(p.formattedValue) },
          { field: 'paymentmethod', headerName: t("payment method"), width: 130, renderCell:(p)=>t("payment method "+p.formattedValue) },
          { field: 'status', headerName: t("payment status"), width: 130, renderCell:(p)=>t("payment "+p.formattedValue) },
          { field: 'ffbillstatus', headerName: t("ff bill status"), width: 130, renderCell:(p)=>t("ff bill status "+p.formattedValue) },
        ]}
        table="ffbills"
        filter={{items:[{field:"dermatoscopes", operator:"equals", value: prop.data?._id}]}}

      />

      
    </Stack>
  )
}