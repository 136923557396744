import React, {  } from 'react';
import { Chip, SvgIcon } from "@mui/material";
import scss from "./StatusChip.module.scss";
import {ReactComponent as IconSo} from "../icons/so.svg"
import {ReactComponent as IconAi} from "../icons/ai.svg"
import {t} from "../Globals.js";
const yello = "#ffdd00"
const gray = "#777"
const red = "#dd0000"
const blue = "#bbeeff"

export default function StatusChip({type=null, diagnosis='', status="", text='', sx={}}) {
  let backgroundColor = "#ffffff";
  let textColor = ""
  if (type==="ai" && diagnosis) {
    backgroundColor = diagnosis === "safely" ? yello : diagnosis === "oncologist" ?  red : gray;
    if (diagnosis !== "safely")textColor="#ffffff"
  }
  if (type==="so" && status === "answered") {
    backgroundColor = blue
  }
  if (type==="so" && status === "reject") {
    backgroundColor = blue
  }

  return <Chip 
    className={scss.mychip} 
    icon={
      (type==="so" && <SvgIcon><IconSo /></SvgIcon>) || (type==="ai" && <SvgIcon><IconAi /></SvgIcon>) || null
    } 
    sx={{color:textColor, backgroundColor: backgroundColor, border:"1px solid gray", ...sx}} 
    size="small" 
    //label={diagnosis? t("diagnosis "+diagnosis):t("status "+status)}  
    label={type==='ai' && diagnosis? t("diagnosis "+diagnosis):t("status "+status)}  
  />
}