import React from 'react'
import { T } from "../Globals.js"
import ItemTemplate from "../components/ItemTemplate"
import { Button, ButtonGroup, Box, Stack, Typography, Card, CardContent, CardActions } from '@mui/material';

const rules = [
  {
    name: "rule_3",
    items: [
      { label: "asymmetry", answer: null },
      { label: "atypical drawing", answer: null },
      { label: "white blue structures", answer: null }
    ],
    grade: 0,
    result: "",
  },
  {
    name: "rule_7",
    items: [
      { label: "atypical pigment pattern", answer: null },
      { label: "blueGray areas blueWhite veil", answer: null },
      { label: "atypical vascular pattern", answer: null },
      { label: "radialRays(stripes) irregular stripes", answer: null },
      { label: "irregularSpots/irregular pigmentation", answer: null },
      { label: "irregular points and globules", answer: null },
      { label: "regression sites structures", answer: null }
    ],
    grade: 0,
    result: "",
  },
  {
    name: "rule_ABCD",
    items: [
      {
        label: "asymmetry", calculation: 1.3, variants: [
          { label: "0", answer: null },
          { label: "1", answer: null },
          { label: "2", answer: null }
        ]
      },
      {
        label: "uneven boundaries", calculation: 0.1, variants: [
          { label: "0", answer: null },
          { label: "1", answer: null },
          { label: "2", answer: null },
          { label: "3", answer: null },
          { label: "4", answer: null },
          { label: "5", answer: null },
          { label: "6", answer: null },
          { label: "7", answer: null },
          { label: "8", answer: null },
        ]
      },
      {
        label: "color distribution", calculation: 0.5, variants: [
          { label: "1", answer: null },
          { label: "2", answer: null },
          { label: "3", answer: null },
          { label: "4", answer: null },
          { label: "5", answer: null },
          { label: "6", answer: null },
        ]
      },
      {
        label: "distinctive structures", calculation: 0.5, variants: [
          { label: "1", answer: null },
          { label: "2", answer: null },
          { label: "3", answer: null },
          { label: "4", answer: null },
          { label: "5", answer: null },
        ]
      },
    ],
    grade: 0,
    result: "",
  }
];


export default function HandCalibration(props) {
  let [data, setData] = React.useState({});

  return (
    <ItemTemplate {...props} title={<T>hand calibration</T>} data={data} setData={setData} buttons={["close"]}>
      <HandCalibrationView />
    </ItemTemplate>
  )
}

export function HandCalibrationView() {
  const [calibration, setCalibration] = React.useState(rules)

  const calibrationRule_3 = (ruleIndex, itemIndex, action) => {

    let result = "not suspicious";

    action
      ? calibration[ruleIndex].items[itemIndex].answer = true
      : calibration[ruleIndex].items[itemIndex].answer = false;

    const trueAnswers = calibration[ruleIndex].items.filter(item => item.answer === true);

    if (trueAnswers.length > 1) {
      result = "suspicious";
    }

    calibration[ruleIndex].grade = trueAnswers.length; 
    calibration[ruleIndex].result = result;
    setCalibration([...calibration]);
  }


  const calibrationRule_7 = (ruleIndex, itemIndex, action) => {

    let result = "not suspicious";

    action
      ? calibration[ruleIndex].items[itemIndex].answer = true
      : calibration[ruleIndex].items[itemIndex].answer = false;

    const trueAnswers = calibration[ruleIndex].items.filter(item => item.answer === true);

    if (trueAnswers.length > 0) {
      result = "suspicious";
    }

    calibration[ruleIndex].grade = trueAnswers.length;
    calibration[ruleIndex].result = result;
    setCalibration([...calibration]);
  }


  const calibrationRule_ABCD = (ruleIndex, itemIndex, variantIndex) => {

    let result = "not suspicious";

    const rule = calibration[ruleIndex];

    rule.items[itemIndex].variants.forEach((v, index) => {
      v.answer = index === variantIndex ? !v.answer : null;
    });

    let calc = rule.items.reduce((sum, item) => {
      const selectedVariant = item.variants.find(v => v.answer);
      return selectedVariant ? sum + Number(selectedVariant.label) * item.calculation : sum;
    }, 0);
    calc = calc.toFixed(1);

    if (calc >= 4.8) {
      result = "suspicious";
    }

    // if (calc >= 4.8 && calc <= 5.45) {
    //   result = "подозрительное"
    // } else if (calc > 5.45) {
    //   result = "подозрительное"
    // }

    calibration[ruleIndex].grade = calc;
    calibration[ruleIndex].result = result;
    setCalibration([...calibration])
  }


  const handleCalibration = (ruleIndex, itemIndex, action) => {

    switch (calibration[ruleIndex].name) {
      case "rule_3":
        calibrationRule_3(ruleIndex, itemIndex, action)
        break;
      case "rule_7":
        calibrationRule_7(ruleIndex, itemIndex, action)
        break;
      case "rule_ABCD":
        calibrationRule_ABCD(ruleIndex, itemIndex, action)
        break;

      default:
        break;
    }
  }

  return (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', alignItems: 'center', mt: 4 }}>
      {calibration.map((rule, ruleIndex) => {

        return (
          <Card key={rule.name} variant="outlined" sx={{ width: { xs: "100%", lg: "70%" } }}>
            <Typography variant='h6' sx={{pl: 2, pt: 2, textAlign: "center"}}><T>{rule.name}</T></Typography>
            <CardContent>
              {rule.name !== "rule_ABCD"
                ? <ContentRuleVariant1 rule={rule} ruleIndex={ruleIndex} handleCalibration={handleCalibration} />
                : <ContentRuleVariant2 rule={rule} ruleIndex={ruleIndex} handleCalibration={handleCalibration} />
              }

            </CardContent>

            <CardActions sx={{ pl: 2, display: "flex", flexDirection: "column" }}>
              <Typography sx={{width: "100%"}}><T>neoplasm</T>: <span className=' font-bold underline'><T>{rule.result}</T></span></Typography>
              <Typography sx={{width: "100%", ml: "0px !important"}}><T>grade</T>: <span className=' font-bold underline'>{rule.grade}</span></Typography>
            </CardActions>
          </Card>
        );
      })}
    </Box>
  );
}

export function ContentRuleVariant1({ rule, ruleIndex, handleCalibration }) {
  return (
    <>
      {rule.items.map((item, itemIndex) => (
        <Stack key={itemIndex} sx={{ flexDirection: { xs: "column", md: "row" }, justifyContent: "space-between", mt: 1 }}>
          <T>{item.label}</T>
          <ButtonGroup variant="outlined" aria-label="Basic button group">
            <Button
              sx={{ borderRadius: 0 }}
              variant={item.answer === true ? "contained" : "outlined"}
              onClick={() => handleCalibration(ruleIndex, itemIndex, true)}
            >
              <T>yes</T>
            </Button>
            <Button
              sx={{ borderRadius: 0 }}
              variant={item.answer === false ? "contained" : "outlined"}
              onClick={() => handleCalibration(ruleIndex, itemIndex, false)}
            >
              <T>no</T>
            </Button>
          </ButtonGroup>
        </Stack>
      ))}
    </>
  );
}

export function ContentRuleVariant2({ rule, ruleIndex, handleCalibration }) {
  return (
    <>
      {rule.items.map((item, itemIndex) => (
        <Stack key={itemIndex} sx={{ flexDirection: { xs: "column", md: "row" }, justifyContent: "space-between", mt: 1 }}>
          <T>{item.label}</T>
            <ButtonGroup sx={{ width: { md: "60%" }, "&.MuiButtonGroup-root button": {minWidth: 0, paddingX: 0} }} variant="outlined">
              {item.variants.map((variant, i) => {
                return (
                  <Button
                    sx={{ width: "100%", borderRadius: 0 }}
                    key={variant.label}
                    variant={variant.answer ? "contained" : "outlined"}
                    onClick={() => handleCalibration(ruleIndex, itemIndex, i)}
                  >
                    {variant.label}
                  </Button>
                )
              })}
            </ButtonGroup>
          {/* } */}
        </Stack>
      ))}
    </>
  );
}