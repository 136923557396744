import React, {useState,useEffect,useContext } from 'react';
import {Box, Stack, SvgIcon, Typography} from '@mui/material';
import {axios, Context} from "../../Globals.js";
// import {ReactComponent as IconAi} from "../../icons/ai.svg";
import {ReactComponent as IconSo} from "../../icons/so.svg";
import {ReactComponent as IconAi} from "../../icons/ai.svg";
import dayjs from 'dayjs';
import Refill from '../Refill.js';

function Balance(props) {
  // const {globalState} = useContext(Context);
  const [firstDermatoscop, setFirstDermatoscop] = useState(null);
  const {globalState} = useContext(Context);

  useEffect(() => {
    axios.get(`/api/dermatoscopes`).then((resp) => {
      if (resp.data.data) {
        const apparats = resp.data.data.filter((device) => new Date(device.enddate).getTime() >= new Date().getTime());
        const sortApparats = apparats.sort((a, b) => new Date(a.enddate).getTime() - new Date(b.enddate).getTime());
        if (sortApparats[0]?.enddate) setFirstDermatoscop(sortApparats[0].enddate);
      } 
    }).catch(() => {});
    // eslint-disable-next-line
  }, []);

  return (
    <Stack sx={{mb: {xs: 5, md: 0}, height:"100%", p: 2, alignItems: "center", justifyContent: "center", gap: 2.7, backgroundColor: window.theme.palette.white.v2, width: "70%", margin: "0 auto"}}>
      <Typography textAlign="left" component="div" variant='myDefault22'>Ваши действующие тарифы</Typography>
      <Stack sx={{gap: 2.7, flexDirection: "column", justifyContent: "space-between"}} >
        {firstDermatoscop && 
          <Box sx={{borderRadius: 1, display: "flex", gap: 1, alignItems: "center"}}>
            <SvgIcon fontSize='large'>
                <IconAi/>
            </SvgIcon>
            <Typography sx={{ml: 1}} color="black">“Безлимитный тариф AI” - <strong>{dayjs(firstDermatoscop).format("DD.MM.YYYY")}</strong></Typography>
          </Box>
        }
        
        {window?.features?.so && 
        <Box sx={{borderRadius: 1, display: "flex", gap: 1, alignItems: "center"}}>
          <SvgIcon fontSize='large'>
            <IconSo />
          </SvgIcon>
          <Typography sx={{ml: 1}} color="black">Количество доступных запросов на сервис “Второе мнение” - <strong>{globalState?.balance.so || "0"}</strong></Typography>
        </Box>
        } 
      </Stack>
      
      <Refill btnProps={{color: "secondary"}}/>
      
    </Stack>
  );
}
  
export default Balance;
