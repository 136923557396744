import { Button, Stack, Typography, Paper, TextField, CircularProgress } from '@mui/material'
import React from 'react'
import axios from 'axios';

function ServiceControl() {
  //Рассылка о технических работах
  const mailingTitle = React.useRef();
  const mailingMessage = React.useRef();
  const [mailingLoading, setMailingLoading] = React.useState(false);

  const handleMailing = async () => {
    const title = mailingTitle.current.querySelector("input").value.trim(); 
    const message = mailingMessage.current.querySelector("textarea").value.trim(); 
    
    if (title === "" || message === "") {
      alert("Поля 'Сообщение' и 'Заголовок письма' должны быть заполнены");
      return
    }
    if (window.confirm("Подтвердить рассылку?")) {
      setMailingLoading(true);
      const resp = await axios.post("/api/control/mailing", {title, message});
      
      if (resp?.data?.result === "ok") {
        setMailingLoading(false);
        alert("Рассылка успешно произведена");
        return;
      } 

      setMailingLoading(false);
      alert("Возникла непредвиденная ошибка");
    }
  }

  return (
    <Stack sx={{p: 4}}>
      <Typography variant='h5'>Управление сервисом</Typography>

      <Paper sx={{mt: 4, p: 4, width: "500px"}}>
        
        <Typography variant='div'><strong>Рассылка пользователям</strong></Typography>

        <Stack sx={{mt: 2}}>
          <TextField
            ref={mailingTitle}
            required
            variant='outlined'
            label="Заголовок письма"
            defaultValue=""
            sx={{mt: 2}}
          />
          <TextField
            ref={mailingMessage}
            required
            variant='outlined'
            label="Сообщение"
            multiline
            rows={4}
            defaultValue=""
            sx={{mt: 2}}
          />

          <Stack sx={{flexDirection: "row", alignItems: "center", mt: 2}}>
            <Button disabled={mailingLoading} sx={{ maxWidth: "fit-content"}} onClick={handleMailing}>Разослать</Button>
            {mailingLoading && <CircularProgress size={20} sx={{ml:2}}/>}
          </Stack>
          
        </Stack>
      </Paper>

    </Stack>
  )
}

export default ServiceControl