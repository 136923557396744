import React, { useState } from 'react';
import { TextField, Button, Switch, Typography, Box, FormGroup, FormControlLabel, Stack, RadioGroup, Radio, Stepper, Step, StepLabel, StepContent } from '@mui/material';
import { axios, T, Alert, getHashNumericValue } from "../Globals"
import { useNavigate } from 'react-router-dom';
import PasswordField from "../components/PasswordField"

const steps = ['Подтверждение почты', 'Проверочный код', 'Заполнение формы регистрации'];

const Register = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    password_confirmation: '',
    email_notify_newresponse: true,
  });
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [load, setLoad] = React.useState(false);
  const [userVerificationCode, setUserVerificationCode] = React.useState(""); 
  const [verificationCode, setVerificationCode] = React.useState(null);
  const [verificationCodeError, setVerificationCodeError] = React.useState(false);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async (event) => {
    let result = null;
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    
    switch (activeStep) {
      case 0:
        setLoad(true);
        result = await handleSubmitConfirm(event);
        setLoad(false);
        break;

      case 1:
        result = userVerificationCode === String(verificationCode);
        setVerificationCodeError(!result);
        break;

      default:
        break;
    }

    if (result) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleSubmitConfirm = async (event) => {
    event.preventDefault();
    try {
      const code = String(getHashNumericValue(event.target.value)).slice(0, 5);
      const resp = await axios.post('/api/register/verificationcode', { code, email: formData.email });
      if (resp.data.result === "ok") {
        setVerificationCode(code);
        return true;
      } else {
        await Alert(<T>{resp?.data?.message}</T>);
        return false;
      }

    } catch (error) {
      console.log(error.response.data);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const resp = await axios.post('/api/register', formData);
      if (resp.data.result === "ok") {
        new Promise((resolve, reject) => {
          Alert(<T>successful registration</T>).then(() => {
            resolve();
            navigate('/login');
          })
        })
      } else {
        Alert(<T>{resp?.data?.message}</T>)
      }
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChangeVerificationCode = (event) => {
    setUserVerificationCode(event.target.value);
  };

  const handleCheck = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked })
  }
  const handleRadio = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value === "true" ? true : false })
  }


  return (
    <Box sx={{ maxWidth: 375, mx: "auto", pt: 2 }}>
      <Box sx={{ maxWidth: "100%" }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>{step}</StepLabel>
              <StepContent>
                <Box>
                  {index === 0
                    &&
                    <form onSubmit={handleSubmitConfirm}>
                      <Typography color={"GrayText"} sx={{ my: 3 }}><T>next verificationcode 1</T>.</Typography>
                      <TextField
                        variant='outlined'
                        required
                        fullWidth
                        type="email"
                        name="email"
                        label="Email"
                        onChange={handleChange}
                        value={formData.email}
                      />
                    </form>
                  }

                  {index === 1 &&
                    <>
                      <Typography color={"GrayText"} sx={{ my: 3 }}><T>next verificationcode 2</T>:<br/><strong>{formData.email}</strong></Typography>
                      <TextField
                        error={verificationCodeError}
                        variant='outlined'
                        required
                        fullWidth
                        type="text"
                        name="code"
                        placeholder='Проверочный код...'
                        onChange={handleChangeVerificationCode}
                        value={userVerificationCode}
                        helperText={verificationCodeError && <T>code doesnt match</T>}
                      />
                    </>
                  }

                  {index === steps.length - 1 &&
                    <form onSubmit={handleSubmit}>
                      <Stack gap={2}>
                        <TextField
                          required
                          fullWidth
                          type="email"
                          name="email"
                          label="Email"
                          disabled
                          value={formData.email}
                        />
                        <TextField
                          required
                          fullWidth
                          type="text"
                          name="name"
                          label={<T>fio</T>}
                          onChange={handleChange}
                          value={formData.name}
                        />
                        <TextField
                          required
                          fullWidth
                          type="text"
                          name="phone"
                          label={<T>phone</T>}
                          onChange={handleChange}
                          value={formData.phone}
                        />
                        <Box>
                          <PasswordField
                            required
                            fullWidth
                            name="password"
                            label={<T>password</T>}
                            onChange={handleChange}
                            value={formData.password}
                          />
                          <PasswordField
                            required
                            fullWidth
                            name="password_confirmation"
                            label={<T>confirm password</T>}
                            onChange={handleChange}
                            value={formData.password_confirmation}
                            sx={{ mt: 1 }}
                          />
                        </Box>

                        <Typography variant="subtitle2" color="textSecondary"><T>register as</T></Typography>
                        <RadioGroup
                          row
                          value={formData.role_administrator || false}
                          name="role_administrator"
                          onChange={handleRadio}
                          label="sdf"
                          sx={{ mt: -2.5 }}
                        >
                          <FormControlLabel value={false} control={<Radio />} label={<T>as doctor</T>} />
                          <FormControlLabel value={true} control={<Radio />} label={<T>as clinic</T>} />
                        </RadioGroup>
                      </Stack>
                      <Typography variant="h6" fontSize={"1.1rem"} sx={{ mt: 2 }}><T>documents</T></Typography>
                      <Typography component="a" fontSize={"0.9rem"} target="_blank" href="/doc/terms.pdf" sx={{ display: "block" }}><T>terms</T></Typography>
                      <Typography component="a" fontSize={"0.9rem"} target="_blank" href="/doc/policy.pdf" sx={{ display: "block" }}><T>policy</T></Typography>
                      <Typography component="a" fontSize={"0.9rem"} target="_blank" href="/doc/kpe.pdf" sx={{ display: "block" }}><T>kpe</T></Typography>
                      <Typography component="a" fontSize={"0.9rem"} target="_blank" href="/doc/agreement.pdf" sx={{ display: "block" }}><T>agreement</T></Typography>
                      <FormGroup sx={{ my: 2 }}>
                        <Stack>
                          <FormControlLabel
                            control={<Switch />}
                            required
                            label={<T>confirm oferta</T>}
                            onChange={handleCheck}
                            name="confirm_oferta"
                            checked={formData.confirm_oferta || false}
                            sx={{ "span": { fontSize: "0.85rem" }, m: 0 }}
                          />
                          <FormControlLabel
                            control={<Switch />}
                            required
                            label={<T>confirm policy</T>}
                            onChange={handleCheck}
                            name="confirm_policy"
                            checked={formData.confirm_policy || false}
                            sx={{ "span": { fontSize: "0.85rem" }, m: 0 }}
                          />
                          <FormControlLabel
                            control={<Switch />}
                            label={<T>confirm spam</T>}
                            onChange={handleCheck}
                            name="confirm_spam"
                            checked={formData.confirm_spam || false}
                            sx={{ "span": { fontSize: "0.85rem" }, m: 0 }}
                          />
                        </Stack>
                      </FormGroup>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ display: "block", m: "0 auto", mt: 3 }}
                        disabled={!(formData.confirm_oferta && formData.confirm_policy)}
                      >
                        <T>register</T>
                      </Button>
                    </form>
                  }
                </Box>
                <Button
                  variant="contained"
                  disabled={load}
                  value={index}
                  onClick={handleNext}
                  sx={{ mx: "auto", my: 2, display: index === steps.length - 1 ? 'none' : 'block' }}
                >
                  { load ? 'Ожидание...' : 'Подтвердить'}
                </Button>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>


      <Button variant="text" onClick={() => navigate("/login")} sx={{ display: "block", m: "0 auto", mt: 1.5 }}><T>already register</T></Button>
    </Box>
  );
};

export default Register;
