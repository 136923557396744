import { Box } from '@mui/material';
import React from 'react';
import Carousel from 'react-material-ui-carousel';

function NewsSlider() {
    
  var slidesData = [
    { id: 0, image: "/img/slides/FF-image-603x376-5.jpg" },
    { id: 1, image: "/img/slides/FF-image-603x376-3.jpg" },
    { id: 2, image: "/img/slides/FF-image-603x376-2.jpg" },
    // { id: 3, image: "/img/slides/FF-image-603x376-1.jpg" },
    // { id: 4, image: "/img/slides/FF-image-603x376-6.jpg" },
    { id: 3, image: "/img/slides/FotoFinderUniverseWireless.jpg" },
  ];

  return (
    <Carousel
      autoPlay={true}
      stopAutoPlayOnHover={true}
      animation='slide'
      duration='800'
      style={{zIndex: 1}}
      navButtonsProps={{
        style: {
          backgroundColor: '#009be5',
        }
      }} 
      indicators={true}
      >
      {
        slidesData.map( (item, i) => (
          <Slide key={i} item={item} />
        ))
      }
    </Carousel>
  )
}


const Slide = ({item}) => {
  // const height = (document.body.clientWidth <= 1200) ? "70vh": "35vh";
  // <Box sx={{ background: `url(${item.image}) center/contain no-repeat`, height: height }}></Box>
  return (
    // <img src={item.image} alt="" style={{objectPosition: "center center", objectFit: "contain", aspectRatio: "16 / 9", maxWidth: "100%"}}/>
    //<img src={item.image} alt="" style={{objectPosition: "center center", objectFit: "contain", aspectRatio: "16 / 9", maxWidth: "100%"}}/>
    <Box sx={{ background: `url(${item.image}) center/cover no-repeat`, height: "350px" }}></Box>
  )
}

export default NewsSlider;