import React, {  useState } from 'react';
import {   T, t, Alert } from "../Globals.js"
import { Stack, TextField, MenuItem, Button, Box } from '@mui/material';
import ItemTemplate from "../components/ItemTemplate";
import ReadOnlyField from "../components/ReadOnlyField";
import Heading from '../components/Heading.js';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import AppleIcon from '@mui/icons-material/Apple';
import { Download } from '@mui/icons-material';
import Help from '../components/Help.js';
import dayjs from 'dayjs';
import useValidation from '../hooks/useValidation.js';
import SuperAdminPanel from '../components/SuperAdminPanel.js';
//import LinkField from "../components/LinkField";
//import { useNavigate } from 'react-router-dom';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//import dayjs from 'dayjs';

export function DermatoscopesItem(props) {
  let [data, setData] = useState({ });
  const {myerrors} = useValidation(data, ["name", "model", "serial", ...(getAdditionalErrors())]);

  function getAdditionalErrors() {
    return data?.model === "other" ? ["dermatocsopeothername"] : []
  }

  const saveHandler = async () => {
    return new Promise((resolve,reject)=>{
      if (myerrors.length===0) resolve();
      else {
        Alert(<T>required error alert</T>).then(()=>{reject()})
      }
    })
  }
  const deleteHandler = async () => { }
  const cancelHandler = async () => {}

  const getButtons = () => data?.id === "create" ? ["save", "close"]: ["save", "close", "delete"];

  return (
    <>
      <ItemTemplate {...props} myerrors={myerrors} title={<T>dermatoscopes item</T>} defaults={{model:"dermlitehandyscope"}} buttons={getButtons()} table="dermatoscopes" data={data} setData={setData} onSave={saveHandler} onCancel={cancelHandler} onDelete={deleteHandler}>
        <DermatoscopesItemView  />
      </ItemTemplate>
    </>
  )
}



export function DermatoscopesItemView({myerrors, ...prop}) {
  //const navigate = useNavigate();

  const handleChange = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.value })
  }

  const showSerialHelp = () => {
    Alert(<T>Расположение серийного номера</T>, <Box><img style={{display: "block", width: "100%"}} src={"/img/DermLiteSerial.jpg"} alt="serial position"/></Box>);
  }

  /*
  const handleCheck = (event) => {
    prop.setData({ ...prop.data, [event.target.name]: event.target.checked })
  }
  */
/*  const confirmHandler = async function () {
    const res = await axios.post("/api/fotofinders/confirm/"+prop.data._id)
    if (res.data.result === "ok") window.navigate("/fotofinders")
    else {Alert(res.data.message); }
  }
*/
 
  return (
    <Stack sx={{ gap: 2 }}>
      <SuperAdminPanel {...prop} handleChange={handleChange}/>
      
      <TextField
        fullWidth
        label={<T>ff name</T>}
        value={prop.data?.name || ""}
        name="name"
        onChange={handleChange}
        error={!!myerrors.includes("name")}
        helperText={<T>ff name help</T>}
      />

      <TextField
        fullWidth
        label={<T>dermatoscope model</T>}
        value={prop.data?.model || ""}
        name="model"
        select
        onChange={handleChange}
        error={!!myerrors.includes("model")}
        helperText={myerrors.includes("model") ? <T>required</T> : ""}
      >
        <MenuItem value="handyscope"><T>dermatoscope model handyscope</T></MenuItem>
        <MenuItem value="dermlitehandyscope"><T>dermatoscope model dermlitehandyscope</T></MenuItem>
        <MenuItem value="medicam"><T>dermatoscope model medicam</T></MenuItem>
        <MenuItem value="other"><T>dermatoscope model other</T></MenuItem>
      </TextField>

      {prop.data.model === "other" &&
        <TextField
          fullWidth
          label = {<T>dermatoscope othername</T>}
          name="dermatocsopeothername"
          value={prop.data?.dermatocsopeothername || ""}
          onChange={handleChange}
          error={!!myerrors.includes("dermatocsopeothername")}
          helperText={myerrors.includes("dermatocsopeothername") ? <T>required</T> : ""}
        />
      }

      <TextField
        sx={{".MuiFormHelperText-root": {display: "flex", justifyContent: "space-between", "span": {color: "grey", cursor: "pointer", flex:1, textAlign: "end"}}}}
        fullWidth
        label={<T>serial</T>}
        value={prop.data?.serial || ""}
        name="serial"
        onChange={handleChange}
        error={!!myerrors.includes("serial")}
        helperText={myerrors.includes("serial") ? <><T>required</T><span onClick={showSerialHelp}><T>where serial number</T>?</span></> : <span onClick={showSerialHelp}><T>where serial number</T>?</span>}
      />
      <Help><T>ff serial help</T></Help>
      {prop.data?.enddate && <>
        <Heading heading={<T>ff access</T>} />
        <Button variant="outlined" component="a" href="https://nextcloud.premium-a.ru/index.php/s/M539j6kDy6WHDgN/download/%D0%98%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F_MelanomeNet.pdf" target="_blank" startIcon={<PdfIcon />} sx={{width:"350px"}}><T>ff download instruction</T></Button>
        <Button variant="outlined" component="a" href="https://nextcloud.premium-a.ru/index.php/s/YtF8pz6t7tHEAgn/download/MelanomeNet.exe" target="_blank" startIcon={<Download />} sx={{width:"350px"}}><T>ff download</T></Button>
        <Button variant="outlined" component="a" href="https://apps.apple.com/ru/app/universe-wireless/id1579016781" target="_blank" startIcon={<AppleIcon />} sx={{width:"350px"}}><T>ff iphone download</T></Button>
        <ReadOnlyField label={<T>end date</T>} value={prop.data?.enddate && dayjs(prop.data?.enddate).format("DD.MM.YYYY")} />
        <ReadOnlyField label={<T>ff comment</T>} value={prop.data?.comment || ""} />

        <Heading heading={<T>PC VPN Settings</T>} />
        <Help><T>ff vpn help</T></Help>
        <ReadOnlyField label={<T>ff vpn address</T>} value="vpn.melanomenet.ru" />
        <ReadOnlyField label={<T>ff login</T>} value={"MNU\\U"+prop.data?.ffportnumber || ""} />
        <ReadOnlyField label={<T>ff password</T>} value={prop.data?.ffpassword || ""} />

        <Heading heading={<T>PC Universe Settings</T>} />
        <Help><T>ff universe help</T></Help>
        <ReadOnlyField label={<T>ff sql addr</T>} value={prop.data?.ffsqladdr || ""} />
        <ReadOnlyField label={<T>ff database</T>} value={"DB"+prop.data?.ffportnumber || ""} />
        <ReadOnlyField label={<T>ff sql auth</T>} value="SQL-сервер" />
        <ReadOnlyField label={<T>ff login</T>} value={"U"+prop.data?.ffportnumber || ""} />
        <ReadOnlyField label={<T>ff password</T>} value={prop.data?.ffpassword || ""} />

        <Heading heading={<T>Iphone Wireless Settings</T>} />
        <Help><T>ff wireless help</T></Help>
        <ReadOnlyField label={<T>Device Name</T>} value={prop.data?.serial || ""} />
        <ReadOnlyField label={<T>Server - Find by - Machine name</T>} value="vpn.melanomenet.ru" />
        <ReadOnlyField label={<T>Port</T>} value={prop.data?.ffportnumber || ""} />
      </>}
      {prop.data._id && 
        <Button variant="outlined" onClick={()=>{window.navigate("/transactions/createFF")}}><T>dermatoscope pay</T></Button>
      }
    </Stack>
  )
}

export function DermatoscopesItemShort(prop) {
  return (
    <>
      <ReadOnlyField
        label={<T>dermatoscope model</T>}
        value={t("dermatoscope model "+prop.data?.model || "")}
        name="model"
      />
      {prop.data?.model === "other" &&
        <ReadOnlyField
          label={<T>dermatoscope othername</T>}
          value={t(prop.data?.dermatocsopeothername || "")}
          name="scale"
        />
      }
    </>
  )
}

