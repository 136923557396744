import React, { useState } from 'react';

import { TextField, Button, Typography, Box, Alert as MuiAlert } from '@mui/material';

//import Template from "../TemplateGuest.js";
import { useNavigate } from 'react-router-dom';

import {axios,T, Alert} from "../Globals.js"
import PasswordField from "../components/PasswordField"

const ForgottPassword = () => {
  const navigate = useNavigate();

  const [state, setState] = useState(1);
  const [formData, setFormData] = useState({
    email: ''
  });

  if (state===1) formData.code = "";

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      let resp 
      if (state === 1) resp = await axios.post('/api/forgottpassword', formData);
      if (state === 2) resp = await axios.post('/api/forgottpassword/code', formData);
      if (state === 3) resp = await axios.post('/api/forgottpassword/newpassword', formData);

      if (resp.data.result==="ok") {
        if (state === 1) setState(2)
        if (state === 2) setState(3)
        if (state === 3) setState(4)
      } else {
        await Alert(resp.data.message)
        if (state === 2) setState(1);
      }

    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };


  if (state === 1) return (
    <>
      <Box sx={{ maxWidth: {xs: "100%", sm:375}, mx: 'auto' }}>
        <Typography variant="myDefault22" sx={{mx: "auto", mt: 4, mb: 2, display: "block", textAlign: "center"}}><T>forgott password</T></Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            required 
            fullWidth
            type="email"
            name="email"
            label="Email"
            onChange={handleChange}
            value={formData.email || ""}
          />
          
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{display: "block", m: "0 auto", mt: 3}}
          >
            <T>continue</T>
          </Button>
        </form>
        <Button variant="text" onClick={()=>navigate("/login")} sx={{display: "block", m: "0 auto", mt: 1.5}}><T>login</T></Button>        
      </Box>
    </>
  );


  if (state === 2) return (
    <>
      <Box sx={{ maxWidth: {xs: "100%", sm:375}, mx: 'auto' }}>
        <Typography variant="myDefault22" sx={{mx: "auto", mt: 4, mb: 2, display: "block", textAlign: "center"}}><T>forgott password</T></Typography>
        <form onSubmit={handleSubmit}>
          <T>input code from email</T>
          <TextField
            required 
            fullWidth
            type="code"
            name="code"
            label={<T>forgott password code</T>}
            onChange={handleChange}
            value={formData.code || ""}
          />
          
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{display: "block", m: "0 auto", mt: 3}}
          >
            <T>continue</T>
          </Button>
        </form>
        <Button variant="text" onClick={()=>navigate("/login")} sx={{display: "block", m: "0 auto", mt: 1.5}}><T>login</T></Button>        
      </Box>
    </>
  );




  if (state === 3) return (
    <>
      <Box sx={{ maxWidth: {xs: "100%", sm:375}, mx: 'auto' }}>
        <Typography variant="myDefault22" sx={{mx: "auto", mt: 4, mb: 2, display: "block", textAlign: "center"}}><T>forgott password</T></Typography>
        <form onSubmit={handleSubmit}>
          <T>create new password</T>
          <PasswordField
            required 
            fullWidth
            name="password"
            label={<T>password</T>}
            onChange={handleChange}
            value={formData.password || ""}
          />
          
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{display: "block", m: "0 auto", mt: 3}}
          >
            <T>continue</T>
          </Button>
        </form>
        <Button variant="text" onClick={()=>navigate("/login")} sx={{display: "block", m: "0 auto", mt: 1.5}}><T>login</T></Button>        
      </Box>
    </>
  );



  
  if (state === 4) return (
    <>
      <Box sx={{ maxWidth: {xs: "100%", sm:375}, mx: 'auto' }}>
        <Typography variant="myDefault22" sx={{mx: "auto", mt: 4, mb: 2, display: "block", textAlign: "center"}}><T>forgott password</T></Typography>
        <MuiAlert><T>forgott password finish</T></MuiAlert>
        <Button variant="contained" onClick={()=>navigate("/login")} sx={{display: "block", m: "0 auto", mt: 1.5}}><T>login</T></Button>        
      </Box>
    </>
  );

};

export default ForgottPassword;
