import React from 'react';
import { Divider, Typography } from '@mui/material';
import Help from './Help';


function Heading({heading, subheading = null, style = {}, line=true}) {
/*<Typography component="pre" sx={{whiteSpace: "pre-line"}}>{subheading}</Typography>*/
  return (
    <>
      <div style={{width:"100%",maxWidth:window.maxWidth, margin:"20px auto 0 auto", ...style}}>
        <Typography component="h1" variant='myDefault22'>{heading}</Typography>
        {line &&
          <Divider component="div" variant='primary' sx={{my: 1.5}}/> 
        }
        {subheading && 
          
          <Help>{subheading}</Help>
        }
      </div>
    </>
  );
}

export default Heading;